import {
  useAdvancedSettings,
  useAnimationModeStore,
  useArtStyle,
  useAspectRatioStore,
  useAudioAsset,
  useAudioTrim,
  useAvatar,
  useStartingFrame,
  useStrengthPromptCompensation,
  useSubjectMatter,
  useTransitions,
  useZoomPulseStore,
} from "../../store/hooks";
import libraryJson from "../../utils/library-json";
import { APIClient } from "../../utils/services";
import { useNavigate } from "react-router-dom";

export default function useTemplate() {
  const templates_api = libraryJson.template;

  const n = useNavigate();

  // Redux setters
  const { setUserVideoName, setUserInitialImageKey, setUserSubjectMatter } =
    useStartingFrame();
  const { setAspectRatio } = useAspectRatioStore();
  const { setAnimationMode } = useAnimationModeStore();
  const { setUserThemeId } = useArtStyle();
  const { setIsFromLibrary, setMusic } = useAudioAsset();
  const { setStart, setEnd } = useAudioTrim();
  const { setZoomPulse } = useZoomPulseStore();
  const { setTransit } = useTransitions();
  const { setStrengthPrompt } = useStrengthPromptCompensation();
  const { setAvatar } = useAvatar();
  const { setAdvSettings } = useAdvancedSettings();
  const { setSubjectMatter } = useSubjectMatter();

  const copyVideoTemplate = (videoId: string) => {
    const client = new APIClient();
    const states = client.getVideoGenerationParamsTemplate(videoId);
    console.log("copy ->", states);
    if (states?.startingFrame) {
      const {
        startingFrame,
        generateVideo,
        audioAsset,
        artStyles,
        subjectsMatter,
      } = states;
      let [first, ...aspectRatio] = generateVideo.aspectRatio.split("");
      const aspectRatioCaptalized = [first.toUpperCase(), ...aspectRatio].join(
        ""
      );

      setUserVideoName(startingFrame.videoName);
      setAspectRatio(aspectRatioCaptalized);
      setAnimationMode(generateVideo.animationMode);
      setUserThemeId(artStyles.themeId);
      setUserInitialImageKey(startingFrame.initialImageKey);
      setIsFromLibrary();
      setMusic({ asset_id: audioAsset.selected });
      setStart(audioAsset.start);
      setEnd(audioAsset.end);
      setZoomPulse(generateVideo.zoomPulse);
      setTransit(generateVideo.transitions);
      setUserSubjectMatter(subjectsMatter.subjectMatter);
      setSubjectMatter(
        subjectsMatter.subjectMatter.map((el: string) => ({
          label: el,
          time: "00:00",
        }))
      );
      setAdvSettings(generateVideo.videoConfig);
      setStrengthPrompt(generateVideo.strength_prompt_compensation);
      setAvatar({ id: artStyles.userAvatar });
    }
    n("/app/create-video/audio-select");
  };

  return {
    templates: templates_api,
    copyVideoTemplate,
  };
}
