import { useState } from "react";

export default function usePaginator() {
  const [actual, setActual] = useState(1);
  const [total, setTotal] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const pages = Math.floor(total / itemsPerPage) + 1;

  const next = () => {
    if (actual < pages) {
      setActual((prev) => (prev += 1));
    }
  };

  const previous = () => {
    if (actual > 1) {
      setActual((prev) => (prev -= 1));
    }
  };

  const showNext = actual < pages;
  const showPrevious = actual > 1;

  const start = Math.floor(itemsPerPage * actual) - itemsPerPage;

  return {
    actual,
    pages,
    total,
    next,
    previous,
    showNext,
    showPrevious,
    setTotal,
    setItemsPerPage,
    itemsPerPage,
    start,
    end: start + itemsPerPage > total ? total : start + itemsPerPage,
    shouldHide: total <= itemsPerPage,
  };
}
