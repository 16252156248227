import { useAuth } from "../../AuthProvider";
import { useAvatar } from "../../store/hooks";
import { useGetAvatarsQuery, useGetMetadataDefaultQuery } from "../../API";
import { APIClient } from "../../utils/services";
import { useEffect, useState } from "react";
import { useArtStyleSelector } from "../ChooseArtStylePage";
import * as Sentry from "@sentry/react";

export default function useAvatarSelection() {
  const { avatar, setAvatar } = useAvatar();
  const [noavatars, setNoAvatars] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const { themeId } = useArtStyleSelector();

  const [avatars, setAvatars] = useState<React.ReactNode[]>([]);

  const { currentUser } = useAuth();
  const ownerId = currentUser?.email || "";
  const owner_default = "leeork+10@mymuze.art";

  const { data, isSuccess, isLoading, isError, refetch } = useGetAvatarsQuery({
    ownerId,
  });

  const { data: dataDefault, isSuccess: isSuccessDefault } =
    useGetMetadataDefaultQuery({
      owner_default,
    });

  async function avatarPreview(owner_id: string, dreambooth_id: string) {
    const service = new APIClient();
    try {
      const result = (await service.dreambooth_get_preview_images(
        owner_id,
        dreambooth_id
      )) as any;
      return result;
    } catch (error) {
      Sentry.captureException(error);
      Sentry.setUser({ email: ownerId });
      return;
    }
  }

  async function avatarPreviewDefault(owner_id: string, dreambooth_id: string) {
    const service = new APIClient();
    try {
      const result = await service.dreambooth_get_preview_images_default(
        owner_id,
        dreambooth_id
      );
      return result;
    } catch (error) {
      Sentry.captureException(error);
      Sentry.setUser({ email: ownerId });
      console.error("Error fetching avatar preview:", error);
      return null;
    }
  }

  function processAvatarData(
    avatar: any,
    imageAvatar: string,
    ownerIdEncoded: string
  ): any {
    return {
      ...avatar,
      imageAvatar,
      date: new Date(avatar.timestamp).toLocaleDateString(),
    };
  }

  const fetchData = async () => {
    const ownerIdEncodedDefault = encodeURIComponent(owner_default);
    const ownerIdEncoded = encodeURIComponent(ownerId);

    setLoading(true);

    if (isSuccessDefault || isSuccess) {
      console.log(isSuccessDefault);

      const defaultAvatars = dataDefault?.result?.records || [];
      const records = data?.result?.records || [];

      const avatarsArray = [] as any;
      let imageAvatar = "";

      try {
        const stylesImages = await Promise.all(
          records.map((record: any) =>
            avatarPreview(ownerId, record.dreambooth_id)
          )
        );

        const defaultStylesImages = await Promise.all(
          defaultAvatars.map((avatar: any) =>
            avatarPreviewDefault(owner_default, avatar.dreambooth_id)
          )
        );

        defaultStylesImages.forEach((previewData, index) => {
          try {
            const thumbnailKeys = previewData?.data?.result?.["thumbnail-keys"];
            if (Array.isArray(thumbnailKeys) && thumbnailKeys.length > 0) {
              const selectedThumbnail = thumbnailKeys.find(
                (item) => item.Name === themeId
              );
              imageAvatar =
                selectedThumbnail?.images[0]?.http_link.replace(
                  owner_default,
                  ownerIdEncodedDefault
                ) ||
                thumbnailKeys[0]?.images[0]?.http_link.replace(
                  owner_default,
                  ownerIdEncodedDefault
                ) ||
                "";
            }
            avatarsArray.push(
              processAvatarData(
                defaultAvatars[index],
                imageAvatar,
                ownerIdEncodedDefault
              )
            );
          } catch (error) {
            console.error(error);
            avatarsArray.push(defaultAvatars[index]);
          }
        });

        stylesImages.forEach((previewData, index) => {
          try {
            const thumbnailKeys = previewData?.data?.result?.["thumbnail-keys"];
            if (Array.isArray(thumbnailKeys) && thumbnailKeys.length > 0) {
              const selectedThumbnail = thumbnailKeys.find(
                (item) => item.Name === themeId
              );
              imageAvatar =
                selectedThumbnail?.images[0]?.http_link.replace(
                  ownerId,
                  ownerIdEncoded
                ) ||
                thumbnailKeys[0]?.images[0]?.http_link.replace(
                  ownerId,
                  ownerIdEncoded
                ) ||
                "";
            }
            avatarsArray.push(
              processAvatarData(records[index], imageAvatar, ownerIdEncoded)
            );
          } catch (error) {
            console.error(error);
            avatarsArray.push(records[index]);
          }
        });

        if (avatarsArray.length === 0) {
          setNoAvatars(true);
        } else {
          setNoAvatars(false);
          setAvatars(
            avatarsArray.sort(
              (a: any, b: any) =>
                new Date(b.timestamp).getTime() -
                new Date(a.timestamp).getTime()
            )
          );
          if (avatarsArray.length > 0) {
            setSelectedImage(avatarsArray[0].imageAvatar);
          }
        }
      } catch (error) {
        console.error("Error fetching avatar data:", error);
        setNoAvatars(true);
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  const avatarCompleted = avatars.filter(
    (i: any) => i.dreambooth_status === "DONE"
  );

  const avatarIncompleted = avatars.filter(
    (i: any) => i.dreambooth_status !== "DONE"
  );

  const incompleted = avatarIncompleted.map((i: any) => i.dreambooth_status);
  const stepName = incompleted.length > 0 ? incompleted[0] : "";
  const step = ~~(
    ((STEPS.findIndex((v) => v === stepName) + 1) * 100) /
    STEPS.length
  );

  const s = stepName
    ? stepName
        .toLowerCase()
        .split("_")
        .map((v: string) => (v[0] ? v[0].toUpperCase() + v.slice(1) : ""))
        .join(" ")
    : "";

  return {
    avatar,
    setAvatar,
    setSelectedImage,
    setLoading,
    fetchData,
    loading,
    avatarList: avatars,
    isSuccess,
    isLoading,
    isError,
    avatarCompleted,
    avatarIncompleted,
    noavatars,
    refetch,
    STEPS,
    step,
    stepName: s,
    imageAvatar: selectedImage,
  };
}

const STEPS = [
  "ERROR",
  "DREAMBOOTH_CONFIG_START",
  "DREAMBOOTH_CONFIG_END",
  "IMAGES_CROP_START",
  "IMAGES_CROP_END",
  "DREAMBOOTH_INIT_START",
  "DREAMBOOTH_INIT_END",
  "DREAMBOOTH_TRAIN_START",
  "DREAMBOOTH_TRAIN_END",
  "DREAMBOOTH_MODEL_UPLOAD_START",
  "DREAMBOOTH_MODEL_UPLOAD_END",
  "DREAMBOOTH_STYLE_PREVIEWS_START",
  "DREAMBOOTH_STYLE_PREVIEWS_END",
];
