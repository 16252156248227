/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useGetTransitionEffectsQuery } from "../../../../API";
import { Form } from "react-bootstrap";
import Delete from "./Delete";
import AddTransition from "./AddTransition";

export default function TransitionEffectBox(props: any) {
  const { addTe, remTe, index, te, setTe } = props;
  const [transition, setTransition] = useState<number>(-1);
  const [strength, setStrength] = useState(2.0);
  const [offsetStart, setOffSetStart] = useState(0.0);
  const [duration, setDuration] = useState(100.0);

  const { data, isSuccess } = useGetTransitionEffectsQuery("2D");
  let options: any[] = [];

  if (typeof data === "object") {
    options = Object.entries(data);
  }

  // load info when load (mount)
  useEffect(() => {
    const initialTransition = te[index].values.transition;
    const initialStrength = te[index].values.strength || 2.0;
    const initialOffsetStart = te[index].values.offsetStart || 0.0;
    const initialDuration = te[index].values.duration || 100.0;

    setTransition(initialTransition);
    setStrength(initialStrength);
    setOffSetStart(initialOffsetStart);
    setDuration(initialDuration);
  }, [te.length]);

  // set value on state of father ...
  useEffect(() => {
    if (isSuccess && options.length > 0) {
      setTe((state: any[]) =>
        state.map((i: any, k: number) => ({
          ...i,
          values: {
            ...i.values,
            transition: k === index ? transition : i.values.transition,
            type:
              k === index
                ? transition > -1
                  ? options[transition][0]
                  : ""
                : i.values.type,
          },
        }))
      );
    }
  }, [transition]);

  useEffect(() => {
    setTe((state: any[]) =>
      state.map((i: any, k: number) => ({
        ...i,
        values: {
          ...i.values,
          strength: k === index ? strength : i.values.strength,
        },
      }))
    );
  }, [strength]);

  useEffect(() => {
    setTe((state: any[]) =>
      state.map((i: any, k: number) => ({
        ...i,
        values: {
          ...i.values,
          offsetStart: k === index ? offsetStart : i.values.offsetStart,
        },
      }))
    );
  }, [offsetStart]);

  useEffect(() => {
    setTe((state: any[]) =>
      state.map((i: any, k: number) => ({
        ...i,
        values: {
          ...i.values,
          duration: k === index ? duration : i.values.duration,
        },
      }))
    );
  }, [duration]);

  const handleTransitionChange = (e: any) => {
    const selectedValue = parseInt(e.target.value);
    setTransition(selectedValue === -1 ? -1 : selectedValue);
  };

  return (
    <div className="d-flex flex-column p-3 gap-3 border rounded">
      <p className="m-0 p-0 text-white">Manually Set Constant Camera Motion</p>
      <div className="d-flex flex-column gap-3 px-3">
        <div className="d-flex flex-column justify-content-between">
          <p className="m-0 p-0 small">Select Type of Motion</p>
          <Form.Select onChange={handleTransitionChange} value={transition}>
            <option value={-1}>Select a Type</option>
            {options?.map((v: any, index: number) => (
              <option key={index} value={index}>
                {v[0]}
              </option>
            ))}
          </Form.Select>
        </div>
      </div>
    </div>
  );
}
