/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { TransitionConf, TransitionValues } from "./types";
import { defaultTransition } from "./utils";
import { useTransitions } from "../../../../store/hooks";
import TransitionEffectBox from "./TransitionEffectBox";

export default function MSetTransitionEffects() {
  const { transit, setTransit } = useTransitions();
  const [te, setTe] = useState<TransitionConf[]>([defaultTransition()]);

  useEffect(() => {
    // get from store when mount
    if (transit.length > 0) {
      setTe([
        ...transit.map((i: TransitionValues) => ({
          options: [],
          values: i,
        })),
      ]);
    }
  }, []);

  useEffect(() => {
    // set to store when change
    if (te.length > 0) {
      setTransit(te.map((i: any) => ({ ...i.values })));
    }
  }, [te]);

  // add new Transition Effect
  const addTe = () => setTe((state) => [...state, defaultTransition()]);

  // remove Tansition Effect at index n
  const remTe = (index: number) => {
    if (te.length > 1) {
      setTe(() => [...te.filter((_, i) => i !== index)]);
    }
  };

  const t = te.map((...v: any) => ({
    ...v[0],
    addTe,
    remTe,
    te,
    setTe,
    index: v[1],
    key: v[1],
  }));

  const transitions = t.map((...v: any) => (
    <TransitionEffectBox {...{ ...v[0], key: v[1] }} />
  ));

  return <>{transitions}</>;
}
