import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { museApi } from "../API";
import userReducer from "./userReducer";
import createAvatarReducer from "./createAvatarReducer";
import generateVideoReducer from "./generateVideoReducer";
import audioAssetReducer from "./audioAssetReducer";
import listImagesSliceReducer from './listImagesSliceReducer';
import artStyleReducer from "./artStyleReducer";
import startingFrameReducer from "./startingFrameReducer";
import promptsReducer from "./promptsReducer";
import parallelGenerationReducer from "./parallelGenerationReducer";

export const store = configureStore({
  reducer: {
    user: userReducer,
    createAvatar: createAvatarReducer,
    generateVideo: generateVideoReducer,
    audioAsset: audioAssetReducer,
    userAssets: listImagesSliceReducer,
    artStyles: artStyleReducer,
    startingFrame: startingFrameReducer,
    subjectsMatter: promptsReducer,
    parallelProcesses: parallelGenerationReducer,
    [museApi.reducerPath]: museApi.reducer,
  },
  // also for museApi
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(museApi.middleware),
});

// also for museApi - refetchOnFocus/refetchOnReconnect
setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
