import { Button, Container, Image } from "react-bootstrap";
import { useAuth, AuthUser } from "../../AuthProvider";
import "./styles.css";
import { useClearConfigs } from "../../store/hooks";
import { useNavigate } from "react-router-dom";

export default function UserCard({
  dataName,
  dataPhoto,
}: // onCardClick,
{
  dataName: any;
  dataPhoto: any;
  // onCardClick: any;
}) {
  const { currentUser, logOut } = useAuth();
  const navigate = useNavigate();
  const { clear } = useClearConfigs();

  const user = currentUser as unknown as AuthUser;
  const photo = user?.photoURL ?? "bi bi-person-circle";
  const name = user?.displayName;
  const email = user?.email;

  const formatUserName = (email: any) => {
    if (!email) return "";
    const username = email.split("@")[0];
    const firstWord = username.split(".")[0];
    const capitalizedUsername =
      firstWord.charAt(0).toUpperCase() + firstWord.slice(1);
    return capitalizedUsername;
  };

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  return (
    <Container
      id="card"
      className={`d-flex flex-column rounded p-2 ${
        !isMobileDevice() ? "mt-auto" : ""
      }`}
      style={{
        marginTop: isMobileDevice() ? "auto" : "0",
      }}

      // onClick={onCardClick}
    >
      <Container
        className="gap-3 py-3 d-flex flex-row flex-noreap justify-content-between"
        style={{ alignItems: "center" }}
      >
        {dataPhoto || user?.photoURL ? (
          <Image
            roundedCircle
            src={dataPhoto || user?.photoURL}
            style={{ width: "4rem", height: "4rem" }}
          />
        ) : (
          <i
            className="bi bi-person-circle"
            style={{
              fontSize: "45px",
              color: "#FFF",
              borderRadius: "50%",
            }}
          />
        )}
        <Container className="m-0 p-0">
          <Container className="m-0 p-0 text-start text-nowrap">
            {dataName ? dataName : formatUserName(email)}
          </Container>
          <Container className="m-0 p-0 text-start text-nowrap text-muted fw-light">
            {email}
          </Container>
        </Container>
      </Container>
      <Button
        id="btn"
        className="py-2 d-flex align-items-center justify-content-center"
        onClick={() => {
          clear();
          logOut();
          navigate("/");
        }}
      >
        Log Out
      </Button>
    </Container>
  );
}
