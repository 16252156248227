import {
  Environments,
  InitializePaddleOptions,
  Paddle,
  PaddleEventData,
  initializePaddle,
} from "@paddle/paddle-js";
import { useEffect, useState } from "react";
import { APIClient } from "../../utils/services";
import { useAuth } from "../../AuthProvider";
import * as Sentry from "@sentry/react";

export function useCheckout(
  eventCallback: ((event: PaddleEventData) => void) | undefined
) {
  const [paddle, setPaddle] = useState<Paddle>();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showSuccessFirstSubs, setShowSuccessFirstSubs] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showMessageDowngrade, setShowMessageDowngrade] = useState(false);
  const [showUpdateTrialingSub, setUpdateTrialingSub] = useState(false);
  const [loading, setLoading] = useState(false);

  const config: InitializePaddleOptions = {
    environment: `${process.env.REACT_APP_PADDLE_ENVIRONMENT}` as Environments,
    seller: Number(`${process.env.REACT_APP_PADDLE_VENDOR_ID}`),
    eventCallback,
  };

  // Download and initialize Paddle instance from CDN
  useEffect(() => {
    initializePaddle(config).then((paddleInstance: Paddle | undefined) => {
      if (paddleInstance) {
        setPaddle(paddleInstance);
      }
    });
  }, []);

  const { currentUser } = useAuth();
  const ownerId = currentUser?.email || "";
  const ownerIdEncoded = encodeURIComponent(ownerId);

  const service = new APIClient();

  const openCheckout = (email: any, descriptionPlan: any) => {
    let priceId = "";

    switch (descriptionPlan) {
      case "Enterprise":
        priceId = "pri_01hdgatkhmpez2fwdn8r0dy6wg";
        break;
      case "Agency":
        priceId = "pri_01hdr0f07aeqbq15pswzr57tqw";
        break;
      case "Rockstar":
      default:
        priceId = "pri_01hrm0f1esgdjmq44r9b41x3mz";
        break;
    }

    let subscriptionId = "";
    let items: any[] = [];
    setLoading(true);

    service
      .get_plan_subscription(ownerIdEncoded)
      .then((res: any) => {
        const subscriptions = res.data.subscriptions;
        const typeSubscription = res.data.user_credits.Item.account_type;
        const activeSubscription = subscriptions.find(
          (subscription: any) =>
            subscription.status === "trialing" ||
            subscription.status === "active"
        );

        if (activeSubscription) {
          const subscriptionId = activeSubscription.id;
          const items = [{ price_id: priceId, quantity: 1 }];

          if (
            typeSubscription === "enterprise" &&
            activeSubscription.status === "active"
          ) {
            service
              .paddle_downgrade_subscription(subscriptionId, items)
              .then((upgradeRes) => {
                //service.get_plan_subscription(ownerId);
                setShowMessageDowngrade(true);
                setLoading(false);
              })
              .catch((error: any) => {
                console.log(error);
                Sentry.captureException(error);
                Sentry.setUser({ email: ownerId });
                setShowErrorModal(true);
                setLoading(false);
              });
          } else if (
            typeSubscription === "agency" &&
            activeSubscription.status === "active"
          ) {
            if (descriptionPlan === "Rockstar") {
              service
                .paddle_downgrade_subscription(subscriptionId, items)
                .then((upgradeRes) => {
                  //service.get_plan_subscription(ownerId);
                  setShowMessageDowngrade(true);
                  setLoading(false);
                })
                .catch((error: any) => {
                  console.log(error);
                  Sentry.captureException(error);
                  Sentry.setUser({ email: ownerId });
                  setShowErrorModal(true);
                  setLoading(false);
                });
            } else if (descriptionPlan === "Enterprise") {
              service
                .paddle_upgrade_subscription(subscriptionId, items)
                .then((upgradeRes) => {
                  //service.get_plan_subscription(ownerId);
                  setShowSuccessModal(true);
                  setLoading(false);
                })
                .catch((error: any) => {
                  console.log(error);
                  Sentry.captureException(error);
                  Sentry.setUser({ email: ownerId });
                  setShowErrorModal(true);
                  setLoading(false);
                });
            }
          } else if (
            typeSubscription === "rockstar" &&
            activeSubscription.status === "active"
          ) {
            service
              .paddle_upgrade_subscription(subscriptionId, items)
              .then((upgradeRes) => {
                //service.get_plan_subscription(ownerId);
                setShowSuccessModal(true);
                setLoading(false);
              })
              .catch((error) => {
                Sentry.captureException(error);
                Sentry.setUser({ email: ownerId });
                setShowErrorModal(true);
                setLoading(false);
              });
          } else if (
            ((typeSubscription === "rockstar" &&
              descriptionPlan === "Agency") ||
              descriptionPlan === "Enterprise") &&
            activeSubscription.status === "trialing"
          ) {
            service
              .paddle_change_subscription_trialing(subscriptionId, items)
              .then((upgradeRes) => {
                //service.get_plan_subscription(ownerId);
                setUpdateTrialingSub(true);
                setLoading(false);
              })
              .catch((error) => {
                Sentry.captureException(error);
                Sentry.setUser({ email: ownerId });
                setShowErrorModal(true);
                setLoading(false);
              });
          } else if (
            typeSubscription === "agency" &&
            descriptionPlan === "Rockstar" &&
            activeSubscription.status === "trialing"
          ) {
            service
              .paddle_change_subscription_trialing(subscriptionId, items)
              .then((upgradeRes) => {
                //service.get_plan_subscription(ownerId);
                setUpdateTrialingSub(true);
                setLoading(false);
              })
              .catch((error) => {
                Sentry.captureException(error);
                Sentry.setUser({ email: ownerId });
                setShowErrorModal(true);
                setLoading(false);
              });
          } else if (
            ((typeSubscription === "enterprise" &&
              descriptionPlan === "Rockstar") ||
              descriptionPlan === "Agency") &&
            activeSubscription.status === "trialing"
          ) {
            service
              .paddle_change_subscription_trialing(subscriptionId, items)
              .then((upgradeRes) => {
                //service.get_plan_subscription(ownerId);
                setUpdateTrialingSub(true);
                setLoading(false);
              })
              .catch((error) => {
                Sentry.captureException(error);
                Sentry.setUser({ email: ownerId });
                setShowErrorModal(true);
                setLoading(false);
              });
          } else if (
            typeSubscription === "free" &&
            (activeSubscription.status === "trialing" ||
              activeSubscription.status === "active")
          ) {
            openPaddleCheckout(ownerIdEncoded, priceId);
            setLoading(false);
          }
        } else {
          openPaddleCheckout(ownerIdEncoded, priceId);
          setLoading(false);
        }
      })
      .catch((error: any) => {
        Sentry.captureException(error);
        Sentry.setUser({ email: ownerId });
        setLoading(false);
      });
  };

  function openPaddleCheckout(email: string, priceId: any): any {
    paddle?.Checkout.open({
      settings: {
        allowLogout: false,
      },
      customer: {
        email,
      },
      items: [{ priceId }],
    });
  }

  return {
    openCheckout,
    openPaddleCheckout,
    showSuccessModal,
    showErrorModal,
    showMessageDowngrade,
    showSuccessFirstSubs,
    setShowSuccessModal,
    setShowSuccessFirstSubs,
    setShowErrorModal,
    setShowMessageDowngrade,
    loading,
    setUpdateTrialingSub,
    showUpdateTrialingSub,
  };
}

/*
eventCallback argument example:
{
  "name": "checkout.completed",
  "data": {
    "id": "che_01he3qtr78bjtt9m5bwpx7yt2g",
    "transaction_id": "txn_01he3qtr4dw62athve538gckq8",
    "status": "completed",
    "custom_data": null,
    "currency_code": "USD",
    "customer": {
      "id": "ctm_01hd7ar19t3mh4mdcwgpc31hcj",
      "email": "asdf@asdf.com",
      "address": {
        "id": "add_01he3qtyv8yhh79n8wz2ce5zxb",
        "country_code": "BR",
        "postal_code": "",
        "city": "",
        "region": "",
        "first_line": ""
      },
      "business": null
    },
    "items": [
      {
        "price_id": "pri_01hdm0z54mf9wabp9q4v0ve4gh",
        "product": {
          "id": "pro_01hdm0xkht8tw1w2hfrd58mvdg",
          "name": "Enterprise",
          "description": "50 musics\n100 medias\n10 simultaneous",
          "image_url": ""
        },
        "billing_cycle": {
          "interval": "month",
          "frequency": 1
        },
        "trial_period": null,
        "quantity": 1,
        "totals": {
          "subtotal": 98.76,
          "tax": 0,
          "total": 98.76,
          "discount": 0,
          "balance": 98.76,
          "credit": 0
        },
        "recurring_totals": {
          "subtotal": 98.76,
          "tax": 0,
          "discount": 0,
          "total": 98.76
        }
      }
    ],
    "totals": {
      "subtotal": 98.76,
      "tax": 0,
      "total": 98.76,
      "discount": 0,
      "credit": 0,
      "balance": 0
    },
    "payment": {
      "method_details": {
        "type": "card",
        "card": {
          "type": "visa",
          "last4": "5556",
          "expiry_month": 12,
          "expiry_year": 2026
        }
      }
    },
    "settings": {
      "display_mode": "wide-overlay",
      "theme": "light"
    },
    "recurring_totals": {
      "subtotal": 98.76,
      "tax": 0,
      "discount": 0,
      "total": 98.76,
      "balance": 98.76,
      "credit": 0
    }
  }
}
*/
