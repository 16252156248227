import axios from "axios";

const BASE_URL = process.env.REACT_APP_MUZE_API_HOST || "http://localhost:8002";
const APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;

const setIntercomUserData = () => {
  const w = window as any;
  let logged_user: any = w.localStorage.getItem(
    "firebase:authUser:AIzaSyD37EVrLn_NQ6wD-Slh-hyWnSCp4nPAVBg:[DEFAULT]"
  );
  logged_user = JSON.parse(logged_user);

  if (!logged_user) return;

  w.Intercom("boot", {
    api_base: "https://api-iam.intercom.io",
    app_id: APP_ID,
    name: logged_user.displayName,
    email: logged_user.email,
  });
};

const checkSimultaneousGeneration = async (ownerId: string) => {
  try {
    const res = await axios.get(
      `${BASE_URL}/videos/in_simultaneous/${ownerId}`,
      { headers: { access_token: "abc123" } }
    );
    const { limit, used } = res.data.payload;
    return limit > used;
  } catch (err: any) {
    console.error(err);
    if (err.response.status === 404) return true;
    return false;
  }
};

const checkSimultaneousGenerationRestyle = async (ownerId: string) => {
  try {
    const res = await axios.get(
      `${BASE_URL}/videos/in_simultaneous/restyle/${ownerId}`,
      { headers: { access_token: "abc123" } }
    );
    const { limit, used } = res.data.payload;
    return limit > used;
  } catch (err: any) {
    console.error(err);
    if (err.response.status === 404) return true;
    return false;
  }
};

const validTime = (
  subject: any,
  seconds: number,
  index: number,
  end: number
) => {
  if (index === subject.length - 1 && end < seconds) return false;
  if (
    index < subject.length - 1 &&
    seconds >= formatSeconds(subject[index + 1].time)
  )
    return false;
  if (index > 0 && seconds <= formatSeconds(subject[index - 1].time))
    return false;
  return true;
};

const formatSeconds = (time: string) => {
  const timeSplit = time.split(":");
  const m = Math.floor(Number(timeSplit[0]) * 60);
  const s = Number(timeSplit[1]);
  return m + s;
};
export {
  checkSimultaneousGeneration,
  setIntercomUserData,
  validTime,
  checkSimultaneousGenerationRestyle,
};
