//envs
const BASE_URL_VIDEO = process.env.REACT_APP_VIDEOS_PUBLIC_TEMPLATE;
const BASE_URL_AUDIO = process.env.REACT_APP_AUDIOS_PUBLIC_TEMPLATE;

export default {
  template: [
    {
      video_id: "video-1723311c-0b00-4fa5-8c38-369d18ac6f2f",
      audio_url: `${BASE_URL_AUDIO}files/audio-8de7dd95-12da-4e14-bd28-f55e20ef256f.mp3`,
      video_url: `${BASE_URL_VIDEO}demo@mymuze.art/video-1723311c-0b00-4fa5-8c38-369d18ac6f2f.mp4`,
      image:
        "https://muze-mvp-dev-dreambooth-images-public.s3.amazonaws.com/temp/demo@mymuze.art/portrait of a rap artist set against a neon-lit cityscape.png",
      video_settings: {
        video_name: "Urban Futuristic",
        create_preview: false,
        style: {
          number_of_prompts: 1,
          theme_id: "Photorealistic",
          subject_matter: [
            "portrait of a rap artist set against a neon-lit cityscape",
            "urban cityscape at twilight, with graffiti-covered walls",
            "portrait of a giraffe wearing headphones set against a neon-lit cityscape",
          ],
          subject_matter_time: [],
          orientation: "vertical",
          dreambooth_id: null,
          enable_negative_prompts: true,
          prompt_times: null,
        },
        animation: {
          duration: 45.0,
          fps: 24,
          seed: 0,
          cfg: 14.0,
          strength: 0.6,
          anti_blur: 0.75,
          noise: 0.2,
          contrast: 1.0,
          fov: 70.0,
          steps: 35,
          animation_mode: "3D",
          diffusion_cadence: 3,
          sampler: "default",
          initial_image_key:
            "temp/demo@mymuze.art/portrait of a rap artist set against a neon-lit cityscape.png",
          initial_image_bucket: "muze-mvp-dev-dreambooth-images-public",
          initial_image_strength: 1.0,
        },
        build: { reverse_loop: false },
        audio: {
          audio_asset_id: "audio-8de7dd95-12da-4e14-bd28-f55e20ef256f",
          auto_invert: false,
          records: [
            {
              stem: "drums",
              camera: "translation_z",
              strength_max: 0.8,
              strength_min: 0.0,
              invert: false,
              decay: 0.0,
              fade_duration: 0,
              signal_shape: "pulse",
              shape_param: 0.5,
              onset_time: 0.0,
              end_time: 45.0,
            },
          ],
          strength_adjust_for_motion: true,
          motion_compensation_method: "maui",
          start_time: 0.0,
          stop_time: 45.0,
          name: "Peter Spacey - 4x20 feat Floating Anarchy.mp3",
        },
        audio_reactive_config: {
          enable_constant_motion_segments: true,
          enable_zoom_pulse_for_drums: true,
          enable_vocal_shake: false,
          constant_motion_strength: 0.6,
          min_segment_duration: 6.0,
          enable_transitions_on_segment_boundaries: false,
          sync_prompt_changes_to_segment_boundaries: true,
        },
        transitions: [
          {
            type: "ConstantRotateX_CCW",
            offset_start: 7.0,
            duration: 7.0,
            strength: 0.6,
            transition_duration: 1.4,
            transition_duration_out: 1.4,
          },
          {
            type: "ConstantRotateX_CW",
            offset_start: 14.0,
            duration: 6.0,
            strength: 0.6,
            transition_duration: 1.2,
            transition_duration_out: 1.2,
          },
          {
            type: "ConstantRotateX_CW",
            offset_start: 20.0,
            duration: 11.0,
            strength: 0.6,
            transition_duration: 2.2,
            transition_duration_out: 2.2,
          },
          {
            type: "ConstantRotateX_CW",
            offset_start: 39.0,
            duration: 18.0,
            strength: 0.6,
            transition_duration: 3.6,
            transition_duration_out: 3.6,
          },
          {
            type: "ConstantRotateY_CCW",
            offset_start: 31.0,
            duration: 8.0,
            strength: 0.6,
            transition_duration: 1.6,
            transition_duration_out: 1.6,
          },
          {
            type: "ConstantRotateY_CW",
            offset_start: 0.0,
            duration: 7.0,
            strength: 0.6,
            transition_duration: 1.4,
            transition_duration_out: 1.4,
          },
          {
            type: "ConstantRotateY_CW",
            offset_start: 7.0,
            duration: 7.0,
            strength: 0.6,
            transition_duration: 1.4,
            transition_duration_out: 1.4,
          },
          {
            type: "ConstantRotateY_CW",
            offset_start: 39.0,
            duration: 18.0,
            strength: 0.6,
            transition_duration: 3.6,
            transition_duration_out: 3.6,
          },
          {
            type: "ConstantZoomIn",
            offset_start: 20.0,
            duration: 11.0,
            strength: 0.6,
            transition_duration: 2.2,
            transition_duration_out: 2.2,
          },
          {
            type: "ConstantZoomIn",
            offset_start: 31.0,
            duration: 8.0,
            strength: 0.6,
            transition_duration: 1.6,
            transition_duration_out: 1.6,
          },
          {
            type: "ConstantZoomOut",
            offset_start: 0.0,
            duration: 7.0,
            strength: 0.6,
            transition_duration: 1.4,
            transition_duration_out: 1.4,
          },
          {
            type: "ConstantZoomOut",
            offset_start: 14.0,
            duration: 6.0,
            strength: 0.6,
            transition_duration: 1.2,
            transition_duration_out: 1.2,
          },
        ],
        strength_prompt_compensation: {
          enabled: true,
          duration: 0.5,
          strength: 0.15,
        },
        misc_config: {},
        temp_audio: { name: "", key: "", delete_source: false },
      },
    },

    {
      video_id: "video-c4208736-bce3-4b4c-a9d8-ca586eb3698d",
      audio_url: `${BASE_URL_AUDIO}files/audio-694b6142-dc7d-43bc-a42e-e5f96371821e.mp3`,
      video_url: `${BASE_URL_VIDEO}demo@mymuze.art/video-c4208736-bce3-4b4c-a9d8-ca586eb3698d.mp4`,
      image:
        "https://muze-mvp-dev-dreambooth-images-public.s3.amazonaws.com/temp/demo@mymuze.art/girl with pink hair, vaporwave style,.png",
      video_settings: {
        video_name: "Cyberpunk Surf",
        create_preview: false,
        style: {
          number_of_prompts: 1,
          theme_id: "Photorealistic",
          subject_matter: [
            "girl with pink hair, vaporwave style",
            "futuristic cyberpunk cityscape at night with towering neon-lit skyscrapers, flying cars, and a crowd of humans and androids",
            "cyberpunk dancer with pink hair, set against a neon-lit cityscape at night",
            "neon-noir cyberpunk setting of a dj with glowing, cybernetic eyes",
          ],
          subject_matter_time: [],
          orientation: "vertical",
          dreambooth_id: null,
          enable_negative_prompts: true,
          prompt_times: null,
        },
        animation: {
          duration: 71.0,
          fps: 24,
          seed: 0,
          cfg: 14.0,
          strength: 0.6,
          anti_blur: 0.75,
          noise: 0.2,
          contrast: 1.0,
          fov: 70.0,
          steps: 35,
          animation_mode: "3D",
          diffusion_cadence: 3,
          sampler: "default",
          initial_image_key:
            "temp/demo@mymuze.art/girl with pink hair, vaporwave style,.png",
          initial_image_bucket: "muze-mvp-dev-dreambooth-images-public",
          initial_image_strength: 1.0,
        },
        build: { reverse_loop: false },
        audio: {
          audio_asset_id: "audio-694b6142-dc7d-43bc-a42e-e5f96371821e",
          auto_invert: false,
          records: [
            {
              stem: "drums",
              camera: "translation_z",
              strength_max: 0.8,
              strength_min: 0.0,
              invert: false,
              decay: 0.0,
              fade_duration: 0,
              signal_shape: "pulse",
              shape_param: 0.5,
              onset_time: 0.0,
              end_time: 71.0,
            },
            {
              stem: "vocals",
              camera: "rotation_3d_z",
              strength_max: 0.5,
              strength_min: 0.0,
              invert: false,
              decay: 100.0,
              fade_duration: 0,
              signal_shape: "pulse",
              shape_param: 0.5,
              onset_time: 0.0,
              end_time: 71.0,
            },
          ],
          strength_adjust_for_motion: true,
          motion_compensation_method: "maui",
          start_time: 115.0,
          stop_time: 186.0,
          name: "Choke - Whos This.mp3",
        },
        audio_reactive_config: {
          enable_constant_motion_segments: true,
          enable_zoom_pulse_for_drums: true,
          enable_vocal_shake: true,
          constant_motion_strength: 0.5,
          min_segment_duration: 6.0,
          enable_transitions_on_segment_boundaries: false,
          sync_prompt_changes_to_segment_boundaries: true,
        },
        transitions: [
          {
            type: "ConstantRotateX_CCW",
            offset_start: 0.0,
            duration: 30.0,
            strength: 0.5,
            transition_duration: 6.0,
            transition_duration_out: 6.0,
          },
          {
            type: "ConstantRotateX_CW",
            offset_start: 36.0,
            duration: 30.0,
            strength: 0.5,
            transition_duration: 6.0,
            transition_duration_out: 6.0,
          },
          {
            type: "ConstantRotateX_CW",
            offset_start: 66.0,
            duration: 26.0,
            strength: 0.5,
            transition_duration: 5.2,
            transition_duration_out: 5.2,
          },
          {
            type: "ConstantRotateY_CW",
            offset_start: 30.0,
            duration: 6.0,
            strength: 0.5,
            transition_duration: 1.2,
            transition_duration_out: 1.2,
          },
          {
            type: "ConstantRotateY_CW",
            offset_start: 36.0,
            duration: 30.0,
            strength: 0.5,
            transition_duration: 6.0,
            transition_duration_out: 6.0,
          },
          {
            type: "ConstantZoomIn",
            offset_start: 0.0,
            duration: 30.0,
            strength: 0.5,
            transition_duration: 6.0,
            transition_duration_out: 6.0,
          },
          {
            type: "ConstantZoomIn",
            offset_start: 30.0,
            duration: 6.0,
            strength: 0.5,
            transition_duration: 1.2,
            transition_duration_out: 1.2,
          },
          {
            type: "ConstantZoomIn",
            offset_start: 66.0,
            duration: 26.0,
            strength: 0.5,
            transition_duration: 5.2,
            transition_duration_out: 5.2,
          },
        ],
        strength_prompt_compensation: {
          enabled: true,
          duration: 0.5,
          strength: 0.15,
        },
        misc_config: {},
        temp_audio: {},
      },
    },

    {
      video_id: "video-1dbef070-9223-42e3-bb51-90618a99ebe8",
      audio_url: `${BASE_URL_AUDIO}files/audio-694b6142-dc7d-43bc-a42e-e5f96371821e.mp3`,
      video_url: `${BASE_URL_VIDEO}demo@mymuze.art/video-1dbef070-9223-42e3-bb51-90618a99ebe8.mp4`,
      image:
        "https://muze-mvp-dev-dreambooth-images-public.s3.amazonaws.com/temp/demo@mymuze.art/hispanic-female-enjoying-music-with-headphones-and-2023-11-27-05-27-01-utc.jpg",
      video_settings: {
        video_name: "Clubbing",
        create_preview: false,
        style: {
          number_of_prompts: 1,
          theme_id: "Photorealistic",
          subject_matter: [
            "hispanic female wearing headphones and dancing",
            "portrait of a cyberpunk girl with pink hair, dark background",
            "street scene with a group of people gathered around a graffiti-covered car",
            "portrait of an eccentric rap star sitting on a pile of dollar bills",
          ],
          subject_matter_time: [],
          orientation: "vertical",
          dreambooth_id: null,
          enable_negative_prompts: true,
          prompt_times: null,
        },
        animation: {
          duration: 40.0,
          fps: 24,
          seed: 0,
          cfg: 14.0,
          strength: 0.6,
          anti_blur: 0.75,
          noise: 0.2,
          contrast: 1.0,
          fov: 70.0,
          steps: 35,
          animation_mode: "3D",
          diffusion_cadence: 3,
          sampler: "default",
          initial_image_key:
            "temp/demo@mymuze.art/hispanic-female-enjoying-music-with-headphones-and-2023-11-27-05-27-01-utc.jpg",
          initial_image_bucket: "muze-mvp-dev-dreambooth-images-public",
          initial_image_strength: 1.0,
        },
        build: { reverse_loop: false },
        audio: {
          audio_asset_id: "audio-694b6142-dc7d-43bc-a42e-e5f96371821e",
          auto_invert: false,
          records: [
            {
              stem: "drums",
              camera: "translation_z",
              strength_max: 0.8,
              strength_min: 0.0,
              invert: false,
              decay: 0.0,
              fade_duration: 0,
              signal_shape: "pulse",
              shape_param: 0.5,
              onset_time: 0.0,
              end_time: 40.0,
            },
            {
              stem: "vocals",
              camera: "rotation_3d_z",
              strength_max: 0.5,
              strength_min: 0.0,
              invert: false,
              decay: 100.0,
              fade_duration: 0,
              signal_shape: "pulse",
              shape_param: 0.5,
              onset_time: 0.0,
              end_time: 40.0,
            },
          ],
          strength_adjust_for_motion: true,
          motion_compensation_method: "maui",
          start_time: 0.0,
          stop_time: 40.0,
          name: "Choke - Whos This.mp3",
        },
        audio_reactive_config: {
          enable_constant_motion_segments: true,
          enable_zoom_pulse_for_drums: true,
          enable_vocal_shake: true,
          constant_motion_strength: 0.5,
          min_segment_duration: 6.0,
          enable_transitions_on_segment_boundaries: false,
          sync_prompt_changes_to_segment_boundaries: true,
        },
        transitions: [
          {
            type: "ConstantRotateX_CCW",
            offset_start: 0.0,
            duration: 30.0,
            strength: 0.5,
            transition_duration: 6.0,
            transition_duration_out: 6.0,
          },
          {
            type: "ConstantRotateX_CCW",
            offset_start: 30.0,
            duration: 6.0,
            strength: 0.5,
            transition_duration: 1.2,
            transition_duration_out: 1.2,
          },
          {
            type: "ConstantRotateX_CW",
            offset_start: 36.0,
            duration: 30.0,
            strength: 0.5,
            transition_duration: 6.0,
            transition_duration_out: 6.0,
          },
          {
            type: "ConstantRotateY_CCW",
            offset_start: 36.0,
            duration: 30.0,
            strength: 0.5,
            transition_duration: 6.0,
            transition_duration_out: 6.0,
          },
          {
            type: "ConstantZoomIn",
            offset_start: 0.0,
            duration: 30.0,
            strength: 0.5,
            transition_duration: 6.0,
            transition_duration_out: 6.0,
          },
          {
            type: "ConstantZoomOut",
            offset_start: 30.0,
            duration: 6.0,
            strength: 0.5,
            transition_duration: 1.2,
            transition_duration_out: 1.2,
          },
        ],
        strength_prompt_compensation: {
          enabled: true,
          duration: 0.5,
          strength: 0.15,
        },
        misc_config: {},
        temp_audio: {},
      },
    },

    {
      video_id: "video-678e2793-0f3b-453f-8260-0b80aed26679",
      audio_url: `${BASE_URL_AUDIO}files/audio-108c64db-7080-4341-8066-c925acf7ff58.mp3`,
      video_url: `${BASE_URL_VIDEO}mauimauricio83@gmail.com/video-678e2793-0f3b-453f-8260-0b80aed26679.mp4`,
      image:
        "https://muze-mvp-dev-thumbnails-public.s3.amazonaws.com/thumbnails/thumbnail-543031f0-1f81-43ad-a340-e744d6d03e07.png",
      video_settings: {
        video_name: "Anime Pop",
        create_preview: false,
        style: {
          number_of_prompts: 1,
          theme_id: "Pop",
          subject_matter: [
            "Anime of a Girl studying at her desk, headphones, Open window, city view, raining, daytime, studio ghibli style",
            "Anime of a man walking in new year, busy street, city view, raining, daytime, studio ghibli style",
            "Anime of a boy and girl flying in the air, bright clouds, sunny, rainbow, studio ghibli style",
            "Anime of a Girl sleeping at her desk, headphones, Open window, city view, raining, daytime",
          ],
          orientation: "vertical",
          dreambooth_id: null,
          enable_negative_prompts: true,
          prompt_times: null,
        },
        animation: {
          duration: 55.0,
          fps: 24,
          seed: 0,
          cfg: 14.0,
          strength: 0.6,
          anti_blur: 0.75,
          noise: 0.2,
          contrast: 1.0,
          fov: 70.0,
          steps: 35,
          animation_mode: "2D",
          diffusion_cadence: 3,
          sampler: "default",
          initial_image_key: "",
          initial_image_bucket: "",
          initial_image_strength: 1.0,
        },
        build: { reverse_loop: false },
        audio: {
          audio_asset_id: "audio-108c64db-7080-4341-8066-c925acf7ff58",
          auto_invert: false,
          records: [],
          strength_adjust_for_motion: true,
          motion_compensation_method: "maui",
          start_time: 16.0,
          stop_time: 71.0,
          name: "Peter Spacey - Feel.mp3",
        },
        audio_reactive_config: {
          enable_constant_motion_segments: true,
          enable_zoom_pulse_for_drums: false,
          enable_vocal_shake: false,
          constant_motion_strength: 1.0,
          min_segment_duration: 6.0,
          enable_transitions_on_segment_boundaries: false,
          sync_prompt_changes_to_segment_boundaries: true,
        },
        transitions: [
          {
            type: "ConstantTranslationDown",
            offset_start: 30.0,
            duration: 9.280000000000001,
            strength: 1.0,
            transition_duration: 1.8560000000000003,
            transition_duration_out: 1.8560000000000003,
          },
          {
            type: "ConstantTranslationDown",
            offset_start: 39.28,
            duration: 8.719999999999999,
            strength: 1.0,
            transition_duration: 1.7439999999999998,
            transition_duration_out: 1.7439999999999998,
          },
          {
            type: "ConstantTranslationRight",
            offset_start: 30.0,
            duration: 9.280000000000001,
            strength: 1.0,
            transition_duration: 1.8560000000000003,
            transition_duration_out: 1.8560000000000003,
          },
          {
            type: "ConstantTranslationRight",
            offset_start: 48.0,
            duration: 7.0,
            strength: 1.0,
            transition_duration: 1.4,
            transition_duration_out: 1.4,
          },
          {
            type: "ConstantTranslationUp",
            offset_start: 0.0,
            duration: 30.0,
            strength: 1.0,
            transition_duration: 6.0,
            transition_duration_out: 6.0,
          },
          {
            type: "ConstantTranslationUp",
            offset_start: 48.0,
            duration: 7.0,
            strength: 1.0,
            transition_duration: 1.4,
            transition_duration_out: 1.4,
          },
          {
            type: "ConstantZoomOut",
            offset_start: 0.0,
            duration: 30.0,
            strength: 1.0,
            transition_duration: 6.0,
            transition_duration_out: 6.0,
          },
          {
            type: "ConstantZoomOut",
            offset_start: 39.28,
            duration: 8.719999999999999,
            strength: 1.0,
            transition_duration: 1.7439999999999998,
            transition_duration_out: 1.7439999999999998,
          },
        ],
        strength_prompt_compensation: {
          enabled: true,
          duration: 0.5,
          strength: 0.15,
        },
        misc_config: {},
        temp_audio: { name: "", key: "", delete_source: false },
      },
    },

    {
      video_id: "video-baaa140c-4d5d-4294-b679-3f594ef3bea3",
      audio_url: `${BASE_URL_AUDIO}files/audio-5a878e8a-162c-40d8-851c-69a7c3c6c544.mp3`,
      video_url: `${BASE_URL_VIDEO}leeork@mymuze.art/video-baaa140c-4d5d-4294-b679-3f594ef3bea3.mp4`,
      image:
        "https://muze-mvp-dev-thumbnails-public.s3.amazonaws.com/thumbnails/thumbnail-0275f91d-a78c-46e6-a7cd-9380520ef3b1.png",
      video_settings: {
        video_name: "Beach Vibes",
        create_preview: false,
        style: {
          number_of_prompts: 1,
          theme_id: "Pop",
          subject_matter: [
            "zoom out from the back on a beautiful jamaican woman watching the sunset on a jamaican beach",
            "bird's eye view of children splashing in the water playing in the sunset on the beach in jamaica",
            "flowers and succulent plants growing in the hills in jamaica",
            "zoom out on a handsome man with dreadlocks lying on his back in the field",
            "portrait of a beautiful jamaican woman with headphones on the beach",
          ],
          subject_matter_time: ["00:00", "00:12", "00:24", "00:36", "00:48"],
          orientation: "vertical",
          dreambooth_id: null,
          enable_negative_prompts: true,
          prompt_times: null,
        },
        animation: {
          duration: 58.0,
          fps: 24,
          seed: 59259,
          cfg: 14.0,
          strength: 0.6,
          anti_blur: 0.75,
          noise: 0.2,
          contrast: 1.0,
          fov: 70.0,
          steps: 26,
          animation_mode: "2D",
          diffusion_cadence: 3,
          sampler: "default",
          initial_image_key: "",
          initial_image_bucket: "",
          initial_image_strength: 0.7,
        },
        build: { reverse_loop: false },
        audio: {
          audio_asset_id: "audio-5a878e8a-162c-40d8-851c-69a7c3c6c544",
          auto_invert: false,
          records: [
            {
              stem: "drums",
              camera: "zoom",
              strength_max: 0.8,
              strength_min: 0.0,
              invert: false,
              decay: 0.0,
              fade_duration: 0,
              signal_shape: "pulse",
              shape_param: 0.5,
              onset_time: 0.0,
              end_time: 58.0,
            },
            {
              stem: "vocals",
              camera: "angle",
              strength_max: 0.5,
              strength_min: 0.0,
              invert: false,
              decay: 100.0,
              fade_duration: 0,
              signal_shape: "pulse",
              shape_param: 0.5,
              onset_time: 0.0,
              end_time: 58.0,
            },
          ],
          strength_adjust_for_motion: true,
          motion_compensation_method: "maui",
          start_time: 0.0,
          stop_time: 58.0,
          name: "yarin primak - no cap",
        },
        audio_reactive_config: {
          enable_constant_motion_segments: true,
          enable_zoom_pulse_for_drums: true,
          enable_vocal_shake: true,
          constant_motion_strength: 0.5,
          min_segment_duration: 6.0,
          enable_transitions_on_segment_boundaries: false,
          sync_prompt_changes_to_segment_boundaries: true,
        },
        transitions: [
          {
            type: "ConstantTranslationDown",
            offset_start: 27.6,
            duration: 7.200000000000003,
            strength: 0.5,
            transition_duration: 1.4400000000000006,
            transition_duration_out: 1.4400000000000006,
          },
          {
            type: "ConstantTranslationDown",
            offset_start: 34.800000000000004,
            duration: 6.600000000000001,
            strength: 0.5,
            transition_duration: 1.3200000000000003,
            transition_duration_out: 1.3200000000000003,
          },
          {
            type: "ConstantTranslationDown",
            offset_start: 48.0,
            duration: 6.600000000000001,
            strength: 0.5,
            transition_duration: 1.3200000000000003,
            transition_duration_out: 1.3200000000000003,
          },
          {
            type: "ConstantTranslationLeft",
            offset_start: 7.8,
            duration: 6.6000000000000005,
            strength: 0.5,
            transition_duration: 1.32,
            transition_duration_out: 1.32,
          },
          {
            type: "ConstantTranslationLeft",
            offset_start: 20.8,
            duration: 14.000000000000004,
            strength: 0.5,
            transition_duration: 2.8000000000000007,
            transition_duration_out: 2.8000000000000007,
          },
          {
            type: "ConstantTranslationLeft",
            offset_start: 41.400000000000006,
            duration: 6.599999999999994,
            strength: 0.5,
            transition_duration: 1.319999999999999,
            transition_duration_out: 1.319999999999999,
          },
          {
            type: "ConstantTranslationLeft",
            offset_start: 54.6,
            duration: 7.0,
            strength: 0.5,
            transition_duration: 1.4,
            transition_duration_out: 1.4,
          },
          {
            type: "ConstantTranslationRight",
            offset_start: 0.0,
            duration: 7.8,
            strength: 0.5,
            transition_duration: 1.56,
            transition_duration_out: 1.56,
          },
          {
            type: "ConstantTranslationUp",
            offset_start: 0.0,
            duration: 7.8,
            strength: 0.5,
            transition_duration: 1.56,
            transition_duration_out: 1.56,
          },
          {
            type: "ConstantTranslationUp",
            offset_start: 7.8,
            duration: 6.6000000000000005,
            strength: 0.5,
            transition_duration: 1.32,
            transition_duration_out: 1.32,
          },
          {
            type: "ConstantTranslationUp",
            offset_start: 14.4,
            duration: 6.4,
            strength: 0.5,
            transition_duration: 1.28,
            transition_duration_out: 1.28,
          },
          {
            type: "ConstantZoomIn",
            offset_start: 14.4,
            duration: 6.4,
            strength: 0.5,
            transition_duration: 1.28,
            transition_duration_out: 1.28,
          },
          {
            type: "ConstantZoomIn",
            offset_start: 54.6,
            duration: 7.0,
            strength: 0.5,
            transition_duration: 1.4,
            transition_duration_out: 1.4,
          },
          {
            type: "ConstantZoomOut",
            offset_start: 20.8,
            duration: 6.800000000000001,
            strength: 0.5,
            transition_duration: 1.36,
            transition_duration_out: 1.36,
          },
          {
            type: "ConstantZoomOut",
            offset_start: 34.800000000000004,
            duration: 6.600000000000001,
            strength: 0.5,
            transition_duration: 1.3200000000000003,
            transition_duration_out: 1.3200000000000003,
          },
          {
            type: "ConstantZoomOut",
            offset_start: 41.400000000000006,
            duration: 6.599999999999994,
            strength: 0.5,
            transition_duration: 1.319999999999999,
            transition_duration_out: 1.319999999999999,
          },
          {
            type: "ConstantZoomOut",
            offset_start: 48.0,
            duration: 6.600000000000001,
            strength: 0.5,
            transition_duration: 1.3200000000000003,
            transition_duration_out: 1.3200000000000003,
          },
        ],
        strength_prompt_compensation: {
          enabled: true,
          duration: 0.38,
          strength: 0.08,
        },
        misc_config: {},
        temp_audio: { name: "", key: "", delete_source: false },
      },
    },

    {
      video_id: "video-72ef3647-7f1f-4e00-83ad-0f624209f224",
      audio_url: `${BASE_URL_AUDIO}files/audio-2b390b58-f71c-418b-b653-a7b065e788cb.mp3`,
      video_url: `${BASE_URL_VIDEO}leeork@mymuze.art/video-72ef3647-7f1f-4e00-83ad-0f624209f224.mp4`,
      image:
        "https://muze-mvp-dev-thumbnails-public.s3.amazonaws.com/thumbnails/thumbnail-6b2e7be8-62db-41bf-ad00-72495f3b4e3f.png",
      video_settings: {
        video_name: "Surreal Mushrooms",
        create_preview: false,
        style: {
          number_of_prompts: 1,
          theme_id: "Surreal",
          subject_matter: [
            "a huge space ship in the shape of a mushroom hovering",
            "huge clock in the sky melting",
            "the head of an old wise man hovering in the sky",
            "cheshire cat smiling on a tree branch. ",
            "huge purple caterpillar smoking a hookah",
            "amanita muscaria mushrooms glowing",
          ],
          subject_matter_time: [
            "00:00",
            "00:10",
            "00:20",
            "00:30",
            "00:40",
            "00:50",
          ],
          orientation: "vertical",
          dreambooth_id: null,
          enable_negative_prompts: true,
          prompt_times: null,
        },
        animation: {
          duration: 58.0,
          fps: 24,
          seed: 59259,
          cfg: 14.0,
          strength: 0.6,
          anti_blur: 0.75,
          noise: 0.2,
          contrast: 1.0,
          fov: 70.0,
          steps: 26,
          animation_mode: "2D",
          diffusion_cadence: 3,
          sampler: "default",
          initial_image_key: "",
          initial_image_bucket: "",
          initial_image_strength: 0.7,
        },
        build: { reverse_loop: false },
        audio: {
          audio_asset_id: "audio-2b390b58-f71c-418b-b653-a7b065e788cb",
          auto_invert: false,
          records: [
            {
              stem: "drums",
              camera: "zoom",
              strength_max: 0.8,
              strength_min: 0.0,
              invert: false,
              decay: 0.0,
              fade_duration: 0,
              signal_shape: "pulse",
              shape_param: 0.5,
              onset_time: 0.0,
              end_time: 58.0,
            },
            {
              stem: "vocals",
              camera: "angle",
              strength_max: 0.5,
              strength_min: 0.0,
              invert: false,
              decay: 100.0,
              fade_duration: 0,
              signal_shape: "pulse",
              shape_param: 0.5,
              onset_time: 0.0,
              end_time: 58.0,
            },
          ],
          strength_adjust_for_motion: true,
          motion_compensation_method: "maui",
          start_time: 0.0,
          stop_time: 58.0,
          name: "Peter Spacey - Feel.mp3",
        },
        audio_reactive_config: {
          enable_constant_motion_segments: true,
          enable_zoom_pulse_for_drums: true,
          enable_vocal_shake: true,
          constant_motion_strength: 0.6,
          min_segment_duration: 6.0,
          enable_transitions_on_segment_boundaries: false,
          sync_prompt_changes_to_segment_boundaries: true,
        },
        transitions: [
          {
            type: "ConstantTranslationDown",
            offset_start: 30.0,
            duration: 9.280000000000001,
            strength: 0.6,
            transition_duration: 1.8560000000000003,
            transition_duration_out: 1.8560000000000003,
          },
          {
            type: "ConstantTranslationLeft",
            offset_start: 55.0,
            duration: 9.0,
            strength: 0.6,
            transition_duration: 1.8,
            transition_duration_out: 1.8,
          },
          {
            type: "ConstantTranslationRight",
            offset_start: 30.0,
            duration: 9.280000000000001,
            strength: 0.6,
            transition_duration: 1.8560000000000003,
            transition_duration_out: 1.8560000000000003,
          },
          {
            type: "ConstantTranslationRight",
            offset_start: 39.28,
            duration: 8.719999999999999,
            strength: 0.6,
            transition_duration: 1.7439999999999998,
            transition_duration_out: 1.7439999999999998,
          },
          {
            type: "ConstantTranslationUp",
            offset_start: 0.0,
            duration: 30.0,
            strength: 0.6,
            transition_duration: 6.0,
            transition_duration_out: 6.0,
          },
          {
            type: "ConstantTranslationUp",
            offset_start: 48.0,
            duration: 7.0,
            strength: 0.6,
            transition_duration: 1.4,
            transition_duration_out: 1.4,
          },
          {
            type: "ConstantZoomIn",
            offset_start: 48.0,
            duration: 7.0,
            strength: 0.6,
            transition_duration: 1.4,
            transition_duration_out: 1.4,
          },
          {
            type: "ConstantZoomIn",
            offset_start: 55.0,
            duration: 9.0,
            strength: 0.6,
            transition_duration: 1.8,
            transition_duration_out: 1.8,
          },
          {
            type: "ConstantZoomOut",
            offset_start: 0.0,
            duration: 30.0,
            strength: 0.6,
            transition_duration: 6.0,
            transition_duration_out: 6.0,
          },
          {
            type: "ConstantZoomOut",
            offset_start: 39.28,
            duration: 8.719999999999999,
            strength: 0.6,
            transition_duration: 1.7439999999999998,
            transition_duration_out: 1.7439999999999998,
          },
        ],
        strength_prompt_compensation: {
          enabled: true,
          duration: 0.38,
          strength: 0.08,
        },
        misc_config: {},
        temp_audio: { name: "", key: "", delete_source: false },
      },
    },

    {
      video_id: "video-f4420bce-77bc-4491-82fc-998cd63e97c5",
      audio_url: `${BASE_URL_AUDIO}files/audio-3a27aa3a-33ec-497f-a93d-f52d8fc17c05.mp3`,
      video_url: `${BASE_URL_VIDEO}leeork@mymuze.art/video-f4420bce-77bc-4491-82fc-998cd63e97c5.mp4`,
      image:
        "https://muze-mvp-dev-thumbnails-public.s3.amazonaws.com/thumbnails/thumbnail-9014c7df-9e98-4fbc-9a82-98554d365a95.png",
      video_settings: {
        video_name: "Lonely Robot",
        create_preview: false,
        style: {
          number_of_prompts: 1,
          theme_id: "Indie",
          subject_matter: [
            "a lonely robot sitting in lotus position in space meditating",
            "delicate black and white flowers sprouting around a lonely robot",
            "lonely robot sitting on a cloud",
          ],
          subject_matter_time: [],
          orientation: "vertical",
          dreambooth_id: null,
          enable_negative_prompts: true,
          prompt_times: null,
        },
        animation: {
          duration: 30.0,
          fps: 24,
          seed: 17830,
          cfg: 14.0,
          strength: 0.6,
          anti_blur: 0.75,
          noise: 0.2,
          contrast: 1.0,
          fov: 70.0,
          steps: 26,
          animation_mode: "2D",
          diffusion_cadence: 3,
          sampler: "default",
          initial_image_key: "",
          initial_image_bucket: "",
          initial_image_strength: 0.7,
        },
        build: { reverse_loop: false },
        audio: {
          audio_asset_id: "audio-3a27aa3a-33ec-497f-a93d-f52d8fc17c05",
          auto_invert: false,
          records: [
            {
              stem: "drums",
              camera: "zoom",
              strength_max: 0.8,
              strength_min: 0.0,
              invert: false,
              decay: 0.0,
              fade_duration: 0,
              signal_shape: "pulse",
              shape_param: 0.5,
              onset_time: 0.0,
              end_time: 30.0,
            },
            {
              stem: "vocals",
              camera: "angle",
              strength_max: 0.5,
              strength_min: 0.0,
              invert: false,
              decay: 100.0,
              fade_duration: 0,
              signal_shape: "pulse",
              shape_param: 0.5,
              onset_time: 0.0,
              end_time: 30.0,
            },
          ],
          strength_adjust_for_motion: true,
          motion_compensation_method: "maui",
          start_time: 0.0,
          stop_time: 30.0,
          name: "Peter Spacey - Grumpy Snowflake.mp3",
        },
        audio_reactive_config: {
          enable_constant_motion_segments: true,
          enable_zoom_pulse_for_drums: true,
          enable_vocal_shake: true,
          constant_motion_strength: 0.5,
          min_segment_duration: 6.0,
          enable_transitions_on_segment_boundaries: false,
          sync_prompt_changes_to_segment_boundaries: true,
        },
        transitions: [
          {
            type: "ConstantTranslationDown",
            offset_start: 0.0,
            duration: 10.0,
            strength: 0.5,
            transition_duration: 2.0,
            transition_duration_out: 2.0,
          },
          {
            type: "ConstantTranslationDown",
            offset_start: 20.02,
            duration: 10.0,
            strength: 0.5,
            transition_duration: 2.0,
            transition_duration_out: 2.0,
          },
          {
            type: "ConstantTranslationRight",
            offset_start: 10.0,
            duration: 10.02,
            strength: 0.5,
            transition_duration: 2.004,
            transition_duration_out: 2.004,
          },
          {
            type: "ConstantTranslationRight",
            offset_start: 20.02,
            duration: 10.0,
            strength: 0.5,
            transition_duration: 2.0,
            transition_duration_out: 2.0,
          },
          {
            type: "ConstantTranslationUp",
            offset_start: 10.0,
            duration: 10.02,
            strength: 0.5,
            transition_duration: 2.004,
            transition_duration_out: 2.004,
          },
          {
            type: "ConstantZoomOut",
            offset_start: 0.0,
            duration: 10.0,
            strength: 0.5,
            transition_duration: 2.0,
            transition_duration_out: 2.0,
          },
        ],
        strength_prompt_compensation: {
          enabled: true,
          duration: 1.0,
          strength: 0.3,
        },
        misc_config: {},
        temp_audio: { name: "", key: "", delete_source: false },
      },
    },

    {
      video_id: "video-786b654a-4b44-42e7-91f5-65f91aa9f3db",
      audio_url: `${BASE_URL_AUDIO}files/audio-1c9afd96-8161-4365-afc5-77a791d6fd4b.mp3`,
      video_url: `${BASE_URL_VIDEO}demo@mymuze.art/video-786b654a-4b44-42e7-91f5-65f91aa9f3db.mp4`,
      image:
        "https://muze-mvp-dev-dreambooth-images-public.s3.amazonaws.com/temp/demo@mymuze.art/5.jpg",
      video_settings: {
        video_name: "Fantastical Illustration",
        create_preview: false,
        style: {
          number_of_prompts: 1,
          theme_id: "Illustration",
          subject_matter: [
            "astronaut riding a unicorn to the moon",
            "unicorns and colorful zebras in space",
            "close up of giant flowers blooming ",
            "close up of meditating android calm and smiling",
          ],
          subject_matter_time: [],
          orientation: "vertical",
          dreambooth_id: null,
          enable_negative_prompts: true,
          prompt_times: null,
        },
        animation: {
          duration: 51.0,
          fps: 24,
          seed: 0,
          cfg: 14.0,
          strength: 0.6,
          anti_blur: 0.75,
          noise: 0.2,
          contrast: 1.0,
          fov: 70.0,
          steps: 35,
          animation_mode: "2D",
          diffusion_cadence: 3,
          sampler: "default",
          initial_image_key: "temp/demo@mymuze.art/5.jpg",
          initial_image_bucket: "muze-mvp-dev-dreambooth-images-public",
          initial_image_strength: 1.0,
        },
        build: { reverse_loop: false },
        audio: {
          audio_asset_id: "audio-1c9afd96-8161-4365-afc5-77a791d6fd4b",
          auto_invert: false,
          records: [
            {
              stem: "drums",
              camera: "zoom",
              strength_max: 0.8,
              strength_min: 0.0,
              invert: false,
              decay: 0.0,
              fade_duration: 0,
              signal_shape: "pulse",
              shape_param: 0.5,
              onset_time: 0.0,
              end_time: 51.0,
            },
            {
              stem: "vocals",
              camera: "angle",
              strength_max: 0.5,
              strength_min: 0.0,
              invert: false,
              decay: 100.0,
              fade_duration: 0,
              signal_shape: "pulse",
              shape_param: 0.5,
              onset_time: 0.0,
              end_time: 51.0,
            },
          ],
          strength_adjust_for_motion: true,
          motion_compensation_method: "maui",
          start_time: 0.0,
          stop_time: 51.0,
          name: "Peter Spacey - Frescos.mp3",
        },
        audio_reactive_config: {
          enable_constant_motion_segments: true,
          enable_zoom_pulse_for_drums: true,
          enable_vocal_shake: true,
          constant_motion_strength: 0.6,
          min_segment_duration: 6.0,
          enable_transitions_on_segment_boundaries: false,
          sync_prompt_changes_to_segment_boundaries: true,
        },
        transitions: [
          {
            type: "ConstantTranslationDown",
            offset_start: 9.0,
            duration: 6.0,
            strength: 0.6,
            transition_duration: 1.2,
            transition_duration_out: 1.2,
          },
          {
            type: "ConstantTranslationLeft",
            offset_start: 15.0,
            duration: 6.0,
            strength: 0.6,
            transition_duration: 1.2,
            transition_duration_out: 1.2,
          },
          {
            type: "ConstantTranslationLeft",
            offset_start: 21.0,
            duration: 7.0,
            strength: 0.6,
            transition_duration: 1.4,
            transition_duration_out: 1.4,
          },
          {
            type: "ConstantTranslationLeft",
            offset_start: 35.0,
            duration: 8.0,
            strength: 0.6,
            transition_duration: 1.6,
            transition_duration_out: 1.6,
          },
          {
            type: "ConstantTranslationRight",
            offset_start: 0.0,
            duration: 9.0,
            strength: 0.6,
            transition_duration: 1.8,
            transition_duration_out: 1.8,
          },
          {
            type: "ConstantTranslationRight",
            offset_start: 28.0,
            duration: 7.0,
            strength: 0.6,
            transition_duration: 1.4,
            transition_duration_out: 1.4,
          },
          {
            type: "ConstantTranslationRight",
            offset_start: 43.0,
            duration: 9.0,
            strength: 0.6,
            transition_duration: 1.8,
            transition_duration_out: 1.8,
          },
          {
            type: "ConstantTranslationUp",
            offset_start: 28.0,
            duration: 7.0,
            strength: 0.6,
            transition_duration: 1.4,
            transition_duration_out: 1.4,
          },
          {
            type: "ConstantTranslationUp",
            offset_start: 43.0,
            duration: 9.0,
            strength: 0.6,
            transition_duration: 1.8,
            transition_duration_out: 1.8,
          },
          {
            type: "ConstantZoomIn",
            offset_start: 9.0,
            duration: 6.0,
            strength: 0.6,
            transition_duration: 1.2,
            transition_duration_out: 1.2,
          },
          {
            type: "ConstantZoomIn",
            offset_start: 21.0,
            duration: 7.0,
            strength: 0.6,
            transition_duration: 1.4,
            transition_duration_out: 1.4,
          },
          {
            type: "ConstantZoomOut",
            offset_start: 0.0,
            duration: 9.0,
            strength: 0.6,
            transition_duration: 1.8,
            transition_duration_out: 1.8,
          },
          {
            type: "ConstantZoomOut",
            offset_start: 15.0,
            duration: 6.0,
            strength: 0.6,
            transition_duration: 1.2,
            transition_duration_out: 1.2,
          },
          {
            type: "ConstantZoomOut",
            offset_start: 35.0,
            duration: 8.0,
            strength: 0.6,
            transition_duration: 1.6,
            transition_duration_out: 1.6,
          },
        ],
        strength_prompt_compensation: {
          enabled: true,
          duration: 0.5,
          strength: 0.15,
        },
        misc_config: {},
        temp_audio: { name: "", key: "", delete_source: false },
      },
    },

    {
      video_id: "video-c0c2fea4-9523-4c57-8af2-a9262b44459c",
      audio_url: `${BASE_URL_AUDIO}files/audio-de8a6bca-8da8-4330-9a9e-64242ff6874b.mp3`,
      video_url: `${BASE_URL_VIDEO}demo@mymuze.art/video-c0c2fea4-9523-4c57-8af2-a9262b44459c.mp4`,
      image:
        "https://muze-mvp-dev-dreambooth-images-public.s3.amazonaws.com/temp/demo@mymuze.art/Comic book cover of blue eyes with pinks face, in the style of hauntingly beautiful illustrations, realistic hyper — detail, glowwave, bold lithographic, dotted, iconic, quietly morbid, 32k cel shading.png",
      video_settings: {
        video_name: "Graphic Zoom",
        create_preview: false,
        style: {
          number_of_prompts: 1,
          theme_id: "Graphic",
          subject_matter: [
            "extreme close up of an eye",
            "zoom into a succulent plant growing",
            "zoom into a colorful bee",
            "zoom into a retro turntable",
          ],
          subject_matter_time: [],
          orientation: "vertical",
          dreambooth_id: null,
          enable_negative_prompts: true,
          prompt_times: null,
        },
        animation: {
          duration: 56.0,
          fps: 24,
          seed: 0,
          cfg: 14.0,
          strength: 0.6,
          anti_blur: 0.75,
          noise: 0.2,
          contrast: 1.0,
          fov: 70.0,
          steps: 35,
          animation_mode: "2D",
          diffusion_cadence: 3,
          sampler: "default",
          initial_image_key:
            "temp/demo@mymuze.art/Comic book cover of blue eyes with pinks face, in the style of hauntingly beautiful illustrations, realistic hyper — detail, glowwave, bold lithographic, dotted, iconic, quietly morbid, 32k cel shading.png",
          initial_image_bucket: "muze-mvp-dev-dreambooth-images-public",
          initial_image_strength: 1.0,
        },
        build: { reverse_loop: false },
        audio: {
          audio_asset_id: "audio-de8a6bca-8da8-4330-9a9e-64242ff6874b",
          auto_invert: false,
          records: [
            {
              stem: "drums",
              camera: "zoom",
              strength_max: 0.8,
              strength_min: 0.0,
              invert: false,
              decay: 0.0,
              fade_duration: 0,
              signal_shape: "pulse",
              shape_param: 0.5,
              onset_time: 0.0,
              end_time: 56.0,
            },
            {
              stem: "vocals",
              camera: "angle",
              strength_max: 0.5,
              strength_min: 0.0,
              invert: false,
              decay: 100.0,
              fade_duration: 0,
              signal_shape: "pulse",
              shape_param: 0.5,
              onset_time: 0.0,
              end_time: 56.0,
            },
          ],
          strength_adjust_for_motion: true,
          motion_compensation_method: "maui",
          start_time: 0.0,
          stop_time: 56.0,
          name: "Peter Spacey - Vuala.mp3",
        },
        audio_reactive_config: {
          enable_constant_motion_segments: true,
          enable_zoom_pulse_for_drums: true,
          enable_vocal_shake: true,
          constant_motion_strength: 0.7,
          min_segment_duration: 6.0,
          enable_transitions_on_segment_boundaries: false,
          sync_prompt_changes_to_segment_boundaries: true,
        },
        transitions: [
          {
            type: "ConstantTranslationDown",
            offset_start: 0.0,
            duration: 30.0,
            strength: 0.7,
            transition_duration: 6.0,
            transition_duration_out: 6.0,
          },
          {
            type: "ConstantTranslationDown",
            offset_start: 30.0,
            duration: 6.32,
            strength: 0.7,
            transition_duration: 1.264,
            transition_duration_out: 1.264,
          },
          {
            type: "ConstantTranslationLeft",
            offset_start: 36.32,
            duration: 12.64,
            strength: 0.7,
            transition_duration: 2.528,
            transition_duration_out: 2.528,
          },
          {
            type: "ConstantTranslationLeft",
            offset_start: 48.96,
            duration: 6.32,
            strength: 0.7,
            transition_duration: 1.264,
            transition_duration_out: 1.264,
          },
          {
            type: "ConstantTranslationRight",
            offset_start: 0.0,
            duration: 30.0,
            strength: 0.7,
            transition_duration: 6.0,
            transition_duration_out: 6.0,
          },
          {
            type: "ConstantTranslationRight",
            offset_start: 55.28,
            duration: 23.19999999999999,
            strength: 0.7,
            transition_duration: 4.639999999999998,
            transition_duration_out: 4.639999999999998,
          },
          {
            type: "ConstantTranslationUp",
            offset_start: 42.34,
            duration: 6.619999999999997,
            strength: 0.7,
            transition_duration: 1.3239999999999994,
            transition_duration_out: 1.3239999999999994,
          },
          {
            type: "ConstantZoomIn",
            offset_start: 30.0,
            duration: 6.32,
            strength: 0.7,
            transition_duration: 1.264,
            transition_duration_out: 1.264,
          },
          {
            type: "ConstantZoomOut",
            offset_start: 36.32,
            duration: 6.020000000000003,
            strength: 0.7,
            transition_duration: 1.2040000000000006,
            transition_duration_out: 1.2040000000000006,
          },
          {
            type: "ConstantZoomOut",
            offset_start: 48.96,
            duration: 6.32,
            strength: 0.7,
            transition_duration: 1.264,
            transition_duration_out: 1.264,
          },
          {
            type: "ConstantZoomOut",
            offset_start: 55.28,
            duration: 14.239999999999995,
            strength: 0.7,
            transition_duration: 2.847999999999999,
            transition_duration_out: 2.847999999999999,
          },
        ],
        strength_prompt_compensation: {
          enabled: true,
          duration: 0.5,
          strength: 0.15,
        },
        misc_config: {},
        temp_audio: { name: "", key: "", delete_source: false },
      },
    },

    {
      video_id: "video-e61f0901-4955-492b-82d9-0925839bcd36",
      audio_url: `${BASE_URL_AUDIO}files/audio-8de7dd95-12da-4e14-bd28-f55e20ef256f.mp3`,
      video_url: `${BASE_URL_VIDEO}demo@mymuze.art/video-e61f0901-4955-492b-82d9-0925839bcd36.mp4`,
      image:
        "https://muze-mvp-dev-thumbnails-public.s3.amazonaws.com/thumbnails/thumbnail-3397ed75-f405-4e9a-9921-2f8dc24c3435.png",
      video_settings: {
        video_name: "Gritty",
        create_preview: false,
        style: {
          number_of_prompts: 1,
          theme_id: "Gritty",
          subject_matter: [
            "crouching demon ninja in a dynamic pose",
            "extreme close up of a demon smiling wickedly",
            "crowd of people in a graffiti covered room",
            "female punk rock singer with tattoos and pink hair ",
          ],
          subject_matter_time: [],
          orientation: "vertical",
          dreambooth_id: null,
          enable_negative_prompts: true,
          prompt_times: null,
        },
        animation: {
          duration: 58.0,
          fps: 24,
          seed: 0,
          cfg: 14.0,
          strength: 0.6,
          anti_blur: 0.75,
          noise: 0.2,
          contrast: 1.0,
          fov: 70.0,
          steps: 35,
          animation_mode: "2D",
          diffusion_cadence: 3,
          sampler: "default",
          initial_image_key: "",
          initial_image_bucket: "",
          initial_image_strength: 0.7,
        },
        build: { reverse_loop: false },
        audio: {
          audio_asset_id: "audio-8de7dd95-12da-4e14-bd28-f55e20ef256f",
          auto_invert: false,
          records: [
            {
              stem: "drums",
              camera: "zoom",
              strength_max: 0.8,
              strength_min: 0.0,
              invert: false,
              decay: 0.0,
              fade_duration: 0,
              signal_shape: "pulse",
              shape_param: 0.5,
              onset_time: 0.0,
              end_time: 58.0,
            },
            {
              stem: "vocals",
              camera: "angle",
              strength_max: 0.5,
              strength_min: 0.0,
              invert: false,
              decay: 100.0,
              fade_duration: 0,
              signal_shape: "pulse",
              shape_param: 0.5,
              onset_time: 0.0,
              end_time: 58.0,
            },
          ],
          strength_adjust_for_motion: true,
          motion_compensation_method: "maui",
          start_time: 58.0,
          stop_time: 116.0,
          name: "Peter Spacey - 4x20 feat Floating Anarchy.mp3",
        },
        audio_reactive_config: {
          enable_constant_motion_segments: true,
          enable_zoom_pulse_for_drums: true,
          enable_vocal_shake: true,
          constant_motion_strength: 0.9,
          min_segment_duration: 6.0,
          enable_transitions_on_segment_boundaries: false,
          sync_prompt_changes_to_segment_boundaries: true,
        },
        transitions: [
          {
            type: "ConstantTranslationDown",
            offset_start: 14.0,
            duration: 6.0,
            strength: 0.9,
            transition_duration: 1.2,
            transition_duration_out: 1.2,
          },
          {
            type: "ConstantTranslationRight",
            offset_start: 0.0,
            duration: 7.0,
            strength: 0.9,
            transition_duration: 1.4,
            transition_duration_out: 1.4,
          },
          {
            type: "ConstantTranslationRight",
            offset_start: 7.0,
            duration: 7.0,
            strength: 0.9,
            transition_duration: 1.4,
            transition_duration_out: 1.4,
          },
          {
            type: "ConstantTranslationRight",
            offset_start: 20.0,
            duration: 11.0,
            strength: 0.9,
            transition_duration: 2.2,
            transition_duration_out: 2.2,
          },
          {
            type: "ConstantTranslationRight",
            offset_start: 39.0,
            duration: 18.0,
            strength: 0.9,
            transition_duration: 3.6,
            transition_duration_out: 3.6,
          },
          {
            type: "ConstantTranslationRight",
            offset_start: 57.0,
            duration: 10.0,
            strength: 0.9,
            transition_duration: 2.0,
            transition_duration_out: 2.0,
          },
          {
            type: "ConstantTranslationUp",
            offset_start: 31.0,
            duration: 8.0,
            strength: 0.9,
            transition_duration: 1.6,
            transition_duration_out: 1.6,
          },
          {
            type: "ConstantTranslationUp",
            offset_start: 39.0,
            duration: 18.0,
            strength: 0.9,
            transition_duration: 3.6,
            transition_duration_out: 3.6,
          },
          {
            type: "ConstantZoomIn",
            offset_start: 0.0,
            duration: 7.0,
            strength: 0.9,
            transition_duration: 1.4,
            transition_duration_out: 1.4,
          },
          {
            type: "ConstantZoomIn",
            offset_start: 14.0,
            duration: 6.0,
            strength: 0.9,
            transition_duration: 1.2,
            transition_duration_out: 1.2,
          },
          {
            type: "ConstantZoomIn",
            offset_start: 57.0,
            duration: 10.0,
            strength: 0.9,
            transition_duration: 2.0,
            transition_duration_out: 2.0,
          },
          {
            type: "ConstantZoomOut",
            offset_start: 7.0,
            duration: 7.0,
            strength: 0.9,
            transition_duration: 1.4,
            transition_duration_out: 1.4,
          },
          {
            type: "ConstantZoomOut",
            offset_start: 20.0,
            duration: 11.0,
            strength: 0.9,
            transition_duration: 2.2,
            transition_duration_out: 2.2,
          },
          {
            type: "ConstantZoomOut",
            offset_start: 31.0,
            duration: 8.0,
            strength: 0.9,
            transition_duration: 1.6,
            transition_duration_out: 1.6,
          },
        ],
        strength_prompt_compensation: {
          enabled: true,
          duration: 0.5,
          strength: 0.15,
        },
        misc_config: {},
        temp_audio: { name: "", key: "", delete_source: false },
      },
    },
  ] as Array<any>,
};
