import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from ".";
import { selectShouldUpdate, setShouldUpdate } from "./listImagesSliceReducer";
import {
  Avatar,
  resetThemeAndAvatar,
  selectThemeId,
  selectThemeIndex,
  selectUserAvatar,
  setThemeId,
  setThemeIndex,
  setUserAvatar,
} from "./artStyleReducer";
import {
  resetSelectedAudio,
  selectAudioAsset,
  selectAudioEnd,
  selectAudioStart,
  setAudioEnd,
  setAudioStart,
  setDuration,
  setIsFromLib,
  setSelected,
} from "./audioAssetReducer";
import {
  resetVideoNameAndInitImage,
  selectInitialImageKey,
  selectSubjectMatter,
  selectVideoName,
  setInitialImageKey,
  setSubjectMatter,
  setVideoName,
} from "./startingFrameReducer";
import {
  selectCurrentVideo,
  selectTransitions,
  setTransitions,
  setCurrentVideo,
  resetVideoConfig,
  setStrengthPromptCompensation,
  selectAdvancedSettings,
  setAdvancedSettings,
  AdvSettings,
  selectAspectRatio,
  setAspectRatioState,
  selectAnimationMode,
  setAnimationModeState,
  selectBoomerang,
  setBoomerangState,
  selectMotionStrength,
  selectZoomPulse,
  selectVocalShake,
  selectAutomaticTime,
  selectNegativePrompts,
  setMotionStrengthState,
  setNegativePromptsState,
  setVocalShakeState,
  setAutomaticTimeState,
  setZoomPulseState,
  resetAdvancedSettings,
  selectStrengthPromptCompensation,
  setTempAudio,
  selectTempAudio,
  setNoAudio,
  selectNoAudio,
  selectSpotify,
  setSpotify,
} from "./generateVideoReducer";
import { resetSubject, selectSubjects, setSubject } from "./promptsReducer";
import { AudioAsset } from "../API";
import {
  addMedia,
  removeMedia,
  reset,
  resetMedia,
  selectCategory,
  selectMedia,
  selectName,
  setCategory,
  setMedia,
  setName,
  toogleMedia,
} from "./createAvatarReducer";
import {
  setinProgress,
  selectInProgress,
  selectUserLimit,
  setUserLimit,
} from "./parallelGenerationReducer";

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export function useAudioAsset() {
  const audioAsset = useAppSelector(selectAudioAsset);
  const isFromLibrary = audioAsset.isFromLibrary;
  const selectedMusic = audioAsset.selected;
  const d = useAppDispatch();
  const setIsFromLibrary = () => d(setIsFromLib());
  const setMusic = (music: any) => d(setSelected(music));
  return { isFromLibrary, setIsFromLibrary, selectedMusic, setMusic };
}

export const useListUserAssets = () => {
  const dispatch = useAppDispatch();
  const shouldUpdate = useAppSelector(selectShouldUpdate);
  const setShouldList = (payload: boolean) =>
    dispatch(setShouldUpdate(payload));
  return { shouldUpdate, setShouldList };
};

export const useArtStyle = () => {
  const dispatch = useAppDispatch();
  const themeId = useAppSelector(selectThemeId);
  const setUserThemeId = (payload: string) => dispatch(setThemeId(payload));
  const themeIndex = useAppSelector(selectThemeIndex);
  const setUserThemeIndex = (payload: number) =>
    dispatch(setThemeIndex(payload));
  return {
    themeId,
    setUserThemeId,
    themeIndex,
    setUserThemeIndex,
  };
};

export const useAvatar = () => {
  const dispatch = useAppDispatch();
  const setAvatar = (payload: any) => dispatch(setUserAvatar(payload));
  const avatar = useAppSelector(selectUserAvatar);
  return { avatar, setAvatar };
};

export const useStartingFrame = () => {
  const dispatch = useAppDispatch();
  const subjectMatter = useAppSelector(selectSubjectMatter);
  const setUserSubjectMatter = (payload: string) =>
    dispatch(setSubjectMatter(payload));
  const initialImageKey = useAppSelector(selectInitialImageKey);
  const setUserInitialImageKey = (payload: string) =>
    dispatch(setInitialImageKey(payload));
  const videoName = useAppSelector(selectVideoName);
  const setUserVideoName = (payload: string) => dispatch(setVideoName(payload));
  return {
    videoName,
    setUserVideoName,
    initialImageKey,
    setUserInitialImageKey,
    subjectMatter,
    setUserSubjectMatter,
  };
};

export const useTransitions = () => {
  const dispatch = useAppDispatch();
  const transit = useAppSelector(selectTransitions);
  const setTransit = (payload: any) => dispatch(setTransitions(payload));
  return { transit, setTransit };
};

export const useStrengthPromptCompensation = () => {
  const dispatch = useAppDispatch();
  const strengthPromptCompensation = useAppSelector(
    selectStrengthPromptCompensation
  );
  const setStrengthPrompt = (payload: any) =>
    dispatch(setStrengthPromptCompensation(payload));
  return { strengthPromptCompensation, setStrengthPrompt };
};

export const useSubjectMatter = () => {
  const dispatch = useAppDispatch();
  const subject = useAppSelector(selectSubjects);
  const setSubjectMatter = (payload: any) => dispatch(setSubject(payload));
  return { subject, setSubjectMatter };
};

export const useNegativePrompts = () => {
  const enableNegativePrompts = useAppSelector(selectNegativePrompts);
  const dispatch = useAppDispatch();
  const setNegativePrompts = (payload: boolean) =>
    dispatch(setNegativePromptsState(payload));
  return { enableNegativePrompts, setNegativePrompts };
};

export const useVideoManager = () => {
  const dispatch = useAppDispatch();
  const currentVideo = useAppSelector(selectCurrentVideo);
  const dispatchCurrentVideo = (payload: any) =>
    dispatch(setCurrentVideo(payload));
  return { currentVideo, dispatchCurrentVideo };
};

export const useTempAudio = () => {
  const dispatch = useAppDispatch();
  const tempAudioParams = useAppSelector(selectTempAudio);
  const selectedRef = tempAudioParams.name;
  const setTemp = (payload: any) => dispatch(setTempAudio(payload));
  return { tempAudioParams, selectedRef, setTemp };
};

export const useNoAudio = () => {
  const no_audio = useAppSelector(selectNoAudio);
  const dispatch = useAppDispatch();
  const setWithoutAudio = (payload: boolean) => dispatch(setNoAudio(payload));
  return { no_audio, setWithoutAudio };
};

export const useSpotify = () => {
  const spotify = useAppSelector(selectSpotify);
  const dispatch = useAppDispatch();
  const setIsSpotify = (payload: boolean) => dispatch(setSpotify(payload));
  return { spotify, setIsSpotify };
};

export const useAdvancedSettings = () => {
  const dispatch = useAppDispatch();
  const as = useAppSelector(selectAdvancedSettings);
  const setAdvSettings = (payload: AdvSettings) =>
    dispatch(setAdvancedSettings(payload));
  const resetAdvSettings = () => dispatch(resetAdvancedSettings());
  return { ...as, setAdvSettings, resetAdvSettings };
};

export const useClearConfigs = () => {
  const dispatch = useAppDispatch();
  const clear = () => {
    dispatch(resetSubject()); // prompts
    dispatch(resetVideoConfig()); // advanced settings + transitions + strenghtPromptCompensation
    dispatch(resetSelectedAudio()); // selected music
    dispatch(resetVideoNameAndInitImage()); // initial image + initial image prompt + video name
    dispatch(resetThemeAndAvatar()); // style + avatar
    dispatch(setAutomaticTimeState(true));
  };
  return { clear };
};

export const useCreateAvatar = () => {
  const dispatch = useAppDispatch();
  const createAvatarMedia = useAppSelector(selectMedia);
  const createAvatarName = useAppSelector(selectName);
  const createAvatarCategory = useAppSelector(selectCategory);

  const setCreateAvatarName = (payload: string) => dispatch(setName(payload));
  const setCreateAvatarCategory = (payload: string) =>
    dispatch(setCategory(payload));

  const resetCreateAvatarMedia = () => dispatch(resetMedia());
  const addCreateAvatarMedia = (payload: string) => dispatch(addMedia(payload));
  const setCreateAvatarMedia = (payload: string[]) =>
    dispatch(setMedia(payload));
  const removeCreateAvatarMedia = (payload: string) =>
    dispatch(removeMedia(payload));
  const toogleCreateAvatarMedia = (payload: string) =>
    dispatch(toogleMedia(payload));

  const resetCreateAvatar = () => dispatch(reset());

  return {
    createAvatarMedia,
    createAvatarName,
    createAvatarCategory,
    setCreateAvatarName,
    setCreateAvatarCategory,
    setCreateAvatarMedia,
    addCreateAvatarMedia,
    removeCreateAvatarMedia,
    resetCreateAvatarMedia,
    toogleCreateAvatarMedia,
    resetCreateAvatar,
  };
};

export const useAspectRatioStore = () => {
  const aspectRatio = useAppSelector(selectAspectRatio);
  const dispatch = useAppDispatch();
  const setAspectRatio = (payload: string) =>
    dispatch(setAspectRatioState(payload));
  return { aspectRatio, setAspectRatio };
};

export const useAnimationModeStore = () => {
  const animationMode = useAppSelector(selectAnimationMode);
  const dispatch = useAppDispatch();
  const setAnimationMode = (payload: string) =>
    dispatch(setAnimationModeState(payload));
  return { animationMode, setAnimationMode };
};

export const useBoomerangStore = () => {
  const boomerang = useAppSelector(selectBoomerang);
  const dispatch = useAppDispatch();
  const setBoomerang = (payload: boolean) =>
    dispatch(setBoomerangState(payload));
  return { boomerang, setBoomerang };
};

export const useMotionStrengthStore = () => {
  const motionStrength = useAppSelector(selectMotionStrength);
  const dispatch = useAppDispatch();
  const setMotionStrength = (payload: number) =>
    dispatch(setMotionStrengthState(payload));
  return { motionStrength, setMotionStrength };
};
export const useZoomPulseStore = () => {
  const zoomPulse = useAppSelector(selectZoomPulse);
  const dispatch = useAppDispatch();
  const setZoomPulse = (payload: boolean) =>
    dispatch(setZoomPulseState(payload));
  return { zoomPulse, setZoomPulse };
};

export const useVocalShakeStore = () => {
  const vocalShake = useAppSelector(selectVocalShake);
  const dispatch = useAppDispatch();
  const setVocalShake = (payload: boolean) =>
    dispatch(setVocalShakeState(payload));
  return { vocalShake, setVocalShake };
};

export const useAutomaticTimeStore = () => {
  const automaticTime = useAppSelector(selectAutomaticTime);
  const dispatch = useAppDispatch();
  const setAutomaticTime = (payload: boolean) =>
    dispatch(setAutomaticTimeState(payload));
  return { automaticTime, setAutomaticTime };
};

export const useAudioTrim = () => {
  const start = useAppSelector(selectAudioStart);
  const end = useAppSelector(selectAudioEnd);
  const delta = { start, end };
  const dispatch = useAppDispatch();
  const setAudioDuration = (payload: { start: number; end: number }) =>
    dispatch(setDuration(payload));
  const setStart = (payload: number) => dispatch(setAudioStart(payload));
  const setEnd = (payload: number) => dispatch(setAudioEnd(payload));
  return { setStart, setEnd, setAudioDuration, start, end, delta };
};

export const useGenerationLimits = () => {
  const inProgress = useAppSelector(selectInProgress);
  const userLimit = useAppSelector(selectUserLimit);
  const dispatch = useAppDispatch();
  const setinProgressCount = (payload: number) =>
    dispatch(setinProgress(payload));
  const setGenerationLimit = (payload: number) =>
    dispatch(setUserLimit(payload));
  return { inProgress, setinProgressCount, userLimit, setGenerationLimit };
};
