import { useEffect } from "react";
import { useAuth } from "../../AuthProvider";
import Upload from "../CreateAvatar/Upload";
import Uploaded from "../CreateAvatar/Uploaded";
import MySideBody from "../CreateAvatar/SideBody";
import "./styles.css";
import useAvatarLoading from "../AvatarSelect/useAvatarLoading";

export default function CreateAvatarPage() {
  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  return (
    <>
      {isMobileDevice() ? (
        <div className="m-0 p-1 vh-100 d-flex flex-grow-1 overflow-hidden">
          <div
            className="rounded d-flex flex-grow-1"
            style={{ background: "#232627" }}
          >
            <div className="d-flex flex-column">
              <MainHeader />
              <div className="p-1 d-flex flex-column flex-grow-1 overflow-hidden">
                <MySideBody />
                <div className="d-flex flex-column flex-grow-1 border rounded overflow-hidden">
                  <AvatarProgress />
                  <ContentBody />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="m-0 p-3 vh-100 d-flex flex-grow-1 overflow-hidden">
          <div
            className="rounded d-flex flex-grow-1"
            style={{ background: "#232627" }}
          >
            <div className="d-flex flex-column border-end flex-grow-1">
              <MainHeader />
              <div className="p-3 d-flex flex-column flex-grow-1 overflow-hidden">
                <div className="d-flex flex-column flex-grow-1 border rounded overflow-hidden">
                  <ContentHeader />
                  <ContentBody />
                  <AvatarProgress />
                </div>
              </div>
            </div>
            <div className="d-flex flex-column">
              <MySideHeader />
              <div className="flex-grow-1 d-flex flex-column justify-content-start align-items-center overflow-hidden">
                <div className="d-flex flex-column flex-grow-1">
                  <MySideBody />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

function AvatarProgress() {
  const { avatarIncompleted, refetch, step, stepName } = useAvatarLoading();
  const label = `${stepName}: ${step}%`;

  useEffect(() => {
    const timer = setInterval(() => {
      refetch();
    }, 5000);

    return () => clearTimeout(timer);
  });

  if (avatarIncompleted.length < 1) {
    return <></>;
  }
  return <MyProgressBar className="mt-auto border m-3 " {...{ label, step }} />;
}

export function MyProgressBar(props: any) {
  const { className, step, label } = props;
  const s = +step > -1 && +step < 101 ? step : 0;

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  return (
    <>
      {isMobileDevice() ? (
        <div
          {...{ className }}
          style={{
            backgroundColor: "#fff",
            position: "relative",
            borderRadius: "10px",
            border: "None",
          }}
        >
          <div
            style={{
              position: "relative",
              color: "#000",
              zIndex: "1",
              width: "100%",
              textAlign: "center",
            }}
          >
            {label || " "}
          </div>
          <div
            style={{
              width: `${s}%`,
              maxWidth: "100%",
              backgroundColor: "#FF30C4",
              color: "#000",
              textAlign: "center",
              whiteSpace: "nowrap",
              minHeight: "1.5rem",
              position: "absolute",
              left: 0,
              top: 0,
              borderRadius: "10px",
              border: "None",
            }}
          ></div>
        </div>
      ) : (
        <div
          {...{ className }}
          style={{
            backgroundColor: "#fff",
            position: "relative",
          }}
        >
          <div
            style={{
              position: "relative",
              color: "#000",
              zIndex: "1",
              width: "100%",
              textAlign: "center",
            }}
          >
            {label || " "}
          </div>
          <div
            style={{
              width: `${s}%`,
              backgroundColor: "#FF30C4",
              color: "#000",
              textAlign: "center",
              whiteSpace: "nowrap",
              minHeight: "1.5rem",
              position: "absolute",
              left: 0,
              top: 0,
            }}
          ></div>
        </div>
      )}
    </>
  );
}

function MainHeader() {
  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  return (
    <>
      {isMobileDevice() ? (
        <div
          className="d-flex px-3 border-bottom justify-content-between align-items-center"
          style={{ minHeight: "3.5rem" }}
        >
          <div className="d-flex flex-column">
            <h3 className="ps d-flex flex-column align-items-start justify-content-center text-white">
              Create Avatar
            </h3>
            <p className="m-0 text-muted" style={{ fontSize: "0.75rem" }}>
              Upload between 10-15 images of the same person
            </p>
          </div>
          <Upload />
        </div>
      ) : (
        <div
          className="d-flex px-3 border-bottom justify-content-between align-items-center"
          style={{ minHeight: "4rem" }}
        >
          <h3 className="ps d-flex flex-column align-items-start justify-content-center text-white">
            Create Avatar
          </h3>
        </div>
      )}
    </>
  );
}

const ContentHeader = () => (
  <>
    <div className="d-flex p-3">
      <div className="py-2">
        <h5>Upload between 10-15 images of the same person</h5>
        <p className="text-muted m-0 p-0">
          Make sure you have the rights to use the suggest media.
        </p>
      </div>
      <div className="ms-auto text-center justify-content-center">
        <i className="bi bi-x-lg fs-5" />
      </div>
    </div>
  </>
);

const ContentHeaderMobile = () => (
  <>
    <div className="d-flex p-0 justify-content-center align-imtens-center">
      <div className="py-2 justify-content-center align-items-center">
        <p className="text-center">
          Upload between 10-15 images of the same person
        </p>
        <p className="text-muted m-0 p-0">
          Make sure you have the rights to use the suggest media.
        </p>
      </div>
      <div className="ms-auto text-center justify-content-center">
        <i className="bi bi-x-lg fs-5" />
      </div>
    </div>
  </>
);

function ContentBody() {
  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  return (
    <>
      {isMobileDevice() ? (
        <div className="d-flex flex-grow-1 overflow-hidden">
          <div className="d-flex px-2 flex-wrap overflow-auto w-100">
            <Uploaded />
          </div>
        </div>
      ) : (
        <div className="d-flex overflow-hidden">
          <div className="d-flex px-2 flex-wrap overflow-auto">
            <Uploaded />
          </div>
        </div>
      )}
    </>
  );
}

function MySideHeader() {
  const { currentUser } = useAuth();
  return (
    <div
      className="d-flex border-bottom align-items-center justify-content-end gap-3 px-2"
      style={{ minHeight: "4rem" }}
    >
      {/* <img
        style={{ width: "2.5rem", height: "2.5rem", borderRadius: "2.5rem" }}
        src={`${currentUser?.photoURL}`}
        alt="user"
      /> */}
    </div>
  );
}
