export const pallete = {
  primary: "#FF30C4",
  secondary: "#3FDD78",
  bright: "#FF30C4",
  light: "#E8ECEF",
  dark: "#232627",
  darker: "#141718",
  accents01: "#D84C10",
  accents02: "#3E90F0",
  accents03: "#8E55EA",
  accents04: "#8C6584",
  accents05: "#DDA73F",
  primary02: "#3FDD78",
  neutral03: "#E8ECEFBF",
};

export const capitalize = (s: string) =>
// put capital letters on every word first letter on a phrase
// safe for 1 word phrase or " " or ""
  s
    .split(" ")
    .map((i) => (i.length > 0 ? i[0].toUpperCase() + i.slice(1) : ""))
    .join(" ");
