import { Stack } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import Navigator from "./Navigator";
import "./styles.css";

export default function Settings() {
  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  const direction = isMobileDevice() ? "vertical" : "horizontal";
  const className = isMobileDevice()
    ? "border rounded m-1 p-1 gap-2"
    : "border rounded m-3 p-3 gap-3 flex-grow-1";

  return (
    <Stack direction={direction} className={className}>
      <Navigator />
      <Outlet />
    </Stack>
  );
}
