import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./PopUp.css";

export default function PopUp(props: any) {
  const { shouldShow, title, text, confirmText, onConfirm, onClose } = props;

  return (
    <Modal
      show={shouldShow}
      onHide={onClose}
      dialogClassName="custom-modal-width"
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{text}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        <Button variant="primary" onClick={onConfirm}>
          {confirmText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
