import { Button, Form, Stack } from 'react-bootstrap';
import { useState } from 'react';
import { APIClient } from '../../../utils/services';

const API_KEY = process.env.REACT_APP_MUZE_API_KEY;

export default function UserLimits() {
  const [ownerId, setOwnerId] = useState('');
  const [data, setData] = useState({});
  const onChange = (e: any) => {
    setOwnerId(e.target.value);
  };
  const onSubmit = (e: any) => {
    e.preventDefault();
    if (ownerId !== '') {
      const client = new APIClient();
      client.authenticate(undefined, undefined, API_KEY);
      client.get_user_limits(ownerId, setData, console.error);
    }
  };
  return (
    <Stack className="mt-3 gap-3 justify-content-end">
      <Stack children={`${Object.entries(data)}`} />
      <Form {...{ onSubmit }}>
        <Form.Group className="mb-3">
          <Form.Label children={'Owner Id:'} />
          <Form.Control type="text" {...{ onChange }} />
        </Form.Group>
        <Button type="submit" className="col-12" children={'Refresh'} />
      </Form>
    </Stack>
  );
}
