import { Stack } from "react-bootstrap";
import useTemplate from "./useTemplate";
import Modal from "react-bootstrap/Modal";
import { useEffect, useState } from "react";
import "./styles.css";
import ReactPlayer from "react-player";
// @ts-ignore
import "react-mirt/dist/css/react-mirt.css";
import { MAX_AUDIO_LENGTH } from "../../store/audioAssetReducer";

const TITLE = "Templates";
const DESCRIPTION = `After choosing a template you can add your song and edit the default settings`;
const MAX = MAX_AUDIO_LENGTH;

export default function TemplatePage() {
  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  return (
    <>
      {isMobileDevice() ? (
        <Stack
          className="rounded m-1 p-1 align-items-center"
          style={{ backgroundColor: "#141718" }}
        >
          <h1>{TITLE}</h1>
          <div className="text-center">{DESCRIPTION}</div>
          <Stack
            direction="horizontal"
            className="flex-grow-1 mt-3 overflow-hidden"
          >
            <Stack
              className="border-end overflow-auto col-9"
              style={{ backgroundColor: "#141718" }}
            >
              {/* MAIN CONTENT */}
              <Templates />
            </Stack>
            {/* <Stack className="justify-content-end ps-3">
              <NextPrev hidePrev fill />
            </Stack> */}
          </Stack>
        </Stack>
      ) : (
        <Stack
          className="rounded m-3 p-3 align-items-center"
          style={{ backgroundColor: "#232627" }}
        >
          <h1>{TITLE}</h1>
          <div className="text-center">{DESCRIPTION}</div>
          <Stack
            direction="horizontal"
            className="flex-grow-1 mt-3 overflow-hidden"
          >
            <Stack className="border-end overflow-auto col-9">
              {/* MAIN CONTENT */}
              <Templates />
            </Stack>
            {/* <Stack className="justify-content-end ps-3">
              <NextPrev hidePrev fill />
            </Stack> */}
          </Stack>
        </Stack>
      )}
    </>
  );
}

function ModalTemplate(props: any) {
  const {
    show,
    onHide,
    formattedDuration,
    image,
    orientationIcon,
    name,
    copyVideoTemplate,
    videoId,
    description,
  } = props;

  const [controlsVisible, setControlsVisible] = useState(false);

  const handleMouseEnter = () => {
    setControlsVisible(true);
  };

  const handleMouseLeave = () => {
    setControlsVisible(false);
  };

  return (
    <Modal {...props} show={show} onHide={onHide} centered>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="d-flex flex-column flex-grow-1 align-items-center justify-content-center overflow-hidden">
              <div
                className="d-flex"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                style={{ position: "relative", width: "100%", height: "100%" }}
              >
                <ReactPlayer
                  url={image}
                  width="100%"
                  height="100%"
                  style={{ objectFit: "cover" }}
                  controls={controlsVisible}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12">
            {
              <div className="modal-title">
                <div>
                  <h4>{name}</h4>
                </div>
                <div className="modal-title">
                  <i
                    className={orientationIcon}
                    style={{ fontSize: "2rem", marginRight: "6px" }}
                  />

                  <i
                    className="bi bi-clock"
                    style={{ fontSize: "1.0rem", marginRight: "4px" }}
                  />
                  <span style={{ fontSize: "1.0rem" }}>
                    {formattedDuration}
                  </span>
                </div>
              </div>
            }
            <div className="p-1 mt-4">
              {description.map((subject: any, index: number) => (
                <p key={index}>{capitalizeFirstLetter(subject)} </p>
              ))}
            </div>
            <div className="mt-1">
              <button
                className="button-template"
                onClick={() => copyVideoTemplate(videoId)}
              >
                select
                <i
                  className="bi bi-hand-index-thumb"
                  style={{ fontSize: "1rem", marginLeft: "5px" }}
                />
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

function Time(props: any) {
  const { delta } = props;
  const t = formatSecondsAsTime(delta.start || 0);
  const tt = formatSecondsAsTime(delta.end || 0);
  return (
    <p className="small text-start text-muted p-0 m-0 mt-1 d-flex justify-content-between">
      <span>{t}</span>
      <span>{tt}</span>
    </p>
  );
}

function formatSecondsAsTime(secs: number) {
  let hr = Math.floor(secs / 3600);
  let min: any = Math.floor((secs - hr * 3600) / 60);
  let sec: any = Math.floor(secs - hr * 3600 - min * 60);

  if (min < 10) {
    min = "0" + min;
  }
  if (sec < 10) {
    sec = "0" + sec;
  }
  return min + ":" + sec;
}

function Templates() {
  const { templates, copyVideoTemplate } = useTemplate();
  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  return (
    <Stack direction="horizontal" className="flex-wrap gap-3 pe-3">
      {templates.map((template: any, index: number, array: any[]) =>
        isMobileDevice() ? (
          <TemplateCardMobile
            key={index}
            image={template.video_url}
            audio={template.audio_url}
            last={index === array.length - 1}
            duration={template.video_settings.animation.duration}
            orientation={template.video_settings.style.orientation}
            name={template.video_settings.video_name}
            videoId={template.video_id}
            description={[template.video_settings.style.subject_matter]}
            copyVideoTemplate={copyVideoTemplate}
          />
        ) : (
          <TemplateCard
            key={index}
            image={template.video_url}
            audio={template.audio_url}
            last={index === array.length - 1}
            duration={template.video_settings.animation.duration}
            orientation={template.video_settings.style.orientation}
            name={template.video_settings.video_name}
            videoId={template.video_id}
            description={[template.video_settings.style.subject_matter]}
            copyVideoTemplate={copyVideoTemplate}
          />
        )
      )}
    </Stack>
  );
}

const formatDuration = (seconds: any) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  const formattedSeconds = remainingSeconds.toFixed(2);

  if (minutes > 0) {
    const formattedMinutes = minutes.toString();
    const [intPart, decimalPart] = formattedSeconds.split(".");
    const paddedSeconds = decimalPart ? decimalPart.padEnd(2, "0") : "00";
    return `${formattedMinutes}:${paddedSeconds}`;
  } else {
    const [intPart, decimalPart] = formattedSeconds.split(".");
    const paddedSeconds = decimalPart ? decimalPart.padEnd(2, "0") : "00";
    const paddedSecondsIntPart = intPart ? intPart.padEnd(2, "0") : "00";
    return intPart ? `0:${paddedSecondsIntPart}` : `0:${paddedSeconds}`;
  }
};

function capitalizeFirstLetter(string: any) {
  if (typeof string !== "string" || string.length === 0) {
    return string;
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function TemplateCard(props: any) {
  const [modalShow, setModalShow] = useState(false);

  const handleClick = () => {
    setModalShow(true);
  };

  const {
    image,
    audio,
    last,
    duration,
    orientation,
    name,
    copyVideoTemplate,
    videoId,
    description,
  } = props;
  const formattedDuration = formatDuration(duration);
  const formattedOrientation =
    orientation.charAt(0).toUpperCase() + orientation.slice(1);

  let orientationIcon;
  switch (formattedOrientation.toLowerCase()) {
    case "square":
      orientationIcon = "bi bi-square";
      break;
    case "landscape":
      orientationIcon = "bi bi-phone-landscape";
      break;
    case "vertical":
      orientationIcon = "bi bi-vr";
      break;
    default:
      orientationIcon = null;
  }

  return (
    <Stack className={last ? "flex-grow-1" : "flex-grow-0"}>
      <Stack
        style={{ height: "15rem", width: "17rem", position: "relative" }}
        className="border rounded text-center overflow-hidden justify-content-center"
        onClick={handleClick}
      >
        <ReactPlayer
          url={image}
          width="100%"
          height="100%"
          style={{ objectFit: "cover" }}
        />

        <div
          style={{
            position: "absolute",
            top: "0",
            right: "0",
            padding: "8px",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            color: "#fff",
            borderRadius: "0 0 0 8px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <i className="bi bi-clock" style={{ marginRight: "4px" }} />
          {formattedDuration}
        </div>

        <div
          style={{
            position: "absolute",
            bottom: "0",
            left: "0",
            padding: "8px",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            color: "#fff",
            borderRadius: "0 0 8px 0",
            display: "flex",
            alignItems: "center",
          }}
        >
          {orientationIcon && (
            <i className={orientationIcon} style={{ marginRight: "4px" }} />
          )}
          {formattedOrientation}
        </div>
      </Stack>

      <ModalTemplate
        show={modalShow}
        onHide={() => setModalShow(false)}
        image={image}
        formattedDuration={formattedDuration}
        orientationIcon={orientationIcon}
        name={name}
        copyVideoTemplate={copyVideoTemplate}
        videoId={videoId}
        description={description}
        audio={audio}
      />
    </Stack>
  );
}

function TemplateCardMobile(props: any) {
  const [showInfo, setShowInfo] = useState(false);
  const [showVideo, setShowVideo] = useState(false);

  const onClick = () => {
    setShowVideo(true);
  };

  const handleMouseEnter = () => {
    setShowInfo(true);
  };

  const handleMouseLeave = () => {
    setShowInfo(false);
  };

  const {
    image,
    duration,
    orientation,
    name,
    copyVideoTemplate,
    videoId,
    description,
  } = props;
  const formattedDuration = formatDuration(duration);
  const formattedOrientation =
    orientation.charAt(0).toUpperCase() + orientation.slice(1);

  let orientationIcon;
  switch (formattedOrientation.toLowerCase()) {
    case "square":
      orientationIcon = "bi bi-square";
      break;
    case "landscape":
      orientationIcon = "bi bi-phone-landscape";
      break;
    case "vertical":
      orientationIcon = "bi bi-vr";
      break;
    default:
      orientationIcon = null;
  }

  return (
    <Stack
      style={{
        height: "15rem",
        width: "17rem",
        position: "relative",
        backgroundColor: "#141718",
      }}
      className="border rounded text-center overflow-hidden justify-content-center"
      onClick={onClick}
      onTouchStart={handleMouseEnter}
      onTouchEnd={handleMouseLeave}
    >
      <ReactPlayer
        url={image}
        width="100%"
        height="100%"
        style={{ objectFit: "cover" }}
        playing={showVideo}
      />

      <div
        style={{
          position: "absolute",
          top: "0",
          right: "0",
          padding: "8px",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          color: "#fff",
          borderRadius: "0 0 0 8px",
          display: "flex",
          alignItems: "center",
          zIndex: 999, // Set a higher z-index value
        }}
      >
        <i className="bi bi-clock" style={{ marginRight: "4px" }} />
        {formattedDuration}
      </div>
      <div
        style={{
          position: "absolute",
          bottom: "0",
          left: "0",
          right: "0",
          padding: "8px",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          color: "#fff",
          borderRadius: "0 0 8px 0",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          zIndex: 1,
        }}
      >
        {showInfo && (
          <>
            <h3>{name}</h3>
            <p style={{ fontSize: "12px" }}>{description}</p>
          </>
        )}
      </div>

      <div
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          padding: "4px",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          color: "#fff",
          borderRadius: "8px 0 0 0",
          display: "flex",
          alignItems: "center",
          zIndex: 999,
        }}
      >
        <div style={{ flexGrow: 1 }}>
          {orientationIcon && (
            <i className={orientationIcon} style={{ marginRight: "4px" }} />
          )}
          {formattedOrientation}
          <div>
            <button
              className="button-template-mobile"
              onClick={() => copyVideoTemplate(videoId)}
              style={{ zIndex: 1000 }}
            >
              <i
                className="bi bi-hand-index-thumb"
                style={{
                  fontSize: "1.2rem",
                  marginLeft: "5px",
                  color: "#fff",
                  borderColor: "#fff",
                }}
              />
            </button>
          </div>
        </div>
      </div>
    </Stack>
  );
}
