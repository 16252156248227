import { createSlice } from "@reduxjs/toolkit";
import { RootState } from ".";

export type Avatar = {
  name: string;
  id: string;
  date: string;
};

const initialState = {
  themeId: "",
  themeIndex: 0,
  userAvatar: { name: "", id: "", date: "" },
};

const handleThemeSelectionSlice = createSlice({
  name: "handleThemeSelection",
  initialState,
  reducers: {
    setThemeId: (state, action) => ({ ...state, themeId: action.payload }),
    setThemeIndex: (state, action) => ({
      ...state,
      themeIndex: action.payload,
    }),
    setUserAvatar: (state, { payload }: { payload: Avatar }) => ({
      ...state,
      userAvatar: payload,
    }),
    resetThemeAndAvatar: () => initialState,
  },
});

export const { setThemeId, setThemeIndex, setUserAvatar, resetThemeAndAvatar } =
  handleThemeSelectionSlice.actions;

export const selectThemeId = (state: RootState) => state.artStyles.themeId;

export const selectThemeIndex = (state: RootState) =>
  state.artStyles.themeIndex;

export const selectUserAvatar = (state: RootState) =>
  state.artStyles.userAvatar;

export default handleThemeSelectionSlice.reducer;
