import { useBoomerangStore } from "../../../store/hooks";

export default function BoomerangSelector() {
  const { boomerang, setBoomerang } = useBoomerangStore();

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  return (
    <div
      className="d-flex gap-3 align-items-center"
      onClick={() => setBoomerang(!boomerang)}
    >
      {!isMobileDevice() && <span className="ps text-muted">Boomerang:</span>}
      {isMobileDevice() && (
        <p
          className="m-0 p-0"
          style={{ whiteSpace: "nowrap", fontSize: "14px" }}
        >
          Boomerang:
        </p>
      )}
      <input type="checkbox" checked={boomerang} readOnly />
    </div>
  );
}
