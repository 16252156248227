import { useNavigate } from "react-router-dom";
import { useAudioAsset } from "../../../../store/hooks";
import "./styles.css";
import { useRef } from "react";
import useAudioTrim from "../../../AudioTrim/useAudioTrim";

export default function UploadMediaBox() {
  const { isFromLibrary, setIsFromLibrary } = useAudioAsset();
  return (
    <div className="ps d-flex flex-column">
      <Title {...{ isFromLibrary, setIsFromLibrary }} />
      {!isFromLibrary && <UploadMedia />}
    </div>
  );
}

function Title(props: any) {
  const { isFromLibrary, setIsFromLibrary } = props;
  const checked = !isFromLibrary;
  const onClick = () => setIsFromLibrary(!isFromLibrary);
  return (
    <div onClick={onClick}>
      <div className="ps d-flex gap-3">
        <input type="checkbox" {...{ checked }} readOnly />
        <span className="" children={"Upload new audio"} />
      </div>
      <p className="text-muted small">New songs take up to 5 min to process</p>
    </div>
  );
}

export function UploadMedia() {
  const { handleDragOver, handleDropLibrary, file, handleChangeLibrary } =
    useAudioTrim();

  const onClick = () => {
    inputFile.current?.click();
  };

  const navigate = useNavigate();
  const inputFile = useRef<HTMLInputElement | null>(null);

  const title = `Upload your audio`;
  const text = "You can upload audio files.";

  return (
    <div id="upload" onClick={onClick}>
      <div id="upload-1" onDragOver={handleDragOver} onDrop={handleDropLibrary}>
        <input
          type="file"
          style={{ display: "none" }}
          ref={inputFile}
          accept=".mp3, .wav"
          onChange={handleChangeLibrary}
          multiple
        />
        <i id="upload-icon" className="bi bi-upload" />
        <h5 className="text-white">{title}</h5>
        <p className="small text-white text-center">{text}</p>
      </div>
    </div>
  );
}

export function UploadMediaMobile() {
  const { handleDragOver, handleDropLibrary, file, handleChangeLibrary } =
    useAudioTrim();

  const onClick = () => {
    inputFile.current?.click();
  };

  const navigate = useNavigate();
  const inputFile = useRef<HTMLInputElement | null>(null);

  const title = `Upload your audio`;
  const text = "You can upload audio files.";

  return (
    <button
      className="btn btn-primary btn-floating"
      style={{
        backgroundColor: "#ff57d0",
        border: "None",
        borderRadius: "10px",
      }}
      onClick={onClick}
    >
      <input
        type="file"
        style={{ display: "none" }}
        ref={inputFile}
        accept=".mp3, .wav"
        onChange={handleChangeLibrary}
        multiple
      />
      <i className="bi bi-plus-circle-fill"></i>
    </button>
  );
}
