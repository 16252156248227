import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '.';

const initialState = {
  inProgress: 0,
  userLimit: 0,
};

const parallelProcessesSlice = createSlice({
  name: 'parallelProcesses',
  initialState,
  reducers: {
    setinProgress: (state, action) => ({ ...state, inProgress: action.payload }),
    setUserLimit: (state, action) => ({ ...state, userLimit: action.payload }),
  },
});

export const { setinProgress } = parallelProcessesSlice.actions;
export const selectInProgress = (state: RootState) => state.parallelProcesses.inProgress;
export const { setUserLimit } = parallelProcessesSlice.actions;
export const selectUserLimit = (state: RootState) => state.parallelProcesses.userLimit;

export default parallelProcessesSlice.reducer;
