import { createSlice } from "@reduxjs/toolkit";
import { RootState } from ".";

const initialState = {
  subjectMatter: "",
  initialImageKey: "",
  videoName: "",
};

const handleStartingFrameSlice = createSlice({
  name: "handleStartingFrame",
  initialState,
  reducers: {
    setSubjectMatter: (state, action) => ({
      ...state,
      subjectMatter: action.payload,
    }),
    setInitialImageKey: (state, action) => ({
      ...state,
      initialImageKey: action.payload,
    }),
    setVideoName: (state, action) => ({ ...state, videoName: action.payload }),
    resetVideoNameAndInitImage: () => initialState,
  },
});

export const {
  setSubjectMatter,
  setInitialImageKey,
  setVideoName,
  resetVideoNameAndInitImage,
} = handleStartingFrameSlice.actions;

export const selectSubjectMatter = (state: RootState) =>
  state.startingFrame.subjectMatter;
export const selectInitialImageKey = (state: RootState) =>
  state.startingFrame.initialImageKey;
export const selectVideoName = (state: RootState) =>
  state.startingFrame.videoName;

export default handleStartingFrameSlice.reducer;
