export class UpscalingSettings {
  constructor(ownerId: string, videoAssetId: string) {
    this.owner_id = ownerId;
    this.video_asset_id = videoAssetId;
  }

  owner_id: string = "";
  video_asset_id: string = "";
  upscaling_settings: Settings = new Settings();
}

export class Settings {
  model_name: string = "realesr-general-x4v3";
  outscale: number = 2.5;
  suffix: string = "upscaled";
  tile: number = 0;
  tile_pad: number = 10;
  pre_pad: number = 0;
  fp32: boolean = false;
  denoise_strength: number = 0.5;
  ffmpeg_bin: string = "ffmpeg";
  num_workers: number = 14;
}
