import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../AuthProvider";
import {
  UploadMedia,
  UploadMediaMobile,
} from "../GenerateVideo/AudioPage/UploadMedia";
import FileDropInput from "../SharedComponents/DropUploader";
import SearchBox from "../Library/SearchBox";
import FileDropInputVideo from "../SharedComponents/DropUploaderVideo";

export default function AssetsLibrary() {
  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  return isMobileDevice() ? (
    <div className="m-0 p-0 vh-100 d-flex flex-grow-1 overflow-hidden">
      <div
        className="rounded d-flex flex-grow-1"
        style={{ background: "#232627" }}
      >
        <div className="d-flex flex-column border-end flex-grow-1">
          <MainHeader />
          <div className="p-3 d-flex flex-column flex-grow-1 overflow-hidden">
            <div className="d-flex p-1 justify-content-center gap-3">
              <div>
                <Tabs />
              </div>
            </div>
            <div className="p-1 justify-content-center align-items-center">
              <SearchBox />
            </div>
            <div className="d-flex flex-grow-1 mb-1 overflow-auto">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="m-0 p-3 vh-100 d-flex flex-grow-1 overflow-hidden">
      <div
        className="rounded d-flex flex-grow-1"
        style={{ background: "#232627" }}
      >
        <div className="d-flex flex-column border-end flex-grow-1">
          <MainHeader />
          <div className="p-3 d-flex flex-column flex-grow-1 overflow-hidden">
            <div className="d-flex flex-column flex-grow-1 border rounded overflow-hidden">
              <div className="d-flex p-3 justify-content-between gap-3">
                <div>
                  <h4 className="text-white">Your assets</h4>
                  <Tabs />
                </div>
                <SearchBox />
              </div>
              <div className="d-flex flex-grow-1 mb-4 overflow-auto">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column">
          <MySideHeader />
          <div className="flex-grow-1 d-flex flex-column justify-content-start align-items-center overflow-hidden">
            <div className="overflow-auto">
              <MySideBody />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function MainHeader() {
  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  return isMobileDevice() ? (
    <div
      className="d-flex px-3 border-bottom justify-content-between align-items-center"
      style={{ minHeight: "4rem" }}
    >
      <h3 className="ps d-flex flex-column align-items-start justify-content-center text-white">
        Library
      </h3>

      <div className="d-flex align-items-center">
        <MySideBody />
      </div>
    </div>
  ) : (
    <div
      className="d-flex px-3 border-bottom justify-content-between align-items-center"
      style={{ minHeight: "4rem" }}
    >
      <h3 className="ps d-flex flex-column align-items-start justify-content-center text-white">
        Library
      </h3>
      {/* <div className="d-flex gap-3">
        <i className="bi bi-star fs-5" />
        <i className="bi bi-bookmark fs-5" />
        <i className="bi bi-three-dots fs-5" />
      </div> */}
    </div>
  );
}

function Tabs() {
  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  return isMobileDevice() ? (
    <nav className="m-0 p-0 w-100">
      <ul className="d-flex gap-3 m-0 p-0">
        <TabItem {...{ label: "Audios", to: "uploaded-audios" }} />
        <TabItem {...{ label: "Images", to: "uploaded-images" }} />
        <TabItem {...{ label: "Videos", to: "uploaded-videos" }} />
      </ul>
    </nav>
  ) : (
    <nav className="m-0 p-0">
      <ul className="d-flex gap-3 m-0 p-0">
        <TabItem {...{ label: "Audios", to: "uploaded-audios" }} />
        <TabItem {...{ label: "Images", to: "uploaded-images" }} />
        <TabItem {...{ label: "Videos", to: "uploaded-videos" }} />
      </ul>
    </nav>
  );
}

function TabItem(props: any) {
  const { label, to } = props;
  const navegate = useNavigate();
  const onClick = () => navegate(to);
  const location = useLocation();
  const active = location.pathname.split("/").includes(to);
  const children = <p className="small m-0 p-0">{label}</p>;
  const i = { color: active ? "#FF30C4" : "#6C7275" };
  const style = { listStyle: "none", ...i };
  const className = "btn m-0 p-0";
  return <li {...{ style, className, children, onClick }} />;
}

function MySideHeader() {
  const { currentUser } = useAuth();
  return (
    <div
      className="d-flex border-bottom align-items-center justify-content-end gap-3 px-2"
      style={{ minHeight: "4rem" }}
    >
      {/* <i className="bi bi-app-indicator fs-5" /> */}
      {currentUser?.photoURL ? (
        <img
          style={{
            width: "2.5rem",
            height: "2.5rem",
            borderRadius: "2.5rem",
            display: "none",
          }}
          src={`${currentUser?.photoURL}`}
          alt="user"
        />
      ) : (
        <i
          className="bi bi-person-circle"
          style={{
            fontSize: "30px",
            color: "#FFF",
            borderRadius: "80%",
            display: "none", // Aqui ocultamos o ícone
          }}
        />
      )}
    </div>
  );
}

function MySideBody({ onShowModal }: { onShowModal?: () => void }) {
  const { pathname } = useLocation();
  const uploadAudio = pathname.match("audios");
  const uploadVideo = pathname.match("videos");
  const uploadImage = pathname.match("images");

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  return isMobileDevice() ? (
    <div className="text-center p-3">
      {uploadImage && <FileDropInput />}
      {uploadAudio && <UploadMedia />}
      {uploadVideo && <FileDropInputVideo />}
    </div>
  ) : (
    <div className="ps py-3 text-center" style={{ minWidth: "14rem" }}>
      {uploadImage && <FileDropInput />}
      {uploadAudio && <UploadMedia />}
      {uploadVideo && <FileDropInputVideo />}
    </div>
  );
}
