import { Button, Form, Spinner, Container } from "react-bootstrap";
import { useState } from "react";
import axios from "axios";
import { useAuth } from "../../AuthProvider";
import toast from "react-hot-toast";

const BASE_URL = process.env.REACT_APP_MUZE_API_HOST || "http://localhost:8002";

interface MobileCreateStyleProps {
  refetch: () => void;
  setShowCreateStyleModal: (value: boolean) => void;
  setNewStyleCreated: (value: boolean) => void;
}

function MobileCreateStyle({
  refetch,
  setShowCreateStyleModal,
  setNewStyleCreated,
}: MobileCreateStyleProps) {
  const { currentUser } = useAuth();
  const owner_id = currentUser?.email || "";
  const [name, setName] = useState("");
  const [prompt, setPrompt] = useState("");
  const [isPublic, setIsPublic] = useState(false);
  const [loading, setLoading] = useState(false);

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  const togglePublic = () => {
    setIsPublic((prev) => !prev);
  };

  const addStyle = async () => {
    setLoading(true);
    const apiUrl = `${BASE_URL}/upload_custom_style/`;

    try {
      const formattedPrompt = `portrait of {SUBJECT MATTER}:1.3 ${prompt}`;
      const formData = new FormData();
      const cleanName = name.trim();
      formData.append("owner_id", owner_id);
      formData.append("name", cleanName);
      formData.append("prompt", formattedPrompt);
      formData.append("is_public", isPublic.toString());

      const response = await axios.post(apiUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data.is_custom_style) {
        setName("");
        setPrompt("");
        setIsPublic(false);

        setLoading(false);
        toast.success("Style created!", { duration: 10000 });

        refetch();
        setShowCreateStyleModal(false);
        setNewStyleCreated(true);
      } else {
        setLoading(false);
        toast.error("A name with this style already exists.", {
          duration: 10000,
        });
      }
    } catch (error) {
      setLoading(false);
      setShowCreateStyleModal(false);
      console.error("Error adding style:", error);
      toast.error("An error occurred while adding the style.", {
        duration: 10000,
      });
    }
  };

  return (
    <Container className="mt-4">
      <h2>Create new style</h2>
      <Form>
        <Form.Group className="mb-3">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="example: Neon Hyper-realistic"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Prompt</Form.Label>
          <Form.Control
            type="text"
            placeholder="example: hyper-realistic, high dynamic range, neon colors, lifelike textures, 8K UHD, high color depth."
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
          />
        </Form.Group>
        <p style={{ fontSize: "xx-small" }}>
          keep your art style prompt focused on the style and not the subject
          matter
        </p>

        <Button
          variant="light"
          style={{
            backgroundColor: "transparent",
            color: "#fff",
            border: "None",
          }}
          onClick={togglePublic}
          className="d-flex align-items-center justify-content-between"
        >
          {isPublic && <i className="bi bi-eye me-2 mr-3" />}
          {!isPublic && <i className="bi bi-eye-slash-fill me-2 mr-3" />}
          Make your style public
          <div className="ms-auto">
            {isPublic && (
              <i className="bi bi-check" style={{ fontSize: "large" }} />
            )}
          </div>
        </Button>
      </Form>
      <div className="mt-3 d-flex justify-content-between">
        <Button
          variant="secondary"
          style={{
            fontSize: isMobileDevice() ? "xx-small" : undefined,
            backgroundColor: "transparent",
          }}
          onClick={() => setShowCreateStyleModal(false)}
        >
          Cancel
        </Button>
        <Button
          variant="danger"
          style={{
            fontSize: isMobileDevice() ? "xx-small" : undefined,
            backgroundColor: "#ff30c4",
          }}
          onClick={addStyle}
        >
          Add style
        </Button>
        {loading && <Spinner />}
      </div>
    </Container>
  );
}

export default MobileCreateStyle;
