import { useSearchParams } from "react-router-dom";

export default function SearchBox() {
  const [search, setSearch] = useSearchParams();
  const query = search.get("query") || "";
  const id = search.get("id") || "";

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  return (
    <div
      className={`d-flex ${
        isMobileDevice() ? "justify-content-center" : "align-items-end"
      }`}
    >
      <div
        style={{
          border: "1px solid #f0f",
          borderRadius: "1rem",
          padding: "0.25rem 0.5rem",
          whiteSpace: "nowrap",
          position: "relative",
          display: "flex",
          alignItems: "center",
        }}
      >
        <i
          className="bi bi-search p-0 m-0"
          style={{ float: "left", left: "0.5rem", position: "absolute" }}
        />
        <i
          className="bi bi-x p-0 m-0"
          style={{ float: "right", right: "0.5rem", position: "absolute" }}
          onClick={() => {
            setSearch({ id, query: "" });
          }}
        />
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setSearch({ id, query });
          }}
        >
          <input
            className="library-searcher"
            style={{
              border: "none",
              textAlign: "center",
              width: "100%",
              display: "inline-block",
            }}
            type="text"
            placeholder={"Search"}
            onChange={({ target: { value } }) => {
              setSearch({ id, query: value });
            }}
          />
        </form>
      </div>
    </div>
  );
}
