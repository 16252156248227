export default function Separator(props: any) {
  const { children, height } = props;
  return (
    <div
      className="py-0 d-flex align-items-center"
      style={{ minHeight: height || "1rem" }}
    >
      <div className="w-100 border-top" />
      <small className="badge text-muted fw-lighter px-2" {...{ children }} />
      <div className="w-100 border-top" />
    </div>
  );
}
