import { createContext, useContext, useEffect, useState } from "react";
import { User, browserLocalPersistence } from "firebase/auth";
import { auth } from "./firebaseConfig";

interface AuthContextProps {
  currentUser: User | null;
  logOut: Function;
}
const AuthContext = createContext<AuthContextProps>({
  currentUser: null,
  logOut: () => {},
});

export default function AuthProvider({ children }: any) {
  const [currentUser, setCurrentUser] = useState(null);
  const logOut = () => auth.signOut();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user: any) => {
      setCurrentUser(user);
    });
    return () => unsubscribe();
  }, []);

  // persist login
  useEffect(() => {
    auth.setPersistence(browserLocalPersistence);
  }, []);

  return (
    <AuthContext.Provider value={{ currentUser, logOut }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth deve ser usado dentro de um AuthProvider");
  }
  return context;
}

export type AuthUser = User;
