import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useAuth } from "../../AuthProvider";
import { useCreateAvatar } from "../../store/hooks";
import { trainingForAvatarCreation } from "./utils";
import toast from "react-hot-toast";
import { APIClient } from "../../utils/services";
import { useReloadMediaQuery } from "../../API";
import PopUp from "../SharedComponents/PopUp";
import mixpanel from "mixpanel-browser";

const avatarName = "Avatar name";
const categoryInfo = `What category best describes your avatar. Super important - give your avatar a name and set the "Class" to reflect what the avatar is (man/woman/cat)`;
const avatarNameInfo = "Give your avatar a name";
const avatarCategory = "Avatar category";
const avatarNameInfoMobile = "Avatar name";

const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN || "";
mixpanel.init(MIXPANEL_TOKEN);

export default function MySideBody() {
  const { currentUser } = useAuth();
  const ownerId = currentUser?.email || "";
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const { refetch } = useReloadMediaQuery(ownerId);

  const {
    createAvatarName,
    createAvatarCategory,
    createAvatarMedia,
    setCreateAvatarName,
    setCreateAvatarCategory,
    resetCreateAvatar,
  } = useCreateAvatar();

  const handleDeleteImages = () => {
    if (createAvatarMedia.length > 0) {
      setShowDeleteConfirmation(true);
    }
  };

  const deleteConfirmationModal = {
    title: "Delete Image",
    text: "Are you sure you want to delete these images?",
    confirmText: "Confirm",
    shouldShow: showDeleteConfirmation,
    onConfirm: () => {
      const client = new APIClient();
      client.authenticate(
        undefined,
        undefined,
        process.env.REACT_APP_MUZE_API_KEY
      );
      const keysToDelete: string[] = createAvatarMedia;
      console.log("delete image: ", keysToDelete);
      client.delete_initial_images(ownerId, keysToDelete, () => {
        toast.success("Images deleted successfully", { duration: 5000 });
        refetch();
        resetCreateAvatar();
        setShowDeleteConfirmation(false);
      });
    },
    onClose: () => {
      setShowDeleteConfirmation(false);
    },
  };

  const handleChangeName = (e: any) => {
    setName(e.target.value);
    setCreateAvatarName(e.target.value);
  };
  const handleChangeCateg = (e: any) => {
    setCategory(e.target.value);
    setCreateAvatarCategory(e.target.value);
  };

  useEffect(() => {
    setName(createAvatarName);
    setCategory(createAvatarCategory);
  }, []);

  const disabled =
    createAvatarName.length < 3 ||
    createAvatarCategory === "" ||
    createAvatarMedia?.length < 10;

  const need = 10 - createAvatarMedia?.length;

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  return (
    <>
      {isMobileDevice() ? (
        <div className="d-flex flex-grow-1 p-2" style={{ maxWidth: "25rem" }}>
          <Form
            className="d-flex flex-column flex-grow-1 justify-content-start"
            onSubmit={(e: any) => {
              e.preventDefault();

              mixpanel.track("Create an Avatar", {
                ownerId: ownerId,
                avatarName: name,
                avatarCategory: category,
                mediaCount: createAvatarMedia.length,
              });

              trainingForAvatarCreation(
                { ownerId, files: createAvatarMedia, name, category },
                () => {
                  resetCreateAvatar();
                  setName("");
                  setCategory("");
                  toast.success(`Creating Avatar: ${name}, please wait.`, {
                    duration: 5000,
                  });
                }
              );
            }}
          >
            <div className="d-flex align-items-center">
              <Form.Group controlId="formAvatarName" className="mb-2 me-2">
                <Form.Label className="d-block">
                  {avatarNameInfoMobile}
                </Form.Label>
                <Form.Control
                  type="text"
                  value={name}
                  onChange={handleChangeName}
                />
              </Form.Group>

              <Form.Group controlId="formAvatarCategory" className="mb-2 me-2">
                <Form.Label className="d-block">{avatarCategory}</Form.Label>
                <Form.Control value={category} onChange={handleChangeCateg} />
              </Form.Group>

              <Button
                type="submit"
                id="btn-create"
                disabled={disabled}
                style={{
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                {" "}
                <i
                  className="bi bi-lightning-charge-fill"
                  style={{ fontSize: "1.6rem" }}
                />
              </Button>
              {createAvatarMedia.length > 0 && (
                <Button
                  variant="danger"
                  size="sm"
                  onClick={handleDeleteImages}
                  style={{
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                  }}
                >
                  <i
                    className="bi bi-trash-fill"
                    style={{ fontSize: "1.5rem", color: "red" }}
                  />
                </Button>
              )}
            </div>
          </Form>
          <PopUp {...deleteConfirmationModal} />
        </div>
      ) : (
        <div className="d-flex flex-grow-1 p-3" style={{ maxWidth: "16rem" }}>
          <Form
            className="d-flex flex-column flex-grow-1 justify-content-start"
            onSubmit={(e: any) => {
              e.preventDefault();

              mixpanel.track("Create an Avatar", {
                ownerId: ownerId,
                avatarName: name,
                avatarCategory: category,
                mediaCount: createAvatarMedia.length,
              });

              trainingForAvatarCreation(
                { ownerId, files: createAvatarMedia, name, category },
                () => {
                  resetCreateAvatar();
                  setName("");
                  setCategory("");
                  toast.success(`Creating Avatar: ${name}, please wait.`, {
                    duration: 5000,
                  });
                }
              );
            }}
          >
            <Form.Group controlId="formAvatarName" className="mb-5">
              <Form.Label className="d-block">{avatarName}</Form.Label>
              <Form.Text className="text-muted">{avatarNameInfo}</Form.Text>
              <Form.Control
                type="text"
                value={name}
                onChange={handleChangeName}
              />
            </Form.Group>

            <Form.Group
              controlId="formAvatarCategory"
              className="d-flex flex-column gap-3"
            >
              <Form.Label className="m-0">{avatarCategory}</Form.Label>
              <Form.Text className="text-muted m-0">{categoryInfo}</Form.Text>
              <Form.Control value={category} onChange={handleChangeCateg} />
            </Form.Group>

            <Form.Group className="d-flex flex-column justify-content-center mt-auto">
              {createAvatarMedia.length > 0 && (
                <Button
                  variant="danger"
                  size="sm"
                  className="mb-3"
                  onClick={handleDeleteImages}
                >
                  <i className="bi bi-trash-fill" /> Delete Images
                </Button>
              )}
              {need > 0 && (
                <span className="text-muted text-center">
                  Select {need}+ images
                </span>
              )}

              <Button
                type="submit"
                id="btn-create"
                children={"Create Avatar"}
                {...{ disabled }}
              />
            </Form.Group>
          </Form>
          <PopUp {...deleteConfirmationModal} />
        </div>
      )}
    </>
  );
}
