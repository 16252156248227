import { useNavigate } from "react-router-dom";

export default function ComingSoon() {
  const n = useNavigate();
  return (
    <div className="ps big-card rounded m-3 d-flex flex-grow-1 flex-column align-items-center justify-content-center overflow-hidden gap-5">
      <h1>
        Coming Soon{" "}
        <i className="bi bi-cone-striped" style={{ color: "#e1e" }} />
      </h1>
      <div className="btn btn-success" onClick={() => n(-1)}>
        <i className="bi bi-chevron-left" /> Back
      </div>
    </div>
  );
}
