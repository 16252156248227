import { Modal } from "react-bootstrap";

function ModalSignUp({ show, onHide, name, description, BackSignIn }: any) {
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="modal-title">
              <div>
                <h4>{name}</h4>
              </div>
            </div>

            <div className="p-1 mt-4">
              <p>{description}</p>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          onClick={BackSignIn}
          className="btn btn-primary"
          style={{ color: "white", backgroundColor: "#FF30C4", border: "none" }}
        >
          Ok
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalSignUp;
