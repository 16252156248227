import { TransitionConf } from "./types";

export const defaultTransition = () =>
  ({
    options: [],
    values: {
      transition: -1,
      strength: 2.0,
      offsetStart: 0.0,
      duration: 100.0,
      type: undefined,
    },
  } as TransitionConf);
