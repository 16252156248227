import { useCallback, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import ReactPlayer from "react-player";
import { APIClient } from "../../utils/services";
import { useAuth } from "../../AuthProvider";
import React from "react";
import "./styles.css";

interface Response {
  result: {
    video_settings: {
      audio: { name: string };
      style: {
        dreambooth_id: string | null;
        theme_id: string;
        subject_matter: string[];
      };

      audio_reactive_config: {
        enable_vocal_shake: boolean;
        enable_zoom_pulse_for_drums: boolean;
      };
    };
  }[];
}

export default function PreviewModalVideo(props: any) {
  const { videoId, shouldShow, title, url, onClose, download } = props;
  const [playing, setPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [prompts, setPrompts] = useState<string[]>([]);
  const [audioName, setAudioName] = useState<string>("");
  const [beatPulse, setBeatPulse] = useState<string>("");
  const [cameraShake, setCameraShake] = useState<string>("");
  const [dreamboothId, setDreamboothId] = useState<string | null>(null);
  const [themeId, setThemeId] = useState<string>("");
  const { currentUser } = useAuth();
  const ownerId = `${currentUser?.email}`;
  const client = new APIClient();

  const fetchVideoMetadata = useCallback((response: Response) => {
    if (response && response.result && response.result.length > 0) {
      const result = response.result[0];
      const { audio, style, audio_reactive_config } = result.video_settings;

      setPrompts(style.subject_matter);
      setAudioName(audio.name);
      setDreamboothId(style.dreambooth_id ? "Yes" : "No");
      setThemeId(style.theme_id);
      setBeatPulse(
        audio_reactive_config.enable_zoom_pulse_for_drums == true ? "Yes" : "No"
      );
      setCameraShake(
        audio_reactive_config.enable_vocal_shake == true ? "Yes" : "No"
      );
    }
  }, []);

  const handleMetadataError = useCallback((err: any) => {
    console.error("Error fetching video metadata:", err);
  }, []);

  useEffect(() => {
    if (ownerId && videoId) {
      setIsLoading(true);
      client.get_video_metadata(
        ownerId,
        videoId,
        fetchVideoMetadata,
        handleMetadataError
      );
      client.get_video_metadata(
        ownerId,
        videoId,
        fetchVideoMetadata,
        handleMetadataError
      );
    }
  }, [ownerId, videoId, fetchVideoMetadata, handleMetadataError]);

  return (
    <Modal show={shouldShow} onHide={onClose} centered>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="d-flex flex-column flex-grow-1 align-items-center justify-content-center overflow-hidden">
              <div
                className="d-flex"
                style={{ position: "relative", width: "100%", height: "100%" }}
              >
                <ReactPlayer
                  {...{
                    url,
                    height: "100%",
                    width: "100%",
                    playing,
                    loop: true,
                    muted: false,
                    volume: 0.8,
                    controls: true,
                    onReady: () => setIsLoading(false),
                  }}
                />
                {!isLoading && <Controls />}
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="row">
              <h5 className="title">{title}</h5>
            </div>
            <div className="row" style={{ marginBottom: "20px" }}>
              {prompts.map((prompt: string, index: number) => (
                <React.Fragment key={index}>
                  {prompt}
                  {index !== prompts.length - 1 && ". "}
                </React.Fragment>
              ))}
              .
            </div>
            <div className="row">
              <div>
                <i
                  className="bi bi-file-music-fill"
                  style={{
                    marginRight: "10px",
                    color: "#FF30C4",
                    fontSize: "18px",
                  }}
                ></i>
                <strong>Song: </strong> {audioName}
              </div>
              <div>
                <i
                  className="bi bi-person-fill"
                  style={{
                    marginRight: "10px",
                    color: "#FF30C4",
                    fontSize: "19px",
                  }}
                ></i>
                <strong>Avatar: </strong> {dreamboothId}
              </div>
              <div>
                <i
                  className="bi bi-brush-fill"
                  style={{
                    marginRight: "10px",
                    color: "#FF30C4",
                    fontSize: "18px",
                  }}
                ></i>
                <strong>Theme: </strong>
                {themeId}
              </div>
              <div>
                <i
                  className="bi bi-camera2"
                  style={{
                    marginRight: "10px",
                    color: "#FF30C4",
                    fontSize: "18px",
                  }}
                ></i>
                <strong>Camera Shake: </strong>
                {cameraShake}
              </div>
              <div>
                <i
                  className="bi bi-activity"
                  style={{
                    marginRight: "10px",
                    color: "#FF30C4",
                    fontSize: "18px",
                  }}
                ></i>
                <strong>Beat Pulse: </strong>
                {beatPulse}
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );

  function Controls(props: any) {
    return (
      <div
        className="d-flex flex-column justify-content-evenly"
        style={{
          position: "absolute",
          right: "0.5rem",
          zIndex: 1,
          height: "100%",
        }}
      ></div>
    );
  }
}
