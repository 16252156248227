import { useEffect } from "react";
import { useAspectRatioStore } from "../../../store/hooks";
import { useGetAspectRatioQuery } from "../../../API";

export default function AspectRatioSelector() {
  const { data, isLoading, isError, isSuccess } = useGetAspectRatioQuery(null);
  const { aspectRatio, setAspectRatio } = useAspectRatioStore();

  useEffect(() => {
    if (isSuccess && aspectRatio === "") setAspectRatio(data[0]);
  }, [isSuccess]);

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  return (
    <>
      {isMobileDevice() ? (
        <div className="d-flex gap-3 align-items-center">
          <p
            className="m-1 p-0"
            style={{ whiteSpace: "nowrap", fontSize: "14px" }}
          >
            Aspect Ratio:
          </p>
          <div className="d-flex border rounded align-items-center justify-content-center">
            {isLoading && <span>Loading...</span>}
            {isError && <span>Something went wrong. Please try later.</span>}

            {isSuccess &&
              data.map((...v: any) => (
                <div
                  onClick={() => setAspectRatio(v[0])}
                  key={v[1]}
                  className={`ps px-2 my-1 ${
                    v[2].length - 1 !== v[1] ? "border-end" : ""
                  }`}
                  style={{
                    color: `${v[0] === aspectRatio ? "#f0f" : "#fff"}`,
                    fontSize: "12px",
                  }}
                >
                  {v[0]}
                </div>
              ))}
          </div>
        </div>
      ) : (
        <div className="d-flex gap-3 align-items-center">
          <span className="ps text-muted">Aspect Ratio:</span>
          <div className="d-flex border rounded align-items-center justify-content-center">
            {isLoading && <span>Loading...</span>}
            {isError && <span>Something went wrong. Please try later.</span>}

            {isSuccess &&
              data.map((...v: any) => (
                <div
                  onClick={() => setAspectRatio(v[0])}
                  key={v[1]}
                  className={`ps px-3 my-1 ${
                    v[2].length - 1 !== v[1] ? "border-end" : ""
                  }`}
                  style={{
                    color: `${v[0] === aspectRatio ? "#f0f" : "#fff"}`,
                  }}
                >
                  {v[0]}
                </div>
              ))}
          </div>
        </div>
      )}
    </>
  );
}
