import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '.';

const initialState = {
  shouldUpdate: false,
};

const listImagesSlice = createSlice({
  name: 'listImages',
  initialState,
  reducers: {
    setShouldUpdate: (state, action) => ({ ...state, shouldUpdate: action.payload }),
  },
});

export const { setShouldUpdate } = listImagesSlice.actions;

export const selectShouldUpdate = (state: RootState) => state.userAssets.shouldUpdate;

export default listImagesSlice.reducer;
