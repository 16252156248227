export default {
  data: [
    {
      Name: "Illustration",
      Prompt:
        "Hyper detailed illustration of ({SUBJECT MATTER}):1.3, by Victo Ngai, by Kilian Eng, bright pastel colors, intricate details, 8k, dynamic lighting",
    },
    {
      Name: "Steampunk",
      Prompt:
        "Hyper detailed illustration of ({SUBJECT MATTER}):1.3, art nouveau, steampunk, collage style",
    },
    {
      Name: "Manga",
      Prompt:
        "Manga comic of ({SUBJECT MATTER}):1.3 by by Steve Ditko, 8k, cell shaded, volumetric light, vibrant colors",
    },
    {
      Name: "Cartoon",
      Prompt:
        "Manga comic of ({SUBJECT MATTER}):1.3 by by Rob Liefeld, 8k, cell shaded",
    },
    {
      Name: "Vibrant",
      Prompt:
        "Portrait of ({SUBJECT MATTER}):1.3 by Victor Moscoso, vibrant colors, 8K, trending on ArtStation",
    },
    {
      Name: "Psychedelic",
      Prompt:
        "Vibrant digital painting of a {SUBJECT MATTER}:1.3, by Alex Grey, hypnotic psychedelic art, 8K",
    },
    {
      Name: "Cyberpunk",
      Prompt:
        "Digital painting of ({SUBJECT MATTER}):1.3, cyberpunk, 8k, octane render, neon colors, bokeh, Tron",
    },
    {
      Name: "Surreal",
      Prompt:
        "Painting Masterpiece of ({SUBJECT MATTER}):1.3, Magritte style, Rockwell Style ,Dali style, hyperrealistic, depth of field",
    },
    {
      Name: "Gritty",
      Prompt:
        "Digital art of {SUBJECT MATTER}:1.3, Nicola Samori style, Ben Templesmith style, dark, punk",
    },
    {
      Name: "Photorealistic",
      Prompt:
        "photo of ({SUBJECT MATTER}):1.3 Photorealistic, analog style, soft lighting, subsurface scattering, ultra realistic, 8k, High Detail",
    },
    {
      Name: "Pop",
      Prompt:
        "Concept art of ({SUBJECT MATTER}):1.3 by Takashi Murakami, by Quentin Blake, Arcane Style, pop art, heavy outlines",
    },
    {
      Name: "Indie",
      Prompt:
        "portrait of ({SUBJECT MATTER}):1.3 by Victo Ngai, by Carne Griffiths, highly detailed, masterpiece",
    },
    {
      Name: "Realistic",
      Prompt: "({SUBJECT MATTER}):1.3, 8K, realistic",
    },
    {
      Name: "Fisheye Lens",
      Prompt: "({SUBJECT MATTER}):1.3, 8K, realistic, fisheye lens, neon",
    },
  ],
};
