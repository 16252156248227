import { createSlice } from "@reduxjs/toolkit";
import { RootState } from ".";
import { AudioAsset } from "../API";

export const MAX_AUDIO_LENGTH = 240;

const initialState = {
  isFromLibrary: false,
  selected: {} as AudioAsset,
  start: 0,
  end: MAX_AUDIO_LENGTH,
};

const audioAssetSlice = createSlice({
  name: "audioAsset",
  initialState,
  reducers: {
    setIsFromLib: (state) => ({
      ...state,
      isFromLibrary: !state.isFromLibrary,
    }),
    setSelected: (state, { payload }: { payload: AudioAsset }) => ({
      ...state,
      selected: payload,
    }),
    setDuration: (
      state,
      { payload }: { payload: { start: number; end: number } }
    ) => ({
      ...state,
      ...payload,
    }),
    setAudioStart: (state, { payload }: { payload: number }) => ({
      ...state,
      start: payload,
    }),
    setAudioEnd: (state, { payload }: { payload: number }) => ({
      ...state,
      end: payload,
    }),
    resetSelectedAudio: () => initialState,
  },
});

export const {
  setIsFromLib,
  setSelected,
  setAudioStart,
  setAudioEnd,
  setDuration,
  resetSelectedAudio,
} = audioAssetSlice.actions;

export const selectAudioAsset = (state: RootState) => state.audioAsset;

export const selectAudioStart = (state: RootState) => state.audioAsset.start;

export const selectAudioEnd = (state: RootState) => state.audioAsset.end;

export default audioAssetSlice.reducer;
