import { Link, useLocation } from "react-router-dom";
import "../../routes/styles.css";
import { APIClient } from "../../utils/services";
import { useEffect, useState } from "react";
import { useAuth } from "../../AuthProvider";
import { log } from "console";

const service = new APIClient();

export default function Navigator({ setMenuOpen, menuOpen, onItemClick }: any) {
  const [userPlan, setUserPlan] = useState<string | null>(null);
  const { currentUser } = useAuth();
  const currentLocation = useLocation();

  const ownerId = `${currentUser?.email}`;

  useEffect(() => {
    if (ownerId) {
      service.getUserByEmail(ownerId).then((res) => {
        if (res) {
          setUserPlan(res.account_type);
        }
      });
    }
  }, [ownerId]);

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  const getIconForMenu = (label: string) => {
    if (label === "Plan and Credits") {
      return userPlan === "free" ? "bi-tags-fill" : "bi-bell-fill";
    }
    // Retorna o ícone padrão definido na lista de menus
    const menuItem = Menus.find((menu) => menu.label === label);
    return menuItem?.icon || "";
  };

  const getPathForMenu = (label: string) => {
    if (label === "Plan and Credits") {
      return userPlan === "free" ? "pricing" : "billing";
    }
    // Retorna o caminho padrão definido na lista de menus
    const menuItem = Menus.find((menu) => menu.label === label);
    return menuItem?.to || "";
  };

  const updatedMenus = Menus.map((menu) => ({
    ...menu,
    icon: getIconForMenu(menu.label),
    to: getPathForMenu(menu.label),
  }));

  return (
    <div className="overflow-auto">
      {updatedMenus.map((menu, index) => (
        <MenuItem
          {...menu}
          key={index}
          setMenuOpen={setMenuOpen}
          menuOpen={menuOpen}
          onItemClick={onItemClick}
          isMobileDevice={isMobileDevice()}
          currentLocation={currentLocation}
        />
      ))}
    </div>
  );
}

function MenuItem({
  to,
  label,
  icon,
  setMenuOpen,
  menuOpen,
  onItemClick,
  isMobileDevice,
  currentLocation,
}: any) {
  const pausePath =
    "M12.404 2.364A2.364 2.364 0 0 0 10.041 0H6.134a2.364 2.364 0 0 0-2.363 2.364v27.273A2.364 2.364 0 0 0 6.134 32h3.907a2.364 2.364 0 0 0 2.363-2.363V2.364Zm15.826 0A2.364 2.364 0 0 0 25.866 0H21.96a2.364 2.364 0 0 0-2.364 2.364v27.273A2.364 2.364 0 0 0 21.96 32h3.906a2.364 2.364 0 0 0 2.364-2.363V2.364Z";

  const pause = () =>
    document.querySelectorAll("path").forEach((i) => {
      if (i.getAttribute("d") === pausePath) {
        i.parentElement?.parentElement?.click();
      }
    });

  const locationPath = currentLocation.pathname.split("/").pop();
  const isActive = locationPath === to.split("/").pop();
  const activeClass = isActive ? "nav-active" : "";
  const className = `btn text-start text-white d-flex gap-3 align-items-center ${activeClass}`;

  const onClick = isMobileDevice
    ? () => {
        pause();
        setMenuOpen(!menuOpen);
        onItemClick(to);
      }
    : () => {
        pause();
      };

  return (
    <Link to={to} className={className} onClick={onClick}>
      <i
        className={`bi ${icon}`}
        style={{ color: isActive ? "#FF30C4" : "#fff" }}
      />
      {label}
    </Link>
  );
}

const Menus = [
  {
    label: "Dashboard",
    icon: "bi-house-door",
    to: "dashboard",
  },
  {
    label: "Spotify Canvas Generator",
    icon: "bi-spotify",
    to: "spotify-generator",
  },
  {
    label: "Your projects",
    icon: "bi-folder",
    to: "library",
  },
  {
    label: "Assets Library",
    icon: "bi-folder-check",
    to: "assets-library",
  },
  {
    label: "Plan and Credits",
    icon: "bell-fill",
    to: "billing",
  },
];
