import { Button, Modal } from "react-bootstrap";

function ModalCancelSubscription({
  show,
  onHide,
  name,
  description,
  backToDashboard,
}: any) {
  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="row">
          <h5 style={{ fontSize: isMobileDevice() ? "medium" : undefined }}>
            {name}
          </h5>
          <div className="p-2 mt-0">
            <p style={{ fontSize: isMobileDevice() ? "small" : undefined }}>
              {description}
            </p>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn btn-primary"
          onClick={backToDashboard}
          style={{
            color: "white",
            backgroundColor: "#FF30C4",
            border: "none",
            fontSize: isMobileDevice() ? "xx-small" : undefined,
          }}
        >
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalCancelSubscription;
