import { useAuth } from "../../../AuthProvider";

import { useAudioAsset, useAudioTrim } from "../../../store/hooks";
import { useGetAudioTranscriptionsQuery } from "../../../API";

export default function LyricsBox() {
  const { currentUser } = useAuth();

  const { delta } = useAudioTrim();

  const { selectedMusic } = useAudioAsset();
  const assetId = selectedMusic?.asset_id || "";
  const ownerId = currentUser?.email || "";
  const hasMusic = assetId !== "";

  const specific_owner_audio_keys = [
    "audio-a4930ba0-fe04-4aa9-be28-7c1577b718bc",
    "audio-d9586edf-81f5-41ee-b2e4-ccb609aefe23",
    "audio-f5c929bd-4433-4ebb-8409-12491e361926",
  ];

  let queryOwnerId = ownerId;
  if (specific_owner_audio_keys.includes(assetId)) {
    queryOwnerId = "beatriz.muzeart@gmail.com";
  }

  const { data, isSuccess, isLoading } = useGetAudioTranscriptionsQuery({
    ownerId: queryOwnerId,
    assetId,
    aligned: true,
  });

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  let l = null;

  if (isSuccess && data?.length > 0) {
    const filteredTranscriptions = data.filter((transcription: any) => {
      return (
        (transcription.start >= delta.start &&
          transcription.start <= delta.end) || // Starts within the interval
        (transcription.end >= delta.start && transcription.end <= delta.end) || // Ends within the interval
        (transcription.start <= delta.start &&
          transcription.end >= delta.end) || // Encloses the interval
        (transcription.end > delta.start && transcription.start < delta.end) // Overlaps the interval
      );
    });

    const adjustedTranscriptions = filteredTranscriptions.map(
      (transcription: any) => ({
        ...transcription,
        start: Math.max(transcription.start - delta.start, 0),
        end: transcription.end - delta.start,
      })
    );

    l = adjustedTranscriptions.map((transcription: any) => (
      <div
        className={isMobileDevice() ? "p-0 mb-2 d-flex" : "p-0 m-0 mb-3"}
        key={transcription.id}
      >
        {transcription.start !== 0.04005006257822278 &&
          transcription.end !== 3.4643304130162704 &&
          (isMobileDevice() ? (
            <p className="p-0" style={{ marginRight: "10px" }}>
              {formatMinutes(transcription.start)}
            </p>
          ) : (
            <p className="p-0 m-0">{formatMinutes(transcription.start)}</p>
          ))}

        {transcription.start !== 0.04005006257822278 &&
          transcription.end !== 3.4643304130162704 &&
          (isMobileDevice() ? (
            <p className="p-0">
              {transcription.text.includes("🎵")
                ? transcription.text
                : "🎵 " + transcription.text + " 🎵"}
            </p>
          ) : (
            <p className="p-0 m-0">
              {transcription.text.includes("🎵")
                ? transcription.text
                : "🎵 " + transcription.text + " 🎵"}
            </p>
          ))}
      </div>
    ));
  }

  return (
    <div
      className={`d-flex flex-column overflow-hidden flex-grow-1 ${
        isMobileDevice() ? "p-3 gap-3 rounded border" : "p-1"
      }${isMobileDevice() ? "" : ""}`}
      style={
        isMobileDevice() ? { height: "250px", backgroundColor: "#2b3035" } : {}
      }
    >
      {selectedMusic.asset_id ? (
        <p className="small text-muted text-center m-0">{Title}</p>
      ) : null}
      {selectedMusic.asset_id ? (
        <div className="overflow-auto">
          <div className="d-flex flex-column justify-content-center flex-grow-1">
            {!hasMusic && <NoMusic />}
            {hasMusic && isLoading && <Loading />}
            {hasMusic && isSuccess && l}
          </div>
        </div>
      ) : null}
    </div>
  );
}

const Title = "Full lyrics transcription";
const Error = "Select a music to see the lyrics.";
const Loading = () => <div className="text-center">Loading...</div>;
const NoMusic = () => <div className="text-center">{Error}</div>;

export const formatMinutes = (sec: number) => {
  const m = Math.floor(sec / 60);
  const s = Math.floor(sec % 60);
  return `${m > 9 ? "" : "0"}${m}:${s > 9 ? "" : "0"}${s}`;
};

export const formatSeconds = (time: string) => {
  const timeSplit = time.split(":");
  const m = Math.floor(Number(timeSplit[0]) * 60);
  const s = Number(timeSplit[1]);
  return m + s;
};
