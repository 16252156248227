import { Modal, Button } from "react-bootstrap";

function ModalConfirmationCancel({
  show,
  onHide,
  name,
  description,
  confirm,
  notConfirm,
}: any) {
  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="row" style={{ fontSize: "small" }}>
          <h5 style={{ fontSize: "medium" }}>{name}</h5>
          <div className="p-2 mt-0">
            <p>{description}</p>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          style={{ fontSize: isMobileDevice() ? "xx-small" : undefined }}
          onClick={notConfirm}
        >
          No, I want to keep my subscription
        </Button>
        <Button
          variant="danger"
          style={{ fontSize: isMobileDevice() ? "xx-small" : undefined }}
          onClick={confirm}
        >
          Yes, I want to cancel my subscription
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalConfirmationCancel;
