import axios from "axios";
import { useEffect, useState } from "react";
import { useCustomEventListener } from "react-custom-events";
import { useSearchParams } from "react-router-dom";
import { useAuth } from "../../AuthProvider";
import PopUp from "../SharedComponents/PopUp";
import usePaginator from "../Paginator/usePaginator";
import Paginator from "../Paginator";

export default function Images() {
  const [images, setImages] = useState([]);
  const [imageData, setImageData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasImages, setHasImages] = useState(false);
  const [error, setError] = useState(false);
  const [show, setShow] = useState(false);
  const [modalInfo, setModalInfo] = useState({});
  const paginator = usePaginator();
  const { actual, setTotal } = paginator;

  const { currentUser } = useAuth();
  const [search] = useSearchParams();
  const query = search.get("query");
  const ownerId = currentUser?.email || "";
  const { REACT_APP_MUZE_API_HOST, REACT_APP_IMAGES_CDN_URL } = process.env;

  const page = actual;
  const perPage = 10;

  const deleteImage = (name: string) => {
    const handleDelete = () => {
      axios
        .delete(`${REACT_APP_MUZE_API_HOST}/initial-image/${ownerId}/${name}`)
        .then((res) => {
          const newData = images.filter((el: any) => el.name !== name);
          setImages(newData);
          setImageData(newData);
        })
        .catch((err) => {
          console.log(err);
          setError(true);
        })
        .finally(() => {
          setShow(false);
        });
    };

    setModalInfo({
      shouldShow: true,
      title: "Delete image",
      text: "Are you sure you want to delete this image?",
      confirmText: "Delete",
      onConfirm: () => handleDelete(),
      onClose: () => setShow(false),
    });
    setShow(true);
  };
  const getImages = () => {
    setLoading(true);
    axios
      .get(
        `${REACT_APP_MUZE_API_HOST}/initial-image/assets?owner_id=${encodeURIComponent(
          ownerId
        )}&page=${page}&per_page=${perPage}`
      )
      .then((res) => {
        if (res.data.results.length === 0 || !Array.isArray(res.data.results)) {
          setLoading(false);
          setHasImages(false);
          setTotal(0);
          return;
        }
        const formatedImages = res.data.results.map((el: any) => {
          return {
            src: `${REACT_APP_IMAGES_CDN_URL}temp/${encodeURIComponent(
              ownerId
            )}/${encodeURIComponent(el.Key)}`,
            name: el.Key,
            date: new Date(el.LastModified).toLocaleDateString(),
          };
        });
        setImages(formatedImages);
        setImageData(formatedImages);
        setHasImages(true);
        setTotal(res.data.total);
      })
      .catch((err) => {
        console.log(err);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useCustomEventListener("onImageUpload", () => {
    getImages();
  });

  useEffect(() => {
    getImages();
  }, [actual]);

  useEffect(() => {
    setImages(() =>
      imageData.filter((el: any) =>
        el.name.toLowerCase().includes(query?.toLowerCase())
      )
    );
  }, [query]);

  return (
    <div>
      <div className="library-img-conteiner">
        {show && <PopUp {...modalInfo} />}
        {error ? (
          "Ops, something went wrong :("
        ) : loading ? (
          <Spinner />
        ) : !hasImages ? (
          "0 images found."
        ) : (
          images.map(({ name, src, date }, i) =>
            Card({ name, src, date, key: `img-card-${i}`, deleteImage })
          )
        )}
      </div>
      <div className="paginator-container">
        <Paginator {...paginator} />
      </div>
    </div>
  );
}

function Spinner() {
  return (
    <div
      style={{ height: "100%" }}
      className="d-flex justify-content-center align-items-center"
    >
      <div
        className="spinner-border"
        style={{ width: "2rem", height: "2rem" }}
        role="status"
      />
    </div>
  );
}

function Card(props: any) {
  const { name, src, date, key, deleteImage } = props;

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  const downloadImage = () => {
    const link = document.createElement("a");
    link.href = src;
    link.download = name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      {isMobileDevice() ? (
        <div
          className={`d-flex flex-column align-items-stretch justify-content-start btn border p-0 rounded position-relative`}
          style={{
            width: "100%",
            marginBottom: "0.5rem",
            marginRight: "10px",
            maxWidth: "38%",
          }}
        >
          <div
            className="library-img-delete position-absolute"
            onClick={() => deleteImage(name)}
            onMouseEnter={(e: any) => {
              e.currentTarget.querySelector("i").style.color = "#FF30C4";
            }}
            onMouseLeave={(e: any) => {
              e.currentTarget.querySelector("i").style.color = "#fff";
            }}
            style={{
              top: "10px",
              right: "10px",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              color: "white",
              border: "none",
              borderRadius: "50%",
              padding: "5px",
              cursor: "pointer",
            }}
          >
            <i className="bi bi-trash p-0 m-0" />
          </div>
          <button
            className="btn btn-download position-absolute"
            onClick={downloadImage}
            style={{
              top: "10px",
              left: "10px",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              color: "white",
              border: "none",
              borderRadius: "50%",
              padding: "5px",
              cursor: "pointer",
            }}
          >
            <i
              className="bi bi-cloud-arrow-down-fill"
              style={{ fontSize: "medium" }}
            ></i>
          </button>
          <img
            className="library-img"
            src={src}
            alt=""
            style={{ width: "100%", height: "100%", display: "block" }}
          />
        </div>
      ) : (
        <div
          className="rounded border text-center library-img-card position-relative"
          style={{ width: "15rem", height: "15rem" }}
          key={key}
        >
          <div
            className="library-img-delete position-absolute"
            onClick={() => deleteImage(name)}
            style={{
              top: "10px",
              right: "10px",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              color: "white",
              border: "none",
              borderRadius: "50%",
              padding: "5px",
              cursor: "pointer",
            }}
          >
            <i className="bi bi-trash p-0 m-0" />
          </div>
          <button
            className="btn btn-download position-absolute"
            onClick={downloadImage}
            style={{
              top: "10px",
              left: "10px",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              color: "white",
              border: "none",
              borderRadius: "50%",
              padding: "5px",
              cursor: "pointer",
            }}
          >
            <i
              className="bi bi-cloud-arrow-down-fill"
              style={{ fontSize: "large" }}
            ></i>
          </button>
          <p className="m-0 p-0 small">{date}</p>
          <img
            className="library-img"
            src={src}
            alt=""
            style={{ width: "100%", height: "100%", display: "block" }}
          />
          <p className="text-center m-0 p-0 asset-name">{name}</p>
        </div>
      )}
    </>
  );
}
