import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Media } from "../components/CreateAvatar/types";

type MediaResponse = {
  contents: Media[];
  quantity: string;
  status: string;
};

export type AudioAsset = {
  name: string;
  owner_id: string;
  AUDIO_status: string;
  asset_id: string;
  http_link: string;
  last_update: string;
  size: number;
  auth_user: string;
  bucket: string;
  key: string;
  next_step_video: boolean;
  timestamp: string;
  "mypart-available": boolean;
  "stems-available": boolean;
  transcriptions: any[];
  events: any[];
  parts: {
    key: string;
    name: string;
    model: string;
    http?: string; // this field is appended in streamlit frontend using hardcoded info
  }[];
};

type ResultLibRes = {
  items: AudioAsset[];
  total_items: number;
};

type AudioLibRes = {
  results: ResultLibRes;
};

type VideoStyleResponse = {
  results: VideoStyleItem[];
};

export type VideoStyleItem = {
  theme_id: string;
  description: string;
  name: string;
  urls: string[];
};

const API_KEY =
  process.env.REACT_APP_MUZE_API_KEY ||
  "environment variable undefined: REACT_APP_MUZE_API_KEY";

// SERVICE:
const BASE_URL = process.env.REACT_APP_MUZE_API_HOST;

export const museApi = createApi({
  reducerPath: "museApi",
  refetchOnFocus: true,
  refetchOnMountOrArgChange: true,
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }), // https://redux-toolkit.js.org/rtk-query/usage/queries
  endpoints: (builder) => ({
    // for use in create avatar (list) uploaded images
    reloadMedia: builder.query<MediaResponse, string>({
      query: (ownerId) => `/dreambooth/uploaded/${ownerId}`,
    }),
    // for use in audio (list) library
    reloadAudio: builder.query<
      AudioLibRes,
      { ownerId: string; page: number; perPage: number }
    >({
      query: ({ ownerId, page, perPage }) => ({
        url: `/audio/assets?owner_id=${encodeURIComponent(
          ownerId
        )}&page=${page}&per_page=${perPage}`,
        headers: [["access_token", API_KEY]],
      }),
    }),

    // for use in audio (list) library
    reloadAudioDefault: builder.query<
      AudioLibRes,
      { page: number; perPage: number }
    >({
      query: ({ page, perPage }) => ({
        url: `/audio/assets/default?page=${page}&per_page=${perPage}`,
        headers: [["access_token", API_KEY]],
      }),
    }),

    getTransitionEffects: builder.query({
      query: (animationType: string) => ({
        url: `/effects/transitions`,
        headers: [["access_token", API_KEY]],
        params: [["animation_type", animationType]],
      }),
    }),

    getVideoStyles: builder.query<
      VideoStyleResponse,
      {
        maxImagesPerTheme: number;
        publicOnly: boolean;
        ownerId?: string;
        isCustomStyle?: boolean;
      }
    >({
      query: ({ maxImagesPerTheme, publicOnly, ownerId, isCustomStyle }) => {
        let url = `/styles/thumbnail_links`;
        const queryParams = new URLSearchParams({
          max_images_per_theme: maxImagesPerTheme.toString(),
          public_only: publicOnly.toString(),
          owner_id: ownerId || "",
          is_custom_style: isCustomStyle?.toString() || "",
        });

        return {
          url: `${url}?${queryParams.toString()}`,
          headers: [["access_token", API_KEY]],
        };
      },
    }),

    getStemAnalisys: builder.query({
      query: ({ assetId, ownerId }: { assetId: string; ownerId: string }) => ({
        // url: `/audio/song_segmentation/${ownerId}/${assetId}`, // wrong info (???)
        url: `/audio/stem_analysis/${ownerId}/${assetId}`,
        headers: [["access_token", API_KEY]],
      }),
    }),
    // Library info
    getAvatars: builder.query({
      query: ({ ownerId }: { ownerId: string }) => ({
        url: `/dreambooth/metadata/${ownerId}`,
        headers: [["access_token", API_KEY]],
      }),
    }),

    getMetadataDefault: builder.query({
      query: ({ owner_default }: { owner_default: string }) => ({
        url: `/dreambooth/metadata/default/${owner_default}`,
        headers: [["access_token", API_KEY]],
      }),
    }),

    getAvatarByIdDefault: builder.query({
      query: ({
        owner_default,
        dreamboothId,
      }: {
        owner_default: string;
        dreamboothId: string;
      }) => ({
        url: `/dreambooth/metadata/previews/${owner_default}/${dreamboothId}`,
        headers: [["access_token", API_KEY]],
      }),
    }),

    getAvatarById: builder.query({
      query: ({
        ownerId,
        dreamboothId,
      }: {
        ownerId: string;
        dreamboothId: string;
      }) => ({
        url: `/dreambooth/metadata/previews/${ownerId}/${dreamboothId}`,
        headers: [["access_token", API_KEY]],
      }),
    }),

    getAudios: builder.query({
      query: ({ ownerId }: { ownerId: string }) => ({
        url: `/audio/assets`,
        params: [["owner_id", ownerId]],
        headers: [["access_token", API_KEY]],
      }),
    }),
    getVideos: builder.query({
      query: ({ ownerId }: { ownerId: string }) => ({
        url: `/video/metadata/${ownerId}`,
        headers: [["access_token", API_KEY]],
      }),
    }),

    //upscale
    getVideosUpscale: builder.query({
      query: ({ ownerId, videoId }: { ownerId: string; videoId: string }) => ({
        url: `/video/upscale-metadata/${ownerId}/${videoId}`,
        headers: [["access_token", API_KEY]],
      }),
    }),

    getVideosUpscaleAll: builder.query({
      query: ({ ownerId }: { ownerId: string }) => ({
        url: `/video/upscale-metadata-all/${ownerId}`,
        headers: [["access_token", API_KEY]],
      }),
    }),

    getRestyleMetadata: builder.query({
      query: ({ ownerId }: { ownerId: string }) => ({
        url: `/video/restyle/metadata/${ownerId}`,
        headers: [["access_token", API_KEY]],
      }),
    }),

    getStyleCustomMetadata: builder.query({
      query: ({ ownerId }: { ownerId: string }) => ({
        url: `/style/custom-metadata/${ownerId}`,
        headers: [["access_token", API_KEY]],
      }),
    }),

    reloadVideosUpscale: builder.query({
      query: ({ ownerId }: { ownerId: string }) => ({
        url: `/upscale/uploaded/${ownerId}`,
        headers: [["access_token", API_KEY]],
      }),
    }),

    reloadVideosRestyled: builder.query({
      query: ({ ownerId }: { ownerId: string }) => ({
        url: `/restyled/uploaded/${ownerId}`,
        headers: [["access_token", API_KEY]],
      }),
    }),

    // END Library info
    getAudioTranscriptions: builder.query<TscriptResponse[], unknown>({
      query: ({ ownerId, assetId, aligned }: TscriptRequest) => {
        let url = `/audio/transcription${aligned ? "-aligned" : ""}`;
        return {
          url: `${url}?owner_id=${ownerId}&asset_id=${assetId}`,
          headers: [["access_token", API_KEY]],
        };
      },
    }),
    getAspectRatio: builder.query({
      query: () => ({
        url: `/ui/widgets/orientation`,
        headers: [["access_token", API_KEY]],
      }),
    }),

    // FOR PREVIEW VIDEO
    getSingleVideoMetadata: builder.query({
      query: ({ ownerId, videoId }: { ownerId: string; videoId: string }) => ({
        url: `/video/metadata_single/${ownerId}/${videoId}`,
        headers: [["access_token", API_KEY]],
      }),
    }),
    // FOR LIBRARY VIDEO
    getVideoMetadata: builder.query({
      query: (ownerId) => ({
        url: `/video/metadata/${ownerId}`,
        headers: [["access_token", API_KEY]],
      }),
    }),
    // FOR INITIAL IMAGE
    getImageAssets: builder.query({
      query: ({
        ownerId,
        page,
        perPage,
      }: {
        ownerId: string;
        page: number;
        perPage: number;
      }) => ({
        url: `/initial-image/assets?owner_id=${ownerId}&page=${page}&per_page=${perPage}`,
        headers: [["access_token", API_KEY]],
      }),
    }),
  }),
});

export const {
  useReloadMediaQuery,
  useReloadVideosUpscaleQuery,
  useReloadAudioQuery,
  useGetStyleCustomMetadataQuery,
  useReloadAudioDefaultQuery,
  useGetMetadataDefaultQuery,
  useGetTransitionEffectsQuery,
  useGetVideoStylesQuery,
  useGetStemAnalisysQuery,
  useGetAudiosQuery,
  useGetVideosQuery,
  useGetVideosUpscaleQuery,
  useGetAvatarsQuery,
  useGetAvatarByIdQuery,
  useGetAvatarByIdDefaultQuery,
  useGetAudioTranscriptionsQuery,
  useGetAspectRatioQuery,
  useGetVideoMetadataQuery,
  useGetImageAssetsQuery,
  useGetSingleVideoMetadataQuery,
  useGetVideosUpscaleAllQuery,
  useGetRestyleMetadataQuery,
} = museApi;

type TscriptRequest = {
  ownerId: string;
  assetId: string;
  aligned?: boolean;
};

type TscriptResponse = {
  text: string;
  end: string;
};
