import { Button, Stack, Form } from 'react-bootstrap';
import { useState } from 'react';
import { APIClient } from '../../../utils/services';

const API_KEY = process.env.REACT_APP_MUZE_API_KEY;

export default function Thumbnails() {
  const [data, setData] = useState({});

  const onSubmit = (e: any) => {
    e.preventDefault();
    const client = new APIClient();
    client.authenticate(undefined, undefined, API_KEY);
    client.generate_thumbnails_for_styles(setData, (e: any) => setData({e}));
  };

  return (
    <Stack className="mt-3 gap-3 justify-content-end">
      <Stack children={`${Object.entries(data)}`} />
      <Form {...{ onSubmit }}>
        <Button
          type="submit"
          className="col-12"
          children={'Generate Thumbnails'}
        />
      </Form>
    </Stack>
  );
}
