import { Form } from "react-bootstrap";
import {
  useMotionStrengthStore,
  useVocalShakeStore,
  useZoomPulseStore,
} from "../../../../store/hooks";

export default function AudioReactivity() {
  const { motionStrength, setMotionStrength } = useMotionStrengthStore();
  const { zoomPulse, setZoomPulse } = useZoomPulseStore();
  const { vocalShake, setVocalShake } = useVocalShakeStore();

  const title = `Audio Reactivity: lower = camera moves less with your audio, higher = camera moves more`;
  const start = 0;
  const end = 10;
  const min = 0;
  const max = 1;
  const step = 0.1;
  return (
    <div className="ps border rounded">
      {/* TITLE AND SLIDER */}
      <div className="m-3 mb-0">
        <h5 className="text-white">{title}</h5>
        <Form.Range
          min={min}
          max={max}
          step={step}
          value={motionStrength}
          onChange={(event) => setMotionStrength(Number(event.target.value))}
        />
        <div className="d-flex justify-content-between">
          <span className="small text-muted">{start}</span>
          <span className="small text-muted">{end}</span>
        </div>
      </div>

      {/* PULSE AND SHAKE */}
      <div className="d-flex justify-content-between">
        <div
          className="d-flex gap-3 p-3"
          onClick={() => {
            setZoomPulse(!zoomPulse);
          }}
        >
          <span>Beat Pulse</span>
          <input type="checkbox" checked={zoomPulse} readOnly />
        </div>
        <div
          className="d-flex gap-3 p-3"
          onClick={() => {
            setVocalShake(!vocalShake);
          }}
        >
          <span>Camera Shake</span>
          <input type="checkbox" checked={vocalShake} readOnly />
        </div>
      </div>
    </div>
  );
}
