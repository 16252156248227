import {
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
  sendPasswordResetEmail,
} from "firebase/auth";
import { auth } from "./firebaseConfig";
import { Navigate } from "react-router-dom";
import { APIClient } from "../utils/services";

export function signInWithGoogle(
  callback: any = () => {},
  errCallback = () => {}
) {
  const provider = new GoogleAuthProvider();
  signInWithPopup(auth, provider)
    .then((res) => {
      callback(res.user.email);
    })
    .catch(errCallback);
}

export function signInWithEmail(
  email: string,
  password: string,
  callback = () => {},
  errCallback = (error: string) => {}
) {
  const service = new APIClient();
  service.authenticate_user(email, password).then((result) => {
    if (result?.custom_token) {
      signInWithEmailAndPassword(auth, email, password)
        .then(callback)
        .catch(errCallback);
    } else {
      errCallback("Authentication failed");
    }
  });
}

export function logOut() {
  auth.signOut().then(() => Navigate({ to: "/" }));
}

export function sendPasswordReset(
  email: string,
  callback = () => {},
  errCallback = (error: any) => {}
): any {
  return sendPasswordResetEmail(auth, email)
    .then(() => {
      callback();
    })
    .catch((error) => {
      if (error.message === "INVALID_EMAIL") {
        errCallback({ code: error.code, message: error.message });
      } else if (
        error.code === "auth/user-not-found" ||
        error.message === "auth/user-not-found"
      ) {
        errCallback({ code: error.code, message: error.message });
      } else {
        errCallback({ code: error.code, message: error.message });
      }
    });
}
