import { APIClient } from "../../utils/services";

async function avatarPreview(owner_id: string, dreambooth_id: string) {
  const service = new APIClient();
  try {
    const result = (await service.dreambooth_get_preview_images(
      owner_id,
      dreambooth_id
    )) as any;
    return result;
  } catch (error) {
    return;
  }
}

async function avatarPreviewDefault(owner_id: string, dreambooth_id: string) {
  const service = new APIClient();
  try {
    const result = await service.dreambooth_get_preview_images_default(
      owner_id,
      dreambooth_id
    );
    return result;
  } catch (error) {
    console.error("Error fetching avatar preview:", error);
    return null;
  }
}

function processAvatarData(
  avatar: any,
  imageAvatar: string,
  ownerIdEncoded: string
): any {
  return {
    ...avatar,
    imageAvatar,
    date: new Date(avatar.timestamp).toLocaleDateString(),
  };
}

export async function getAvatarsList(
  currentUser: any,
  data: any,
  dataDefault: any,
  themeId: any
): Promise<any> {
  const ownerId = currentUser?.email || "";
  const owner_default = "leeork+10@mymuze.art";

  const ownerIdEncodedDefault = encodeURIComponent(owner_default);
  const ownerIdEncoded = encodeURIComponent(ownerId);

  const defaultAvatars = dataDefault?.result?.records || [];
  const records = data?.result?.records || [];

  const avatarsArray = [] as any;
  let imageAvatar = "";

  try {
    // Buscar estilos e imagens dos avatares
    const [stylesImages, defaultStylesImages] = await Promise.all([
      Promise.all(
        records.map((record: any) =>
          avatarPreview(ownerId, record.dreambooth_id)
        )
      ),
      Promise.all(
        defaultAvatars.map((avatar: any) =>
          avatarPreviewDefault(owner_default, avatar.dreambooth_id)
        )
      ),
    ]);

    // Processar avatares padrão
    defaultStylesImages.forEach((previewData, index) => {
      try {
        const thumbnailKeys = previewData?.data?.result?.["thumbnail-keys"];
        if (Array.isArray(thumbnailKeys) && thumbnailKeys.length > 0) {
          const selectedThumbnail = thumbnailKeys.find(
            (item) => item.Name === themeId
          );
          imageAvatar =
            selectedThumbnail?.images[0]?.http_link.replace(
              owner_default,
              ownerIdEncodedDefault
            ) ||
            thumbnailKeys[0]?.images[0]?.http_link.replace(
              owner_default,
              ownerIdEncodedDefault
            ) ||
            "";
        }
        avatarsArray.push(
          processAvatarData(
            defaultAvatars[index],
            imageAvatar,
            ownerIdEncodedDefault
          )
        );
      } catch (error) {
        console.error(error);
        avatarsArray.push(defaultAvatars[index]);
      }
    });

    // Processar avatares de estilos
    stylesImages.forEach((previewData, index) => {
      try {
        const thumbnailKeys = previewData?.data?.result?.["thumbnail-keys"];
        if (Array.isArray(thumbnailKeys) && thumbnailKeys.length > 0) {
          const selectedThumbnail = thumbnailKeys.find(
            (item) => item.Name === themeId
          );
          imageAvatar =
            selectedThumbnail?.images[0]?.http_link.replace(
              ownerId,
              ownerIdEncoded
            ) ||
            thumbnailKeys[0]?.images[0]?.http_link.replace(
              ownerId,
              ownerIdEncoded
            ) ||
            "";
        }
        avatarsArray.push(
          processAvatarData(records[index], imageAvatar, ownerIdEncoded)
        );
      } catch (error) {
        console.error(error);
        avatarsArray.push(records[index]);
      }
    });

    return {
      avatarsData: avatarsArray.sort(
        (a: any, b: any) =>
          new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
      ),
      imageAvatar,
    };
  } catch (error) {
    console.log(error);
  }
}
