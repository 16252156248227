import { useEffect, useRef, useState } from "react";
import { Stack } from "react-bootstrap";
import { commonImageTypes, validFileType } from "./utils";
import { APIClient, upload_file } from "../../utils/services";
import { useReloadMediaQuery } from "../../API";
import { useAuth } from "../../AuthProvider";
import toast from "react-hot-toast";

const API_KEY = process.env.REACT_APP_MUZE_API_KEY;

export default function Upload() {
  const { currentUser } = useAuth();
  const [allFilesUploaded, setAllFilesUploaded] = useState(false);
  const ownerId = currentUser?.email || "";
  const { refetch } = useReloadMediaQuery(ownerId);

  const className = "col-12 col-sm-6 col-md-4 col-xl-3 col-xxl-2";
  const inputFile = useRef<HTMLInputElement | null>(null);

  const onClick = openInputDialog(inputFile);
  const handleChange = (e: any) => {
    const files = e.target.files;
    const client = new APIClient();
    client.authenticate(undefined, undefined, API_KEY);

    if (files.length > 0) {
      const validFiles = Array.from(files).filter((file) =>
        validFileType(file as File, commonImageTypes)
      );

      if (validFiles.length === 0) {
        toast.error("No valid image file selected", { duration: 9000 });
        return;
      }
      const invalidFile = Array.from(files).some(
        (file) => !validFileType(file as File, commonImageTypes)
      );

      if (invalidFile) {
        toast.error("One or more selected type files aren't valid. Try again", {
          duration: 9000,
        });
        return;
      }

      toast.success("Loading files...", { duration: 9000 });

      let successfulUploads = 0;

      for (let i = 0; i < files.length; i += 1) {
        const file = files[i];
        const name = `${file.name}`;
        const okFunc = (data: any) => {
          if (data.status === "ok") {
            upload_file(data.signed_url, file, () => {
              successfulUploads++;
              if (successfulUploads === files.length) {
                toast.dismiss();
                refetch();
                toast.success("File(s) sent successfully!", { duration: 7000 });
                setAllFilesUploaded(true);
              }
            });
          } else {
            toast.error("Error getting signed URL.", { duration: 7000 });
          }
        };
        client.get_dreambooth_signed_url(ownerId, name, okFunc);
      }
    }
  };

  useEffect(() => {
    return () => {
      setAllFilesUploaded(false);
    };
  }, []);

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  return (
    <>
      {isMobileDevice() ? (
        <div onClick={onClick} className={className} style={{ width: "3rem" }}>
          <Input inputFile={inputFile} onChange={handleChange} />

          <button
            className="btn btn-primary btn-floating"
            style={{
              backgroundColor: "#ff57d0",
              border: "none",
              borderRadius: "10px",
            }}
            onClick={onClick}
          >
            <i className="bi bi-plus-circle-fill"></i>
          </button>
        </div>
      ) : (
        <div
          {...{
            onClick,
            className,
            style: { width: "10rem", height: "10rem" },
          }}
        >
          <Input inputFile={inputFile} onChange={handleChange} />
          <Stack className="card my-clicable border rounded m-2">
            {/* <i className="bi bi-x-lg fs-5 align-self-end" /> */}
            <Stack className="text-center mt-auto justify-content-center">
              <i
                style={{ lineHeight: 0 }}
                className="bi bi-arrow-bar-up fs-6 mt-2"
              />
              <i style={{ lineHeight: 0 }} className="bi bi-image fs-5" />
              <p className="mt-1 fs-6">Upload media</p>
            </Stack>
          </Stack>
        </div>
      )}
    </>
  );
}

function openInputDialog(
  inputFile: React.MutableRefObject<HTMLInputElement | null>
) {
  return () => inputFile.current?.click();
}

function Input(props: any) {
  const { inputFile, onChange } = props;
  return (
    <input
      type="file"
      id="file"
      ref={inputFile}
      style={{ display: "none" }}
      accept="image/*"
      multiple={true}
      onChange={onChange}
    />
  );
}
