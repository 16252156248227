import { useNavigate, useLocation } from "react-router-dom";
import { useRef } from "react";
import ReactPlayer from "react-player";

const TITLE = "Upscale Effect Applied";

export default function UpscalePage() {
  const navigate = useNavigate();
  const ref = useRef<any>(null);
  const location = useLocation();
  const { state } = location;
  const { url } = state;

  const handleBackClick = () => {
    navigate("/app/library/projects");
  };

  return (
    <div className="ps big-card rounded m-3 d-flex flex-grow-1 align-items-center justify-content-center">
      <div className="d-flex flex-column flex-grow-1 justify-content-center align-items-center border-end h-100 p-3">
        <h2 className="mt-3 fw-bold">{TITLE}</h2>
        <div
          className="d-flex flex-column p-3 overflow-hidden "
          style={{ height: "100%" }}
        >
          <div
            className="d-flex justify-content-center my-auto overflow-auto"
            style={{ height: "100%", width: "100%" }}
          >
            <ReactPlayer
              {...{
                url,
                height: "100%",
                width: "100%",
                ref,
                loop: true,
                muted: false,
                volume: 0.8,
                controls: true,
              }}
            />
          </div>
        </div>

        <div className="btn btn-success" onClick={handleBackClick}>
          <i className="bi bi-chevron-left" /> Back
        </div>
      </div>
    </div>
  );
}
