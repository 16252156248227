import { Card, Col, Stack } from "react-bootstrap";
import { useReloadMediaQuery } from "../../API";
import { useCreateAvatar } from "../../store/hooks";
import { useAuth } from "../../AuthProvider";
import { useState } from "react";
import Upload from "../CreateAvatar/Upload";

const BASE_URL = process.env.REACT_APP_IMAGES_CDN_URL;

export default function Uploaded() {
  const { currentUser } = useAuth();
  const ownerId = currentUser?.email || "";
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const { data, isSuccess, isLoading } = useReloadMediaQuery(ownerId); // https://redux-toolkit.js.org/rtk-query/usage/queries#frequently-used-query-hook-return-values
  const sortedContents = data?.contents?.slice().sort((a, b) => {
    return (
      new Date(b.LastModified).getTime() - new Date(a.LastModified).getTime()
    );
  });

  const { toogleCreateAvatarMedia } = useCreateAvatar();

  const handleSelectAllChange = () => {
    setSelectAllChecked(!selectAllChecked);

    sortedContents?.forEach((item) => {
      toogleCreateAvatarMedia(item.Key);
    });
  };

  const content = sortedContents?.map((item) => (
    <UploadedMedia
      key={item.Key}
      {...item}
      selectAllChecked={selectAllChecked}
      toogleCreateAvatarMedia={toogleCreateAvatarMedia}
    />
  ));

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  return (
    <div>
      {isSuccess && content && content?.length > 0 && (
        <SelectAllCheckbox
          checked={selectAllChecked}
          onChange={handleSelectAllChange}
        />
      )}
      {!isMobileDevice() && (
        <div className="actions-card m-2">
          <Upload />
        </div>
      )}
      {isLoading && <Col>Loading...</Col>}

      <div className="selected-cards">
        {isSuccess && content}
        {isSuccess && !data.contents && (
          <Col className="m-3">Upload some files.</Col>
        )}
      </div>
    </div>
  );
}

function SelectAllCheckbox({ checked, onChange }: any) {
  return (
    <div className="m-3">
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        style={{ marginRight: "0.5rem" }}
      />
      Select all images
    </div>
  );
}

function UploadedMedia(props: any) {
  const { Key, toogleCreateAvatarMedia } = props;

  const treatedKey = Key.replace(/\+/g, "%2B");

  const url = BASE_URL + treatedKey.split(" ").join("+");

  const { createAvatarMedia } = useCreateAvatar();
  const checked = createAvatarMedia?.includes(Key);

  const handleClick = (e: any) => {
    e.stopPropagation();
    toogleCreateAvatarMedia(Key);
  };

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  return (
    <Card
      onClick={handleClick}
      className="card thumbs border rounded m-3 p-1"
      style={{
        width: isMobileDevice() ? "5rem" : "10rem",
        height: isMobileDevice() ? "5rem" : "10rem",
      }}
    >
      <img src={url} alt="Imagem" style={{ width: "100%", height: "100%" }} />
      <input
        {...{ checked }}
        readOnly
        type="checkbox"
        style={{
          right: "-0.35rem",
          top: "-0.35rem",
          position: "absolute",
          width: "1rem",
          height: "1rem",
        }}
      />
    </Card>
  );
}
