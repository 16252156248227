import { createSlice } from "@reduxjs/toolkit";
import { RootState } from ".";
import {
  StrengthPromptCompensation,
  TempAudio,
  VideoRequest,
} from "../components/GenerateVideo/types";
import { TransitionValues } from "../components/GenerateVideo/AudioPage/MSetTransitionsEffects/types";

export type AdvSettings = {
  steps?: number;
  strength: number;
  cadence: number;
  fps: number;
  seed: number;
  scale: number;
};

const initialState = {
  videoConfig: {} as VideoRequest,
  advancedSettings: {
    strength: 0.6,
    cadence: 3,
    fps: 24,
    seed: 0,
    scale: 35,
    steps: 35,
  } as AdvSettings,
  transitions: [] as TransitionValues[],
  strength_prompt_compensation: {} as StrengthPromptCompensation,
  temp_audio: {} as TempAudio,
  no_audio: false,
  spotify: false,
  currentVideo: {},
  aspectRatio: "",
  animationMode: "",
  boomerang: false,
  motionStrength: 0.5,
  zoomPulse: true,
  vocalShake: true,
  automaticTime: true,
  enableNegativePrompts: true,
};

const generateVideoSlice = createSlice({
  name: "generateVideo",
  initialState,
  reducers: {
    setStrengthPromptCompensation: (state, action) => ({
      ...state,
      strength_prompt_compensation:
        action.payload as StrengthPromptCompensation,
    }),

    setTempAudio: (state, action) => ({
      ...state,
      temp_audio: action.payload as TempAudio,
    }),

    setNoAudio: (state, { payload }: { payload: boolean }) => ({
      ...state,
      no_audio: payload,
    }),

    setSpotify: (state, { payload }: { payload: boolean }) => ({
      ...state,
      spotify: payload,
    }),

    resetVideoConfig: (state) => ({
      ...state,
      videoConfig: {} as VideoRequest,
    }),
    setAdvancedSettings: (state, action) => ({
      ...state,
      advancedSettings: action.payload as AdvSettings,
    }),
    resetAdvancedSettings: (state) => ({
      ...state,
      advancedSettings: initialState.advancedSettings,
    }),
    setTransitions: (state, action) => ({
      ...state,
      transitions: action.payload as TransitionValues[],
    }),
    setCurrentVideo: (state, action) => ({
      ...state,
      currentVideo: action.payload,
    }),
    setAspectRatioState: (state, { payload }: { payload: string }) => ({
      ...state,
      aspectRatio: payload,
    }),
    setAnimationModeState: (state, { payload }: { payload: string }) => ({
      ...state,
      animationMode: payload,
    }),
    setBoomerangState: (state, { payload }: { payload: boolean }) => ({
      ...state,
      boomerang: payload,
    }),
    setZoomPulseState: (state, { payload }: { payload: boolean }) => ({
      ...state,
      zoomPulse: payload,
    }),
    setMotionStrengthState: (state, { payload }: { payload: number }) => ({
      ...state,
      motionStrength: payload,
    }),
    setVocalShakeState: (state, { payload }: { payload: boolean }) => ({
      ...state,
      vocalShake: payload,
    }),
    setAutomaticTimeState: (state, { payload }: { payload: boolean }) => ({
      ...state,
      automaticTime: payload,
    }),
    setNegativePromptsState: (state, { payload }: { payload: boolean }) => ({
      ...state,
      enableNegativePrompts: payload,
    }),
    reset: (state) => ({ ...initialState, currentVideo: state.currentVideo }),
  },
});

export const {
  resetVideoConfig,
  setStrengthPromptCompensation,
  setTempAudio,
  setNoAudio,
  setSpotify,
  resetAdvancedSettings,
  setAdvancedSettings,
  setTransitions,
  setCurrentVideo,
  setAspectRatioState,
  setAnimationModeState,
  setBoomerangState,
  setMotionStrengthState,
  setZoomPulseState,
  setVocalShakeState,
  setAutomaticTimeState,
  setNegativePromptsState,
} = generateVideoSlice.actions;

export const selectAdvancedSettings = (state: RootState) =>
  state.generateVideo.advancedSettings;

export const selectTransitions = (state: RootState) =>
  state.generateVideo.transitions;

export const selectCurrentVideo = (state: RootState) =>
  state.generateVideo.currentVideo;

export const selectVideoConfig = (state: RootState) =>
  state.generateVideo.videoConfig;

export const selectStrengthPromptCompensation = (state: RootState) =>
  state.generateVideo.strength_prompt_compensation;

export const selectTempAudio = (state: RootState) =>
  state.generateVideo.temp_audio;

export const selectAspectRatio = (state: RootState) =>
  state.generateVideo.aspectRatio;

export const selectAnimationMode = (state: RootState) =>
  state.generateVideo.animationMode;

export const selectBoomerang = (state: RootState) =>
  state.generateVideo.boomerang;

export const selectMotionStrength = (state: RootState) =>
  state.generateVideo.motionStrength;

export const selectZoomPulse = (state: RootState) =>
  state.generateVideo.zoomPulse;

export const selectNoAudio = (state: RootState) => state.generateVideo.no_audio;

export const selectSpotify = (state: RootState) => state.generateVideo.spotify;

export const selectVocalShake = (state: RootState) =>
  state.generateVideo.vocalShake;

export const selectAutomaticTime = (state: RootState) =>
  state.generateVideo.automaticTime;

export const selectNegativePrompts = (state: RootState) =>
  state.generateVideo.enableNegativePrompts;

export default generateVideoSlice.reducer;
