import { useState } from "react";
import { Dropdown, Stack } from "react-bootstrap";
import CreateUser from "./CreateUser";
import UserLimits from "./UserLimits";
import Thumbnails from "./Thumbnails";

export default function Admin() {
  const [menu, setMenu] = useState(0);
  return (
    <div className="d-flex justify-content-center align-self-start mx-auto">
      <Stack className="border rounded p-3">
        <Header children={<Menu {...{ menu, setMenu }} />} />
        {opts[menu].component}
      </Stack>
    </div>
  );
}

function Header(props: any) {
  const { children } = props;
  return (
    <Stack direction="horizontal" className="gap-5 ms-5">
      <Stack className="h2 text-center white" children="Admin" />
      {children}
    </Stack>
  );
}

function Menu(props: any) {
  const { menu, setMenu } = props;
  return (
    <Dropdown onSelect={(index: string | null) => setMenu(+`${index}`)}>
      <Dropdown.Toggle variant="success" id="dropdown-basic">
        {opts[+menu].label}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {opts.map((i: any, key: number) => (
          <Dropdown.Item {...{ key }} eventKey={key} children={i.label} />
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

const opts = [
  {
    label: "Create User",
    component: <CreateUser />,
  },
  {
    label: "User Limits",
    component: <UserLimits />,
  },
  {
    label: "Thumbnails",
    component: <Thumbnails />,
  },
];
