import axios from "axios";
import { useEffect, useState } from "react";
import { useCustomEventListener } from "react-custom-events";
import { useAuth } from "../../AuthProvider";
import usePaginator from "../Paginator/usePaginator";
import Paginator from "../Paginator";
import { useGetImageAssetsQuery } from "../../API";
import { useStartingFrame } from "../../store/hooks";

const MESSAGE = "Uploaded images will appears here.";
const imageBucketBaseURL = process.env.REACT_APP_IMAGES_BUCKET_URL;
const { REACT_APP_MUZE_API_HOST } = process.env;

export default function Ipadapter() {
  const { refetch, isLoading, isError } = useImageLibrary();
  const { currentUser } = useAuth();
  const [noImages, setNoImages] = useState(false);
  const [ipadapterScale, setIpadapterScale] = useState<number>(0.7);
  const [ipadapterReferenceimage, setIpadapterReferenceimage] =
    useState("ip_image_9.png");
  const paginator = usePaginator();
  const { actual, setTotal } = paginator;
  const page = actual;
  const perPage = 10;
  const [formatedImages, setFormatedImages] = useState<any[]>([]);

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  const containerClassName = isMobileDevice()
    ? "choose-from-library-new-mobile overflow-hidden"
    : "choose-from-library-restyle overflow-hidden";

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    setNoImages(formatedImages.length === 0);
  }, [formatedImages.length, setNoImages]);

  const getImages = () => {
    axios
      .get(
        `${REACT_APP_MUZE_API_HOST}/ipadapter-images/assets?page=${page}&per_page=${perPage}`
      )
      .then((res: any) => {
        if (res.data.results.length === 0 || !Array.isArray(res.data.results)) {
          setTotal(0);
          setFormatedImages([]);
          return;
        }
        const formatedImages = res.data.results.map(
          (el: any, index: number, array: any[]) => ({
            src: `https://d3aa5rqohsahcz.cloudfront.net/${encodeURIComponent(
              el.Key
            )}`,
            name: el.Key,
            date: new Date(el.LastModified).toLocaleDateString(),
            isLast: index === array.length - 1,
          })
        );
        setTotal(res.data.total);
        setFormatedImages(formatedImages);
      })
      .catch((err: any) => {
        console.log(err);
      })
      .finally(() => {});
  };

  useCustomEventListener("onImageUpload", () => {
    getImages();
  });

  useEffect(() => {
    getImages();
  }, [actual]);

  return (
    <div className="library-img-conteiner">
      {isLoading && <Spinner />}
      {isError && <Error />}
      {!isLoading && !isError && (
        <>
          <div className="d-flex flex-wrap justify-content-start overflow-auto p-3 gap-3">
            {formatedImages.length > 0 ? (
              formatedImages.map((image: any) => (
                <ImageCard key={image.name} {...image} deleteImage date />
              ))
            ) : (
              <div>{formatedImages.length === 0 ? MESSAGE : null}</div>
            )}
          </div>
          <div className="paginator-container">
            <Paginator {...paginator} />
          </div>
        </>
      )}
    </div>
  );
}

function useImageLibrary() {
  const { currentUser } = useAuth();
  const ownerId = currentUser?.email || "";
  const paginator = usePaginator();
  const { actual, setTotal, setItemsPerPage, itemsPerPage } = paginator;
  const page = actual;
  const perPage = itemsPerPage;
  const { data, isLoading, refetch, isError } = useGetImageAssetsQuery({
    ownerId,
    page,
    perPage,
  });
  const { setUserInitialImageKey, initialImageKey } = useStartingFrame();

  const images = Array.isArray(data?.results)
    ? data.results.map((i: any, k: number, l: any[]) => ({
        img: `${imageBucketBaseURL}/${ownerId}/${i.Key}`,
        id: i.Key,
        isLast: k === l.length - 1,
      }))
    : [];

  return {
    refetch,
    images,
    isLoading,
    isError,
    setUserInitialImageKey,
    initialImageKey,
  };
}

function Spinner() {
  return (
    <div
      style={{ height: "100%" }}
      className="d-flex justify-content-center align-items-center"
    >
      <div
        className="spinner-border"
        style={{ width: "2rem", height: "2rem" }}
        role="status"
      />
    </div>
  );
}

function ImageCard(props: any) {
  const { src, name, key, date, deleteImage } = props;

  const downloadImage = () => {
    const link = document.createElement("a");
    link.href = src;
    link.download = name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  return (
    <>
      {isMobileDevice() ? (
        <div
          className={`d-flex flex-column align-items-stretch justify-content-start btn border p-0 rounded position-relative`}
          style={{
            width: "100%",
            marginBottom: "0.5rem",
            marginRight: "10px",
            maxWidth: "38%",
          }}
        >
          <div
            className="library-img-delete position-absolute"
            onClick={() => deleteImage(name)}
            onMouseEnter={(e: any) => {
              e.currentTarget.querySelector("i").style.color = "#FF30C4";
            }}
            onMouseLeave={(e: any) => {
              e.currentTarget.querySelector("i").style.color = "#fff";
            }}
            style={{
              top: "10px",
              right: "10px",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              color: "white",
              border: "none",
              borderRadius: "50%",
              padding: "5px",
              cursor: "pointer",
            }}
          >
            <i className="bi bi-trash p-0 m-0" />
          </div>
          <button
            className="btn btn-download position-absolute"
            onClick={downloadImage}
            style={{
              top: "10px",
              left: "10px",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              color: "white",
              border: "none",
              borderRadius: "50%",
              padding: "5px",
              cursor: "pointer",
            }}
          >
            <i
              className="bi bi-cloud-arrow-down-fill"
              style={{ fontSize: "medium" }}
            ></i>
          </button>
          <img
            className="library-img"
            src={src}
            alt=""
            style={{ width: "100%", height: "100%", display: "block" }}
          />
        </div>
      ) : (
        <div
          className="rounded border text-center library-img-card position-relative"
          style={{ width: "15rem", height: "15rem" }}
          key={key}
        >
          <div
            className="library-img-delete position-absolute"
            onClick={() => deleteImage(name)}
            style={{
              top: "10px",
              right: "10px",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              color: "white",
              border: "none",
              borderRadius: "50%",
              padding: "5px",
              cursor: "pointer",
            }}
          >
            <i className="bi bi-trash p-0 m-0" />
          </div>
          <button
            className="btn btn-download position-absolute"
            onClick={downloadImage}
            style={{
              top: "10px",
              left: "10px",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              color: "white",
              border: "none",
              borderRadius: "50%",
              padding: "5px",
              cursor: "pointer",
            }}
          >
            <i
              className="bi bi-cloud-arrow-down-fill"
              style={{ fontSize: "large" }}
            ></i>
          </button>
          <p className="m-0 p-0 small">{date}</p>
          <img
            className="library-img"
            src={src}
            alt=""
            style={{ width: "100%", height: "100%", display: "block" }}
          />
          <p className="text-center m-0 p-0 asset-name">{name}</p>
        </div>
      )}
    </>
  );
}

function Loading() {
  return (
    <div className="d-flex flex-grow-1 align-items-center justify-content-center">
      <h3>Loading ...</h3>
    </div>
  );
}

function Error() {
  return (
    <div className="d-flex flex-grow-1 flex-column gap-3 align-items-center justify-content-center">
      <h5>Something went wront ...</h5>
      <p>Please try later.</p>
    </div>
  );
}
