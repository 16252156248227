import { Form } from "react-bootstrap";
import { useStartingFrame } from "../../../store/hooks";
import toast from "react-hot-toast";
import { useRef } from "react";
import "./styles.css";

export default function Name() {
  const { setUserVideoName, videoName } = useStartingFrame();

  const handleInputChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setUserVideoName(value);
  };

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  const handleBlur = () => {
    const trimmedValue = videoName.trim();

    if (
      trimmedValue !== "" &&
      !/^[a-zA-Z0-9\s!@#$%^&*()_+={}[\]:;<>,.?~\\/-]+$/.test(trimmedValue)
    ) {
      toast.error(
        "Please use only English characters in the video name to ensure proper processing",
        { duration: 3000 }
      );
    } else if (trimmedValue !== "" && !/^[a-zA-Z0-9\s]*$/.test(trimmedValue)) {
      toast.error(
        "Please do not use special characters in the video name to ensure proper processing",
        { duration: 3000 }
      );
    }
  };

  const isVideoNameEmpty = videoName.trim() === "";
  const highlightClassName = isVideoNameEmpty ? "highlight-empty" : "";

  return (
    <>
      {isMobileDevice() ? (
        <div className="d-flex gap-2 align-items-center">
          <p
            className="m-1 p-0"
            style={{ whiteSpace: "nowrap", fontSize: "14px" }}
          >
            Name your video:
          </p>
          <Form.Control
            defaultValue={videoName}
            type="text"
            className={`flex-grow-1 ${highlightClassName}`}
            style={{ height: "25px", marginRight: "5px" }}
            onClick={() => toast.dismiss()}
            onChange={handleInputChange}
            onBlur={handleBlur}
            required
          />
        </div>
      ) : (
        <div className="d-flex gap-3 align-items-center flex-grow-1">
          <div className="col-5 d-flex flex-wrap p-1">
            <p className="m-0 p-0" style={{ whiteSpace: "nowrap" }}>
              Name your video:
            </p>
            <Form.Control
              defaultValue={videoName}
              type="text"
              className={`flex-grow-1 ${highlightClassName}`}
              onClick={() => toast.dismiss()}
              onChange={handleInputChange}
              onBlur={handleBlur}
              required
            />
          </div>
        </div>
      )}
    </>
  );
}
