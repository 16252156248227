import { useNavigate } from "react-router-dom";
import "./styles.css";
import logo from "./logomuze.png";

export default function SuccessfulPaymentPage() {
  const navigate = useNavigate();

  return (
    <div>
      <div className="success-container">
        <div className="success-content">
          <div className="success-card">
            <h1 className="success-title">
              Congrats! Your subscription was successful! 🎉
            </h1>
            <div
              className="success-button"
              onClick={() => navigate("/app/dashboard")}
            >
              <i className="bi bi-chevron-left" /> Start Creating
            </div>
          </div>
        </div>
      </div>
      <div className="logo-container">
        <img src={logo} alt="Your Logo" className="logo" />
      </div>
    </div>
  );
}
