import { useEffect, useRef, useState } from "react";
import { Button, Stack, Form, Spinner } from "react-bootstrap";
import { APIClient, upload_file } from "../../../utils/services";
import { useAuth } from "../../../AuthProvider";

const API_KEY = process.env.REACT_APP_MUZE_API_KEY;
const userState = {
  name: "",
  email: "",
  phone: "",
  password: "",
  photo: "",
};

export default function EditProfile() {
  const client = new APIClient();
  const { currentUser } = useAuth();
  const inputFile = useRef<HTMLInputElement | null>(null);
  const userId = currentUser?.email || "";
  const [nameEditMode, setNameEditMode] = useState(false);
  const [nameInput, setNameInput] = useState(currentUser?.displayName || "");
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState({
    name: currentUser?.displayName || "",
    email: currentUser?.email,
    phone: currentUser?.phoneNumber,
    photo: currentUser?.photoURL || "",
  });

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const userData = await client.getUserData(userId);
        setData({
          name: userData.user_name || currentUser?.displayName,
          email: userData?.email || currentUser?.email,
          phone: userData?.phone || currentUser?.phoneNumber,
          photo: userData.photo_url || currentUser?.photoURL,
        });
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching user data:", error);
      }
    };

    fetchData();
  }, [currentUser, userId]);

  const handleNameEdit = () => {
    setNameEditMode(true);
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNameInput(e.target.value);
  };

  const handleNameSave = async () => {
    setNameEditMode(false);
    setLoading(true);

    try {
      const res = await client.update_user(userId, {
        user_name: nameInput,
        photo_url: data.photo,
      });

      setData((prevData) => ({
        ...prevData,
        name: res?.user_name || currentUser?.displayName,
      }));
    } catch (error) {
      console.error("Error updating user:", error);
    }

    setLoading(false);
  };

  const handleNameBlur = () => {
    if (nameEditMode) {
      handleNameSave();
    }
  };

  const handleFileChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async (event) => {
        const photoURL = event.target?.result as string;
        try {
          const res = await client.update_user(userId, {
            user_name: data.name
              ? data.name
              : formatUserName(currentUser?.email),
            photo_url: photoURL,
          });

          setData((prevData) => ({
            ...prevData,
            photo: res?.photo_url || currentUser?.photoURL,
            name: res?.user_name || currentUser?.displayName,
          }));
        } catch (error) {
          console.error("error update user:", error);
        }
      };
      reader.onerror = (error) => {
        console.error("Error reading file:", error);
      };
      reader.readAsDataURL(file); // Ler o arquivo como URL de dados (base64)
    }
  };

  const formatUserName = (email: any) => {
    if (!email) return "";
    const username = email.split("@")[0];
    const firstWord = username.split(".")[0];
    const capitalizedUsername =
      firstWord.charAt(0).toUpperCase() + firstWord.slice(1);
    return capitalizedUsername;
  };

  return (
    <Stack className="flex-grow-0 ms-auto me-auto overflow-hidden">
      {!isMobileDevice() && <h2 className="ps">Your Profile</h2>}
      <Form className="overflow-auto">
        {/* AVATAR IMAGE */}
        <Form.Group>
          <Stack direction="horizontal" className="mt-4 mb-4">
            <Stack className="flex-grow-0 me-4">
              <Form.Label className="ps">
                {/* button image tag */}
                <Button
                  variant="link"
                  className="p-0 border-0"
                  onClick={() => inputFile.current?.click()}
                >
                  {loading && <Spinner style={{ color: "#ff30c" }} />}
                  {!loading &&
                    (data.photo ? (
                      <img
                        height="96"
                        width="96"
                        alt="Your photo"
                        src={data.photo}
                        style={{ borderRadius: "50%" }}
                        title="Edit your photo"
                      />
                    ) : (
                      <i
                        className="bi bi-person-circle"
                        style={{
                          fontSize: "55px",
                          color: "#FFF",
                          borderRadius: "80%",
                        }}
                        title="Edit your photo"
                      />
                    ))}
                </Button>
              </Form.Label>
            </Stack>
            <Stack className="me-auto flex-grow-0">
              <input
                type="file"
                ref={inputFile}
                style={{ display: "none" }}
                accept="image/jpeg, image/jpg"
                onChange={handleFileChange}
              />
            </Stack>
          </Stack>
        </Form.Group>

        {/* NAME */}
        <Form.Group className="mb-4">
          {!nameEditMode ? (
            <>
              <Form.Label className="d-flex align-items-center">
                {data.name ? data.name : formatUserName(currentUser?.email)}
                <i
                  className="bi bi-pencil-square m-3"
                  style={{ color: "#ff30c4", cursor: "pointer" }}
                  onClick={handleNameEdit}
                />
              </Form.Label>
            </>
          ) : (
            <Form.Control
              type="text"
              value={nameInput}
              onChange={handleNameChange}
              onBlur={handleNameBlur}
            />
          )}
        </Form.Group>

        {/* EMAIL */}
        <Form.Group className="mb-4">
          <Form.Label>Email: {currentUser?.email}</Form.Label>
        </Form.Group>

        {/* SUBMIT */}
        {/* <Button id="saveChanges" type="submit" className="col-12">
          Save changes
        </Button> */}
      </Form>
    </Stack>
  );
}

function UploadImg() {
  const inputFile = useRef<HTMLInputElement | null>(null);
  const className = "btn-dark border mb-3";
  const children = "Upload new image";
  const onClick = () => {
    inputFile.current?.click();
  };
  const handleChange = (e: any) => {
    const files = e.target.files;
    uploadFiles(ownerId, files);
  };
  const inp = {
    id: "file",
    ref: inputFile,
    style: { display: "none" },
    accept: "audio/.mp3",
  };
  const { currentUser } = useAuth();
  const ownerId = currentUser?.email || "";

  return (
    <>
      <Button {...{ className, children, onClick }} />
      <input type="file" {...inp} onChange={handleChange} />
    </>
  );
}

function uploadFiles(ownerId: string, files: any[]) {
  if (files.length > 0) {
    // forEach nor map possible
    for (let i = 0; i < files.length; i += 1) {
      const file = files[i];
      if (file.name !== "") {
        const d = new Date();
        const name = `${file.name}`;
        const start = `${d.getTime()}_`;
        const client = new APIClient();
        client.authenticate();
        client.get_signed_url(ownerId, start + name, "audio", (url: string) => {
          // uploading
          const okFunc = () => console.log(`${file.name} upload ok`);
          const errFunc = (err: any) => console.error("upload error: ", err);
          upload_file(url, file, okFunc, errFunc);
        });
      }
    }
  }
}
