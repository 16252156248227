import { useAuth } from "../../AuthProvider";
import { useGetAvatarsQuery } from "../../API";

export default function useAvatarLoading() {
  const { currentUser } = useAuth();
  const ownerId = currentUser?.email || "";

  const { data, refetch, isError } = useGetAvatarsQuery({
    ownerId,
  });

  const avatars = data?.result?.records || [];

  const avatarIncompleted = avatars.filter(
    (i: any) => i.dreambooth_status !== "DONE"
  );

  const incompleted = avatarIncompleted.map((i: any) => i.dreambooth_status);
  const stepName = incompleted.length > 0 ? incompleted[0] : "";
  const step = ~~(
    ((STEPS.findIndex((v) => v === stepName) + 1) * 100) /
    STEPS.length
  );

  const s = stepName
    ? stepName
        .toLowerCase()
        .split("_")
        .map((v: string) => (v[0] ? v[0].toUpperCase() + v.slice(1) : ""))
        .join(" ")
    : "";

  return {
    avatarIncompleted,
    refetch,
    step,
    stepName: s,
    isError,
  };
}

const STEPS = [
  "ERROR",
  "DREAMBOOTH_CONFIG_START",
  "DREAMBOOTH_CONFIG_END",
  "IMAGES_CROP_START",
  "IMAGES_CROP_END",
  "DREAMBOOTH_INIT_START",
  "DREAMBOOTH_INIT_END",
  "DREAMBOOTH_TRAIN_START",
  "DREAMBOOTH_TRAIN_END",
  "DREAMBOOTH_MODEL_UPLOAD_START",
  "DREAMBOOTH_MODEL_UPLOAD_END",
  "DREAMBOOTH_STYLE_PREVIEWS_START",
  "DREAMBOOTH_STYLE_PREVIEWS_END",
];
