import { useState } from 'react';
import { Button, Form, Stack } from 'react-bootstrap';
import { APIClient } from '../../../utils/services';
import { createUser } from '../../../utils/fb-services';

export default function CreateUser() {
  const [data, setData] = useState(NEW_USER);
  return (
    <Stack className="justify-content-end flex-grow-1 overflow-hidden">
      <Form onSubmit={handleSubmit(data)} className='overflow-auto'>
        <FormItems {...{ data, setData }} />
        <SubmitButton disabled={data.password.length < 8} />
        {/* TEST FOR EMPTY / INVALID INPUT DATA */}
      </Form>
    </Stack>
  );
}

function FormItems(props: any) {
  const { data, setData } = props;
  const passErr = ' should be at least 8 characters';
  const passL = data.password.length;
  const isPass = (k: number) => Object.keys(NEW_USER)[k] === 'password';
  const style = { borderColor: 'red' };

  return (
    <>
      {Object.keys(data).map((i: any, k: number) => (
        <Form.Group key={k} className="mb-3">
          <Form.Label>
            {formatLabel(i)}
            {isPass(k) && passL > 0 && passL < 8 ? passErr : null}
          </Form.Label>
          <Form.Control
            style={isPass(k) && passL > 0 && passL < 8 ? style : {}}
            type={
              typeof Object.values(NEW_USER)[k] === 'number' ? 'number' : 'text'
            }
            name={i}
            onChange={(e) => setData({ ...data, [`${i}`]: e.target.value })}
          />
        </Form.Group>
      ))}
    </>
  );
}

function handleSubmit(data: any) {
  return (e: any) => {
    e.preventDefault();
    // show loading modal

    const client = new APIClient();
    client.authenticate();

    createUser(
      data.email,
      data.password,
      (r: any) => {
        const { uid } = r.user;
        client.register_new_user({ ...data, userId: uid });
        // success message && hide loading modal
      }
      // , ()=> show err message && hide loading modal (&& remove item from firebase ?)
    );
  };
}

function SubmitButton(props: any) {
  return (
    <Button
      {...props}
      type="submit"
      className="btn-success col-12"
      children={'Submit'}
    />
  );
}

const formatLabel = (name: string) =>
  name
    .split('')
    .map((i) => (i === '_' ? ' ' : i))
    .join('')
    .split(' ')
    .map((j) => j[0].toUpperCase() + j.slice(1, j.length))
    .join(' ');

const NEW_USER = {
  owner_id: '',
  name: '',
  email: '',
  subscription_tier: '',
  credits: 0,
  phone: '',
  password: '',
};

/*
credits: "3000"
​email: "newUser@mail.com"
​name: "test new user"
​owner_id: "gabriel.rodrigues@lexartlabs.xyz"
​password: "12345678"
​phone: "+5511998769876"
​subscription_tier: "pro"
*/
