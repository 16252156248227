import { useEffect, useState } from "react";
import { useAuth } from "../../AuthProvider";
import { formatMinutes } from "../GenerateVideo/Prompts/LyricsBox";
import { useVideoManager } from "../../store/hooks";
import { useGetSingleVideoMetadataQuery } from "../../API";
import { useLocation } from "react-router-dom";

const DONE = "DONE";
const ERROR = "ERROR";
const PROCESSING = "PROCESSING";

const defaultState = {
  video_name: "",
  times_min: { TOTAL: 0 },
  video_status: "",
  video_url: "",
  video_id: "",
  user_status: "",
  progress: {
    saved_frame_index: null,
    saved_frame_httplink: "",
    frame_index: null,
    max_frames: null,
    timestamp: "",
  },
};

export default function useVideo() {
  const [oneVideo, setOneVideo] = useState(defaultState);

  const location = useLocation();
  const { videoInfo } = location.state || {};
  const { currentUser } = useAuth();
  const ownerId = currentUser?.email || "";
  const videoId = videoInfo?.id;
  const { data, isSuccess, refetch, isError, isLoading } =
    useGetSingleVideoMetadataQuery({ ownerId, videoId });

  const findVideo = () => {
    if (isSuccess) {
      const videos = data?.result || [];
      const video = videos[0] || defaultState;
      setOneVideo(video);
    }
  };

  useEffect(() => {
    findVideo();
  }, [data, isSuccess]);

  return {
    oneVideo,
    refetch,
    isLoading,
    isError,
    ownerId,
    id: oneVideo?.video_id,
    name: oneVideo.video_name,
    url: oneVideo.video_url,
    time: oneVideo.times_min.TOTAL,
    frameProgress: oneVideo.progress,
    status: formatStatus(oneVideo),
    formatedTime: formatMinutes(oneVideo.times_min.TOTAL * 60),
    isWorking: oneVideo.user_status === PROCESSING,
    isVideoError: oneVideo.user_status === ERROR,
    isDone: oneVideo.user_status === DONE,
    step: mapEvents[oneVideo.video_status] || 1,
  };
}

function formatStatus(video: typeof defaultState) {
  if (video.video_name === "") return ""; // prevent show "ERROR while page is loading"
  if (video.video_status === DONE) return "Done.";
  if (video.user_status !== PROCESSING) return "";

  return mapEvents[video.video_status] ? "Starting Transitions" : "ERROR";
}

const mapEvents: any = {
  SUBMISSION: 0,
  RESOLVED_AUDIO_ASSET: 2,
  PROMPTS_GENERATED_START: 3,
  PROMPTS_GENERATED_DONE: 4,
  ADDED_NEW_AUDIO_EFFECTS_DONE: 5,
  START_TRANSITIONS: 6,
  END_TRANSITIONS: 7,
  MOTION_COMPENSATION_START: 8,
  MOTION_COMPENSATION_END: 9,
  STABLE_DIFFUSION_INIT_START: 10,
  STABLE_DIFFUSION_INIT_END: 11,
  STABLE_DIFFUSION_RENDER_START: 12,
  STABLE_DIFFUSION_RENDER_END: 13,
  VIDEO_BUILD_START: 14,
  VIDEO_BUILD_END: 15,
};
