export default function Navigator(props: any) {
  const { ops } = props;
  const opts = ops.map((...v: any) => <NavItem {...v[0]} />);
  return (
    <nav className="m-0 p-0">
      <ul className="d-flex gap-1 m-0 p-0">{opts}</ul>
    </nav>
  );
}

function NavItem(props: any) {
  const { label, active, onClick } = props;
  const i = { color: active ? "#FF30C4" : "#6C7275" };
  const className = "btn m-0 p-0";
  const style = { listStyle: "none", ...i };
  const children = <p className="small m-0 p-0">{label}</p>;
  return <li {...{ style, className, children, onClick }} />;
}
