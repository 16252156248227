import React, { useEffect, useState } from "react";
import {
  VideoStyleItem,
  useGetStyleCustomMetadataQuery,
  useGetVideoStylesQuery,
} from "../../API";
import { useArtStyle } from "../../store/hooks";
import { NextPrev } from "../AudioPage";
import "./styles.css";
import {
  Carousel,
  Modal,
  ProgressBar,
  Spinner,
  Tab,
  Tabs,
} from "react-bootstrap";
import stylesJson from "../../utils/styles-json";
import { useAuth } from "../../AuthProvider";
import CreateStyleModal from "./CreateStyleModal";
import MobileCreateStyle from "./MobileCreateStyle";
import axios from "axios";
import { APIClient } from "../../utils/services";
import * as Sentry from "@sentry/react";

const TITLE = "Choose your art style";
const SUBTITLE = "Art styles influence the way your video will look";

interface ArtStyleListProps {
  type: "public" | "my";
}

const BASE_URL = process.env.REACT_APP_MUZE_API_HOST || "http://localhost:8002";

export default function ChooseArtStylePage() {
  const [activeTab, setActiveTab] = useState({
    type: "public",
  });
  const [showCreateStyleModal, setShowCreateStyleModal] = useState(false);
  const [newStyleCreated, setNewStyleCreated] = useState(false);

  const { currentUser } = useAuth();
  const ownerId = currentUser?.email || "";

  const { styles, isLoading, isError, refetch } = useArtStyleSelector(
    activeTab.type
  );

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  const handleTabChange = (tab: any) => {
    if (tab === "public" || tab === "my") {
      setActiveTab({ type: tab });
    }
  };

  const handleCreateStyleCardClick = () => {
    setShowCreateStyleModal(true);
  };

  const handleCloseCreateStyleModal = () => {
    setShowCreateStyleModal(false);
  };

  useEffect(() => {
    if (!showCreateStyleModal && newStyleCreated) {
      setActiveTab({ type: "my" });
      setNewStyleCreated(false);
    }
  }, [showCreateStyleModal, newStyleCreated]);

  return (
    <div className="ps big-card rounded m-3 d-flex flex-grow-1 flex-column align-items-center justify-content-center p-2">
      <div className="d-flex flex-column flex-grow-1 justify-content-center align-items-center">
        {window.innerWidth < 1600 && typeof window.innerWidth === "number" && (
          <NextPrev next={"../prompts"} />
        )}
        <header className="d-flex flex-column gap-3 text-center pt-3">
          <h1 className="fw-bold m-0">{TITLE}</h1>
          <p className="text-muted m-0">{SUBTITLE}</p>
        </header>
        <Tabs
          activeKey={activeTab.type}
          onSelect={handleTabChange}
          className="mt-3 custom-tabs"
        >
          <Tab eventKey="public" title="Styles" className="nav-link"></Tab>
          <Tab eventKey="my" title="My Styles" className="nav-link"></Tab>
        </Tabs>
        {isLoading && <Loading />}
        {isError && <LoadingError />}
        <div className="mb-3">
          {isMobileDevice() && showCreateStyleModal && (
            <MobileCreateStyle
              refetch={refetch}
              setShowCreateStyleModal={setShowCreateStyleModal}
              setNewStyleCreated={setNewStyleCreated}
            />
          )}
        </div>
        {!isError && !isLoading && (
          <>
            {activeTab.type === "public" ? (
              <ArtStyleList
                styles={styles}
                onCreateStyleCardClick={handleCreateStyleCardClick}
                activeTabType={activeTab.type}
              />
            ) : (
              <CustomStylePrivate
                styles={styles}
                activeTabType={activeTab.type}
              />
            )}
          </>
        )}
      </div>
      {window.innerWidth > 1600 && typeof window.innerWidth === "number" && (
        <NextPrev next={"../prompts"} />
      )}

      {!isMobileDevice() && (
        <CreateStyleModal
          show={showCreateStyleModal}
          onHide={handleCloseCreateStyleModal}
          refetch={refetch}
          setNewStyleCreated={setNewStyleCreated}
        />
      )}
    </div>
  );
}

function ModalStyle(props: any) {
  const { show, onHide, img, images, name, description } = props;

  return (
    <Modal {...props} show={show} onHide={onHide} centered>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-lg-6 col-md-12 col-sm-12">
            <Carousel>
              {images.map((image: any, index: any) => (
                <Carousel.Item interval={1000} key={index}>
                  <img
                    className="d-block w-100"
                    src={image}
                    alt={`Slide ${index}`}
                  />
                </Carousel.Item>
              ))}
            </Carousel>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="modal-title">
              <div>
                <h4>{name}</h4>
              </div>
            </div>
            <div className="p-1 mt-4">
              <p>{description}</p>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

function Loading() {
  return (
    <div className="d-flex flex-grow-1 align-items-center justify-content-center">
      <h3>Loading ...</h3>
    </div>
  );
}

function LoadingError() {
  return (
    <div className="d-flex flex-grow-1 flex-column gap-3 align-items-center justify-content-center">
      <h5>Something went wrong ...</h5>
      <p>Please try again later.</p>
    </div>
  );
}

const ArtStyleList = ({
  styles,
  onCreateStyleCardClick,
  activeTabType,
}: {
  styles: any;
  onCreateStyleCardClick: any;
  activeTabType: string;
}) => {
  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  return (
    <>
      {isMobileDevice() ? (
        <div className="overflow-auto">
          <div
            className="d-flex rounded border flex-wrap justify-content-center overflow-auto p-3 gap-3"
            style={{ height: "500px" }}
          >
            <CreateStyleCard onClick={onCreateStyleCardClick} />
            {styles.map((item: any, index: any, array: any) => (
              <StyleCardMobile
                key={index}
                {...item}
                last={index === array.length - 1}
              />
            ))}
          </div>
        </div>
      ) : (
        <div className="choose-art-style-box flex-grow-1 overflow-hidden">
          <div className="d-flex flex-wrap justify-content-start overflow-auto p-1 gap-3">
            <CreateStyleCard onClick={onCreateStyleCardClick} />
            {styles.map((item: any, index: any, array: any) => (
              <StyleCard
                key={index}
                {...item}
                last={index === array.length - 1}
                activeTabType={activeTabType}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

const CustomStylePrivate: React.FC<{
  styles: any[];
  activeTabType: string;
}> = ({ styles, activeTabType }) => {
  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  return (
    <>
      {isMobileDevice() ? (
        <div className="overflow-auto">
          <div
            className="d-flex rounded border flex-wrap justify-content-center overflow-auto p-3 gap-3"
            style={{ height: "500px" }}
          >
            {styles.map((item: any, index: any, array: any) => (
              <StyleCardMobile
                key={index}
                {...item}
                last={index === array.length - 1}
              />
            ))}
          </div>
        </div>
      ) : (
        <div className="choose-art-style-box flex-grow-1 overflow-hidden">
          <div className="d-flex flex-wrap justify-content-start overflow-auto p-1 gap-3">
            {styles.map((item: any, index: any, array: any) => (
              <StyleCard
                key={index}
                {...item}
                last={index === array.length - 1}
                activeTabType={activeTabType}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

function StyleCard(props: any) {
  const { title, onClick, last, img, images, activeTabType, icon } = props;
  const { themeId } = useArtStyleSelector();
  const selected = themeId === title;
  const [modalShow, setModalShow] = useState(false);
  const [styleData, setStyleData] = useState([] as any);
  const [modalPrompts, setModalPrompts] = useState("");
  const { currentUser } = useAuth();
  const owner_id = currentUser?.email || "";
  const service = new APIClient();

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (activeTabType === "my") {
          const response = await service.styles_settings_custom(owner_id);
          setStyleData(response.data);
        } else {
          const data = stylesJson;
          setStyleData(data.data);
        }
      } catch (error) {
        console.error("Error fetching custom styles:", error);
      }
    };

    fetchData();
  }, [activeTabType, owner_id]);

  let prompt = "";

  const handleEyeIconClick = () => {
    setModalShow(true);
    try {
      const selectedStyle = styleData.find((x: any) => x.Name === title) as any;

      if (selectedStyle) {
        prompt = selectedStyle.Prompt as never;
        setModalPrompts(prompt);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const className = `style-card rounded d-flex flex-column align-items-center justify-content-between ${
    selected ? "style-card-selected" : ""
  } ${last ? "me-auto" : ""}`;

  return (
    <div
      onClick={onClick}
      className={className}
      style={img ? { backgroundImage: `url(${img})` } : {}}
    >
      <div className="d-flex align-items-center justify-content-center pe-2 mt-1 gap-2 col-10">
        {!img ? (
          <ProgressBar
            striped
            animated
            variant="success"
            max={10}
            now={6}
            label="IN PROGRESS"
            style={{ width: "100%", height: "20px", color: "black" }}
          />
        ) : (
          <i
            className="bi bi-check2-all"
            style={{ fontSize: "20px", marginRight: "230px" }}
          ></i>
        )}
      </div>
      <div className="d-flex flex-grow-1 align-items-center justify-content-center">
        <i
          className={`bi bi-${!img ? "clock-history" : ""}`}
          style={{ fontSize: "7rem" }}
        />
      </div>
      <div className="d-flex align-items-center justify-content-between p-1 gap-1 col-12">
        {!isMobileDevice() && (
          <div
            className="text-muted"
            style={{
              zIndex: 1,
              position: "relative",
              backgroundColor: "rgba(0, 0, 0, 0.7)",
              color: "#fff",
              padding: "3px",
              fontSize: "small",
              borderRadius: "0px 6px 0px 6px",
            }}
          >
            {title}
          </div>
        )}

        <div
          className="d-flex align-items-center justify-content-center gap-2"
          onClick={handleEyeIconClick}
        >
          <div
            style={{
              position: "relative",
              bottom: "0",
              left: "0",
              padding: "0px",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              color: "#fff",
              borderRadius: "14px",
              display: "flex",
              alignItems: "center",
            }}
          >
            {!img ? (
              <i className="bi bi-clock-history style-mini-icon" />
            ) : (
              <i className="bi bi-eye-fill style-mini-icon" />
            )}
          </div>
        </div>
      </div>
      <ModalStyle
        show={modalShow}
        onHide={() => setModalShow(false)}
        images={images}
        img={img}
        name={title}
        description={modalPrompts}
      />
    </div>
  );
}

function StyleCardMobile(props: any) {
  const { title, onClick, last, img, images, activeTab } = props;
  const { themeId } = useArtStyleSelector();
  const selected = themeId === title;

  const className = `style-card rounded d-flex flex-column align-items-center justify-content-between ${
    selected ? "style-card-selected" : ""
  } ${last ? "me-auto" : ""}`;

  return (
    <div onClick={onClick} className={className}>
      <Carousel interval={3000}>
        {images.map((image: any, index: any) => (
          <Carousel.Item key={index}>
            {img && (
              <img className="w-100" src={image} alt={`Slide ${index}`} />
            )}
            {img && (
              <Carousel.Caption className="carousel-caption-custom">
                <h5 style={{ fontSize: "large" }}>{title}</h5>
              </Carousel.Caption>
            )}
          </Carousel.Item>
        ))}
      </Carousel>
      {!img && (
        <div
          className="w-100 d-flex justify-content-center align-items-center"
          style={{ height: "100%" }}
        >
          <i className="bi bi-clock-history" style={{ fontSize: "100px" }}></i>{" "}
        </div>
      )}
      {!img && (
        <div className="mb-2">
          <ProgressBar
            striped
            animated
            variant="success"
            max={10}
            now={6}
            label="IN PROGRESS"
            style={{ width: "230px", height: "20px", color: "black" }}
          />
        </div>
      )}
    </div>
  );
}

function CreateStyleCard({ onClick }: any) {
  const { currentUser } = useAuth();
  const ownerId = currentUser?.email || "";

  const { data } = useGetStyleCustomMetadataQuery({
    ownerId,
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (data?.result?.[0]?.status === "DONE") {
      setLoading(false);
    } else if (data?.result?.[0]?.status === "SUBMITTED") {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [data?.result?.[0]?.status]);

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  const className = `style-card rounded d-flex flex-column align-items-center justify-content-between`;

  return (
    <div
      className={`${className} create-style-card`}
      onClick={onClick}
      style={{ cursor: "pointer" }}
    >
      <div className="d-flex align-items-center justify-content-center flex-grow-1">
        <i className="bi bi-plus-lg" style={{ fontSize: "5rem" }} />
      </div>

      <div className="d-flex align-items-center mb-4 justify-content-center p-2">
        Create a style
      </div>
    </div>
  );
}

const STYLE_LIST = Array(100).fill({
  name: "style name",
  time: "4:00",
  onDelete: () => console.error("delete: style"),
  onClick: () => console.log("select: style"),
});

export function useArtStyleSelector(activeTabType?: string | null) {
  const { currentUser } = useAuth();
  const ownerId = currentUser?.email || "";

  const queryOptionsCustom = {
    maxImagesPerTheme: 4,
    publicOnly: false,
    ownerId: ownerId,
    isCustomStyle: true,
  };

  const queryOptions = {
    maxImagesPerTheme: 4,
    publicOnly: false,
    ownerId: ownerId,
    isCustomStyle: false,
  };

  const optionsToUse =
    activeTabType === "my" ? queryOptionsCustom : queryOptions;

  const { data, isLoading, isSuccess, isError, refetch } =
    useGetVideoStylesQuery(optionsToUse);

  const { themeId, setUserThemeId, setUserThemeIndex } = useArtStyle();

  useEffect(() => {
    if (isSuccess && themeId === "") {
      const r = data?.results || [];
      r.forEach((i: any, k: any) => {
        if (k === 0 && i.theme_id) {
          setUserThemeId(i.theme_id);
        }
      });
    }
  }, [isSuccess]);

  const s = data?.results || [];
  const styles = s.map((v: VideoStyleItem, index: number, l: any[]) => ({
    title: v.name,
    img: v.urls[0],
    icon: "clock-history",
    images: v.urls,
    subtitle: v.description,
    themeId: v.theme_id,
    active: v.theme_id === themeId,
    isLast: index === l.length - 1,
    onClick: (_: any) => {
      setUserThemeId(v.theme_id);
      setUserThemeIndex(index);
    },
  }));

  return { styles, isLoading, isError, themeId, refetch };
}
