import { createSlice } from "@reduxjs/toolkit";
import { RootState } from ".";

type State = {
  media: string[];
  name: string;
  category: string;
};

const initialState: State = {
  media: [],
  name: "",
  category: "Cat",
};

const createAvatarSlice = createSlice({
  name: "createAvatar",
  initialState,
  reducers: {
    setMedia: (state, { payload }: { payload: string[] }) => ({
      ...state,
      media: payload,
    }),
    addMedia: (state, { payload }: { payload: string }) => ({
      ...state,
      media: [...state.media, payload],
    }),
    removeMedia: (state, { payload }: { payload: string }) => ({
      ...state,
      media: [...state.media.filter((i) => i !== payload)],
    }),
    resetMedia: (state) => ({
      ...state,
      media: [],
    }),
    toogleMedia: (state, { payload }: { payload: string }) => {
      if (state.media.includes(payload)) {
        return {
          ...state,
          media: [...state.media.filter((i) => i !== payload)],
        };
      }
      return {
        ...state,
        media: [...state.media, payload],
      };
    },
    setName: (state, { payload }: { payload: string }) => ({
      ...state,
      name: payload,
    }),
    setCategory: (state, { payload }: { payload: string }) => ({
      ...state,
      category: payload,
    }),
    reset: () => initialState,
  },
});

export const {
  setMedia,
  addMedia,
  removeMedia,
  resetMedia,
  toogleMedia,
  setName,
  setCategory,
  reset,
} = createAvatarSlice.actions;

export const selectMedia = (state: RootState) => state.createAvatar.media;
export const selectName = (state: RootState) => state.createAvatar.name;
export const selectCategory = (state: RootState) => state.createAvatar.category;

export default createAvatarSlice.reducer;
