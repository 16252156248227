import { useEffect } from "react";
import { useAnimationModeStore } from "../../../store/hooks";
import BoomerangSelector from "./BoomerangSelector";

export default function AnimationModeSelector() {
  const data = ["2D", "3D"];
  const { animationMode, setAnimationMode } = useAnimationModeStore();

  useEffect(() => {
    if (animationMode === "") setAnimationMode(data[0]);
  }, []);

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  return (
    <>
      {isMobileDevice() ? (
        <div className="d-flex gap-2 align-items-center">
          <p
            className="m-1 p-0"
            style={{ whiteSpace: "nowrap", fontSize: "14px" }}
          >
            Animation Mode:
          </p>
          <div className="d-flex border rounded align-items-center justify-content-center">
            {data.map((...v: any) => (
              <div
                onClick={() => setAnimationMode(v[0])}
                key={v[1]}
                className={`ps px-3 my-1 ${
                  v[2].length - 1 !== v[1] ? "border-end" : ""
                }`}
                style={{
                  color: `${v[0] === animationMode ? "#f0f" : "#fff"}`,
                  fontSize: "12px",
                }}
              >
                {v[0]}
              </div>
            ))}
          </div>
          <BoomerangSelector />
        </div>
      ) : (
        <div className="d-flex gap-3 align-items-center">
          <span className="ps text-muted">Animation Mode:</span>
          <div className="d-flex border rounded align-items-center justify-content-center">
            {data.map((...v: any) => (
              <div
                onClick={() => setAnimationMode(v[0])}
                key={v[1]}
                className={`ps px-3 my-1 ${
                  v[2].length - 1 !== v[1] ? "border-end" : ""
                }`}
                style={{
                  color: `${v[0] === animationMode ? "#f0f" : "#fff"}`,
                }}
              >
                {v[0]}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
}
