export default function Paginator(props: any) {
  const { actual, pages, next, previous, showPrevious, showNext, shouldHide } =
    props;

  if (shouldHide) return <></>;

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  return (
    <>
      {isMobileDevice() ? (
        <div className="d-flex gap-3 mx-auto justify-content-center align-items-center">
          <span
            className={`btn btn-dark ${showPrevious ? "" : "d-none"}`}
            onClick={() => previous()}
          >
            <i className="bi bi-chevron-left" />
          </span>
          <span>{actual}</span>
          <span>of</span>
          <span>{pages}</span>
          <span
            className={`btn btn-dark ${showNext ? "" : "d-none"}`}
            onClick={() => next()}
          >
            <i className="bi bi-chevron-right" />
          </span>
        </div>
      ) : (
        <div className="d-flex gap-3 mx-auto justify-content-center align-items-center">
          <span
            className={`btn btn-dark ${showPrevious ? "" : "d-none"}`}
            onClick={() => previous()}
          >
            <i className="bi bi-chevron-left" />
            Previous
          </span>
          <span>{actual}</span>
          <span>of</span>
          <span>{pages}</span>
          <span
            className={`btn btn-dark ${showNext ? "" : "d-none"}`}
            onClick={() => next()}
          >
            Next
            <i className="bi bi-chevron-right" />
          </span>
        </div>
      )}
    </>
  );
}
