import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '.';

const initialState = {
  userId: 'initial-user-id',
  userName: 'initial-user-name',
  token: 'initial-token',
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserName(state, action) {
      return {
        ...state,
        userName: action.payload,
      };
    },
    setUserId(state, action) {
      return {
        ...state,
        userId: action.payload,
      };
    },
    setToken(state, action) {
      return {
        ...state,
        token: action.payload,
      };
    },
  },
});

export const { setUserId, setUserName, setToken } = userSlice.actions;

export const selectUserName = (state: RootState) => state.user.userName;
export const selectUserId = (state: RootState) => state.user.userId;
export const selectToken = (state: RootState) => state.user.token;

export default userSlice.reducer;
